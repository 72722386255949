import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { appConfig } from '../app.config';
import { AuthenticationService } from '../authservices/authentication.service';

@Injectable()
export class TerminalGuard implements CanActivate {

    constructor(public auth: AuthenticationService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        // const expectedRole = route.data.expectedRole;
        // if (localStorage.getItem('token')) {
            // logged in so return true
            //  return true;
        // }
        // not logged in so redirect to login page with the return url
        // this.router.navigate(['login']);
        // return false;
  
        if (JSON.parse(localStorage.getItem("isTerminalLogin")) == true || JSON.parse(localStorage.getItem("isTerminalLogin")) ) {
            // this.router.navigate(['/dashboard'])
            return true;
        } 
        
        this.router.navigate(['login']);
        return false;
    }
}
