import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx'
import { BehaviorSubject, forkJoin, interval, Subscription } from 'rxjs';
import { browserDBInstance } from './browser';
import { appConfig } from '../app.config';
import { PosService } from 'src/app/services/pos.service';
import { OrdersPage } from '../pages/orders/orders.page';
import { CMSService } from './cms.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PassingDataService } from './passing-data.service';
// import { SQLite, SQLiteObject } from '@ionic-native/sqlite';
import { DatePipe } from '@angular/common';
import { setMaxListeners } from 'process';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DataServiceService } from './data-service.service';
import { AppComponent } from '../app.component';

declare var window: any;
// const SQL_DB_NAME = process.env.IONIC_ENV === 'dev' ? '__broswer.db' : '__native.db';
const SQL_DB_NAME = 'limerrpos.db';

@Injectable({
  providedIn: 'root'
})
export class WebSqlService {
  dbInstance: any;
  currentUser: any = {};

  currentDate: any;
  internetStatus: any = "Online";
  userList = [];
  checkoutList = [];
  posMenuListData = [];
  storeReceiptData = [];
  storePosBehavior : any = {}
  storeDetailData:any = {};

  private dbReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private updateSubscription: Subscription

  constructor(private sqlite: SQLite, private http: HttpClient,
    private _posService: PosService, public cmsService: CMSService,
    private spinner: NgxSpinnerService, private passData: PassingDataService,
    private platform: Platform, private datePipe: DatePipe,
    private translate: TranslateService,
    public toster: ToastrService,private _dataService: DataServiceService) {
    // this.platform.ready().then(() => {
    //   // this.createDb();
    //   this.createDb();
    // })
    this.platform.ready().then((readySource) => {
      this.init();
    });
    // this.init();
    // this.init();
    // this.dbInstance.executeSql (`DELETE FROM reasons WHERE database_id=14`);

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.passData.checkShare.subscribe(data => {
      var prvData = data;
      if (!this.isEmpty(prvData)) {
        this.internetStatus = prvData
      }
      
    });
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  // seedDatabase() {
  //   this.http.get('assets/seed.sql', { responseType: 'text' })
  //     .subscribe(async sql => {
  //       let data = await this.dbInstance.sqlBatch(sql);
  
  //       this.dbReady.next(true);
  //       // this.sqlitePorter.importSqlToDb(this.dbInstance, sql)
  //       //   .then(_ => {
  //       //     this.dbReady.next(true);
  //       //   })
  //       //   .catch(e => console.error(e));
  //     });
  // }

  // Android
  // createDb() {
  //   this.sqlite.create({
  //     name: SQL_DB_NAME,
  //     location: 'default'
  //   })
  //     .then((db: SQLiteObject) => {

  //       this.dbInstance = db;
  //       this.createSql();

  //     })
  
  // }

  // Windows
  // createDb() {
  //   let db = window.openDatabase(SQL_DB_NAME, '1.0', 'DEV', 5 * 1024 * 1024);
  //   this.dbInstance = browserDBInstance(db);
  //   this.createSql();
  // }

  async init() {
    //debugger
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    
    if (!this.platform.is('cordova')) {
      
      let db = window.openDatabase(SQL_DB_NAME, '1.0', 'DEV', 5 * 1024 * 1024);
      this.dbInstance = browserDBInstance(db);
      this.createSql();
    } else {
      
      this.sqlite.create({
        name: SQL_DB_NAME,
        location: 'default'
      })
        .then((db: SQLiteObject) => {
          this.dbInstance = db;
          
          this.createSql();
        })
        
    }
  }


  async startWebsqlStoreFunction() {
    // this.spinner.show();
    this.spinner.show("mySpinner", {
      type: "ball-clip-rotate-pulse",
      size: "medium",
      color: "#ffffff",
    });
    //ForkJoin
    var customer = await this.getcustomerListToWebSql()
    var tender_profile = await this.getTenderProfile()
    var tender_type = await this.getTenderTypeListItems()
    var category_list = await this.getPLUCategoryList()
    var all_reason = await this.getAllReasons()
    var plu_menu_item = await this.getPluMenuItem()
    var discount = await this.getDiscount()
    var rider = this.getRiderListToWebSql();
    // var mobile_orders = await this.syncfromMobileOrders()
    var posmenulist = await this.posMenuList()
    var storeTableCategories = await this.storeTableCategories()
    var storeTable = await this.storeTables()
    var printer = await this.getPrinterList()
    // var attendance = await this.setCheckAttendance();
    // var attendanceouttime = await this.setCheckoutAttendance();
    this.getAttendanceList()
    this.storeReceiptTemplate()
    this.getstorePosBehavior()
    this.spinner.hide('mySpinner');


    let last_sync_time = this.datePipe.transform(new Date(), 'MM/dd/yyyy, HH:mm:ss');
    localStorage.setItem('last_sync_time', JSON.stringify(last_sync_time.toLocaleString()));





    
    // const promisesArray: any[] = [];
    // await promisesArray.push(this.getcustomerListToWebSql());
    // await promisesArray.push(this.getTenderProfile());
    // await promisesArray.push(this.getTenderTypeListItems());
    // await promisesArray.push(this.getPLUCategoryList());
    // await promisesArray.push(this.getAllReasons());
    // await promisesArray.push(this.getPluMenuItem());
    // await promisesArray.push(this.getDiscount())
    // await promisesArray.push(this.syncfromMobileOrders());
    // await promisesArray.push(this.getRiderListToWebSql());
    // await promisesArray.push(this.getPaidout())
    // await promisesArray.push(this.getStore());
    // await Promise.all(promisesArray)
    //   .then((res) => {
    //     this.spinner.hide('mySpinner');
    
    
    //   }, (firstErr) => {
    //     this.spinner.hide('mySpinner');
    //   });
  }

  //Commented by joy

  // gettenderType(order_type) {
  //   let list = [];
  //   this.dbInstance.executeSql(`SELECT * FROM tender_profiles_store where database_id =` + order_type, []).then((data) => {
  //     for (let i = 0; i < data.rows.length; i++) {
  //       list.push(data.rows.item(i));
  //     }
  //   })
  //   return list
  // }

  // getPaidout() {
  //   debugger
  //   return new Promise(resolve => {

  //     this.http.post(appConfig.apiUrl + '/getpaidout', { department_id: 1 }).subscribe(res => {
  //       this.dbInstance.executeSql(`SELECT * FROM paidout`).then((data) => {
  //         // let tendertypelist = data.rows;
  //         let list = [];
  //         for (let i = 0; i < data.rows.length; i++) {
  //           list.push(data.rows.item(i));
  //         }
  //         if (list.length > 0) {
  //           res['data'].forEach(async element => {
  //             let dataInsert = await this.checkPaidOut(element);
  //             if (dataInsert['length'] == 0) {
  //               // insert

  //               let elementData = [element.id, `'` + element.amount + `'`, element.amount_type, element.clerk_id, `'` + element.comment + `'`, element.paid_out_reason_id]
  //               this.dbInstance.executeSql(`INSERT INTO paidout(database_id, amount, amount_type, clerk_id, comment, paid_out_reason_id) VALUES(` + elementData.toString() + `)`, [])
  //                 .then((res) => {
  
  //                 }).catch((err) => {
  //                   this.spinner.hide();
  //                 })
  //             }
  //           });
  //         } else {
  //           res['data'].forEach(element => {
  //             let elementData = [element.id, `'` + element.amount + `'`, element.amount_type, element.clerk_id, `'` + element.comment + `'`, element.paid_out_reason_id]
  //             this.dbInstance.executeSql(`INSERT INTO paidout(database_id, amount, amount_type, clerk_id, comment, paid_out_reason_id) VALUES(` + elementData.toString() + `)`, [])
  //             .then((res) => {
  
  //               }).catch((err) => {
  //                 this.spinner.hide();
  //               })

  //           })
  //         }
  //         resolve(res)
  //       })
  //     }, (err) => {
  //       resolve(err);
  //     });
  //   });
  // }

  //start sync online or miss order
  syncfromMobileOrders() {
    
    // this.sync_img = "assets/images/sync.gif"
    return new Promise(resolve => {

      let onlineSendData = {
        // "page_no": this.p,
        // "order_status": 3,
        "terminal_id": localStorage.getItem("terminal_id")
      }
      this._posService.getAllOrderData(onlineSendData)
        .subscribe(
          async res => {
            if (res.status === 1) {
              // this.orderList = res.data.orders;
              // this.orderList = [];
              let orders = res.data;
              
              // orders.sort((a, b) => 0 - (a > b ? -1 : 1));
              orders.sort((a, b) => 0 - (
                // a.local_transaction_no - b.local_transaction_no // example : order by id
                (a.local_transaction_no > b.local_transaction_no ? 1 : -1)
              ));

              let findOrd
              // orders.forEach(async element => {
              
              //   findOrd = await this.findOrderFromLocalData(element);

              //   if (findOrd == 0) {
              //     //insert into websql
              //     this.addOrdersIntoWebSql(element)
              //   } else {
              //     this.updateOrderIntoWebSql(element)
              //   }
              // });
              for (let index = 0; index < orders.length; index++) {
                const element = orders[index];
                const local_transaction_no = element.local_transaction_no;
                //if(local_transaction_no == 0){//Mobile local_transaction_no status
                //element.order_id = element.id;
                //let status = await this._posService.placeOrder(element);
                //  this.addOrdersIntoWebSql(element)
                //  }
                
                findOrd = await this.findOrderFromLocalData(element);

                if (findOrd == 0) {
                  //insert into websql
                  this.addOrdersIntoWebSql(element)
                } else {
                  this.updateOrderIntoWebSql(element)
                }
              }

              this.passData.newOrderStatus(true)
              resolve("")
              
              // this.spinner.hide()
            } else {
              resolve("")
              // this.orderList = []
              // this.sync_img = "assets/images/sync_white.png";
              // this.spinner.hide()
            }
          },
          err => {
            
            resolve(err)

          })
    })

  }

 async getKdsOrders(senddata) {
    var printer = await this.getPrinterList()
    this.storeReceiptTemplate()
    // this.sync_img = "assets/images/sync.gif"
    return new Promise(resolve => {
      var onlineSendData = { 
        is_kds: 1,
        store_id:localStorage.getItem('store_id'),
        kds_id:localStorage.getItem('kds_id'),
        kds_departments:JSON.parse(localStorage.getItem('kds_departments')),
        get_departments:JSON.parse(localStorage.getItem('get_departments')),
      }
      this._posService.syncKdsOrders(onlineSendData)
        .subscribe(
          async res => {
            if (res.status === 1) {

              
              resolve(res.data);
             

              // // this.orderList = res.data.orders;
              // // this.orderList = [];
              // let orders = res.data;
              
              // // orders.sort((a, b) => 0 - (a > b ? -1 : 1));
              // orders.sort((a, b) => 0 - (
              //   // a.local_transaction_no - b.local_transaction_no // example : order by id
              //   (a.local_transaction_no > b.local_transaction_no ? 1 : -1)
              // ));

              // let findOrd
              // // orders.forEach(async element => {
              
              // //   findOrd = await this.findOrderFromLocalData(element);

              // //   if (findOrd == 0) {
              // //     //insert into websql
              // //     this.addOrdersIntoWebSql(element)
              // //   } else {
              // //     this.updateOrderIntoWebSql(element)
              // //   }
              // // });
              // for (let index = 0; index < orders.length; index++) {
              //   const element = orders[index];
              //   const local_transaction_no = element.local_transaction_no;
              //   //if(local_transaction_no == 0){//Mobile local_transaction_no status
              //   //element.order_id = element.id;
              //   //let status = await this._posService.placeOrder(element);
              //   //  this.addOrdersIntoWebSql(element)
              //   //  }
              
              //   findOrd = await this.findOrderFromLocalData(element);

              //   if (findOrd == 0) {
              //     //insert into websql
              //     this.addKdsOrdersIntoWebSql(element)
              //   } else {
              //     this.updateKdsOrderIntoWebSql(element)
              //   }
              // }

              // this.passData.newOrderStatus(true)
              resolve("")
              
              // this.spinner.hide()
            } else {
              resolve("")
              // this.orderList = []
              // this.sync_img = "assets/images/sync_white.png";
              // this.spinner.hide()
            }
          },
          err => {
            
            resolve(err)

          })
    })

  }



  async updateOrderIntoWebSql(ordData) {

    await this.dbInstance.executeSql(`UPDATE orders SET order_status = ` + ordData.order_status + `, local_transaction_no = ` + ordData.local_transaction_no + ` where database_id = ` + ordData.id, []).then(async (data) => {
      // this.sync_img = "assets/images/sync_white.png";

    })
  }

  async updateKdsOrderIntoWebSql(ordData) {

    await this.dbInstance.executeSql(`UPDATE orders SET order_status = ` + ordData.order_status + `, local_transaction_no = ` + ordData.local_transaction_no + ` where database_id = ` + ordData.id, []).then(async (data) => {
      // this.sync_img = "assets/images/sync_white.png";

    })
  }

  async updateKdsStatusIntoWebSql(status,item, mainOrderStatus,selected_item_order_status) {
    
    return new Promise(async resolve => {
    var order_status = selected_item_order_status;
    var is_start_cooking = 0
    var check_update = 0
    
    if(mainOrderStatus == 1) {
      order_status = 7
    } else if(mainOrderStatus == 2) {
      is_start_cooking = 1 // ask pk
    } else if(mainOrderStatus == 3){
      order_status = 6
    }
    await this.dbInstance.executeSql(`UPDATE order_store_kds SET status = ` + status + ` where database_id = ` + item.kds_data.database_id +` AND group_id = `+ item.group_id, []).then(async (data) => {
      // this.sync_img = "assets/images/sync_white.png";
      
      let updateData = {
        'id':item.kds_data.database_id,  
        'kds_id':item.kds_data.kds_id,
        'order_id':item.kds_data.order_id,
        'order_detail_id':item.kds_data.order_detail_id,
        'pricelookup_id':item.kds_data.pricelookup_id,
        'status':status,
        'order_status':order_status,
        'is_start_cooking':is_start_cooking
      }
      await this._posService.updateKdsItem(updateData)

      await this.dbInstance.executeSql(`SELECT (SELECT COUNT(*) FROM order_store_kds WHERE order_id = ` +item.kds_data.order_id +` AND status=0) as 'Statuscountone',(SELECT COUNT(*) FROM order_store_kds WHERE order_id = ` +item.kds_data.order_id + ` AND status=1) as 'Statuscounttwo',(SELECT COUNT(*) FROM order_store_kds WHERE order_id = ` +item.kds_data.order_id + ` AND status=2) as 'Statuscountthree'`, []).then(async (data) => {
        
        if(data.rows.item(0).Statuscountone  == 0 && data.rows.item(0).Statuscounttwo  == 0)   {
          await this.dbInstance.executeSql(`UPDATE orders SET order_status = 7 where database_id = ` + item.kds_data.order_id, []).then(async (data) => {
      
          })
        }else if(data.rows.item(0).Statuscountone  == 0 && data.rows.item(0).Statuscounttwo  == 1)   {
          await this.dbInstance.executeSql(`UPDATE orders SET order_status = 6 where database_id = ` + item.kds_data.order_id, []).then(async (data) => {
      
          })
        }  
    
        check_update = 1;
        
         // resolve(data.rows.item(0));
  
      })

    })
      if(check_update == 1){
        resolve(true);
      }else{
        resolve(false);
      }
    });
  }

  async createTranscationNoTabel() {
    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS transcation_no(
      tran_no INTEGER NOT NULL,
      created_at TEXT
    )`, []);
  }

  async addOrdersIntoWebSql(ordData) {//this fun call after start of day only

    await this.createTranscationNoTabel()

    // to create transction number
    // var local_transaction_no = ordData.local_transaction_no;
    // await this.sqlService.dbInstance.executeSql(`INSERT INTO transcation_no (tran_no, created_at) VALUES(` + local_transaction_no + `,` + transdata + `)`, []).then((res) => {
    
    //   // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
    //   // local_no = res['insertId']
    // })
    var new_transaction_number = 1;
    // device type = 1 for ios and 2 for android 0 for POS
    if (ordData.local_transaction_no == 0 && (ordData.device_type == 1 || ordData.device_type == 2)) {
      await this.dbInstance.executeSql(`SELECT tran_no FROM transcation_no ORDER BY tran_no DESC LIMIT 1`, {}).then((res) => {
        
        if (res.rows.length > 0) {
          var total_transaction_number = res.rows.item(0).tran_no;
          if (total_transaction_number > 0) {
            new_transaction_number = total_transaction_number + 1;
          } else {
            new_transaction_number = 1;
          }
        } else {
          new_transaction_number = 1;
        }

      })
    } else {
      new_transaction_number = ordData.local_transaction_no;
      // let date = [`'` + new Date() + `'`]
      // new_transaction_number = ordData.local_transaction_no;
      // await this.dbInstance.executeSql(`INSERT INTO transcation_no (tran_no, created_at) VALUES(` + new_transaction_number + `,` + date + `)`, []).then((res) => {

      // })
    }

    //end to create transction number

    let transdata = [`'` + new Date() + `'`]
    let local_no;
    await this.dbInstance.executeSql(`INSERT INTO transcation_no (tran_no, created_at) VALUES(` + new_transaction_number + `,` + transdata + `)`, []).then((res) => {
      
      // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
      // local_no = res['insertId']
    })
    // let currentDate = this._datePipe.transform(ordData.created_at, 'full')
    // let currentDate = ordData.created_at_formated
    // let created_date = Date.parse(currentDate.toString());
    let customer_name = ordData.order_customer.name + " " + ordData.order_customer.lname;
    //debugger
    let staffNote = ordData.staff_note ? ordData.staff_note : "null";
    if (ordData.order_table.title == undefined || ordData.order_table.title == '') {
      ordData.order_table.title =''
    }
    

    let data = [`'` + ordData.store_order_sequence_id + `'`,  + ordData.store_order_id + `'`, new_transaction_number, `'` + ordData.id + `'`, ordData.business_id, ordData.state_id, ordData.store_id, ordData.terminal_id,
      0, `'` + ordData.customer_id + `'`, `'` + customer_name + `'`, `'` + ordData.order_customer.phone + `'`, `'` + ordData.adujusted_order_id + `'`, ordData.cash_change,
    ordData.cash_taken, ordData.total_item, ordData.total_item_qty, ordData.total_discount,
    ordData.total_tax, ordData.total_price, ordData.grand_total, 0, 0, 0, ordData.discount_id, ordData.discount_rate,
    ordData.discount_type, 0, 0, 0, 0, ordData.order_status, `'` + ordData.cancellation_reason + `'`, 0, 0, "null", `'` + staffNote + `'`, ordData.tender_profile_id,
    `'` + ordData.tender_profile_name + `'`, `'` + ordData.tender_profile_image + `'`, ordData.price_override_status, this.currentUser.id, ordData.created_at_formated, 1, ordData.round_off,
    ordData.delivery_charge, ordData.device_type, ordData.delivery_type,ordData.table_id,`'` + ordData.order_table.title + `'`];
    let sql = `INSERT INTO orders
    (store_order_sequence_id, store_order_id, local_transaction_no,database_id, business_id, state_id, store_id,terminal_id,
    reference_no,customer_id, customer_name,customer_phone, adujusted_order_id, cash_change,
    cash_taken,total_item,total_item_qty,total_discount,
    total_tax,total_price,grand_total,tax_id,
    tax_rate,tax_type, discount_id,discount_rate,
    discount_type,coupon_id,coupon_discount,coupon_type,
    shipping_cost, order_status, cancellation_reason, payment_status,
    paid_amount,sale_note, staff_note,tender_profile_id,
    tender_profile_name, tender_profile_image, price_override_status, created_by, created_at, isSync, round_off,delivery_charges, device_type, delivery_type,table_id,table_name) VALUES (`+ data.toString() + `)`;

    //insert Data into order_store_detail
    await this.dbInstance.executeSql(sql, []).then((data) => {

      let order = data;
      // Success

      ordData.order_detail.forEach(async cartData => {
        
        //   let price_cmt = cartData.price_override_item_comment != "" ? cartData.price_override_item_comment : 'null';
        //   let tax_in_prc = cartData.tax_include_price ? cartData.tax_include_price : 'null'
        //   let order_cart_data = [
        //     order.insertId, cartData.pricelookup_id, cartData.pricelookup_qty, `'` + cartData.pricelookup_name.replace("'", "`") + `'`,
        //     cartData.pricelookup_item_price, cartData.add_variation, cartData.price_override_item_status, cartData.price_override_item_price,
        //     cartData.price_override_item_id, `'` + price_cmt + `'`, cartData.add_addon, cartData.add_ingredient,
        //     cartData.addon_variation_combine, cartData.addon_ingredient_combine, tax_in_prc
        //   ]
        //   // , cartData.tax_include_price
        //   // tax_include_price
        //   await this.dbInstance.executeSql(`INSERT INTO order_store_detail(order_id, pricelookup_id, pricelookup_qty, pricelookup_name,
        //   pricelookup_item_price, add_variation, price_override_item_status, price_override_item_price,
        //   price_override_item_id, price_override_item_comment, add_addon, add_ingredient,
        //   addon_variation_combine, addon_ingredient_combine,tax_include_price ) VALUES (`+ order_cart_data.toString() + `)`, [])
        // });

        let price_cmt = cartData.price_override_item_comment != "" ? cartData.price_override_item_comment : 'null';
        // let tax_in_prc = cartData.tax_include_price ? cartData.tax_include_price : 'null'
        let specialNote = cartData.note != "" ? cartData.note : '';
        let addOnItemAry = cartData.order_detail_add_add_ons ? JSON.stringify(cartData.order_detail_add_add_ons) : '[]';
        let removeItemAry = cartData.order_detail_remove_add_ons ? JSON.stringify(cartData.order_detail_remove_add_ons) : '[]';
        let is_taken_for_new_item = cartData.is_taken_for_new_item ? cartData.is_taken_for_new_item : 0;
        let plu_type = cartData.plu_type;

        let order_cart_data = [cartData.id,
          order.insertId, cartData.pricelookup_id, cartData.pricelookup_qty, `'` + cartData.pricelookup_name.replace("'", "`") + `'`,
          cartData.pricelookup_item_price, cartData.price_override_item_status, cartData.price_override_item_price,
          cartData.price_override_item_id, `'` + price_cmt + `'`, `'` + addOnItemAry + `'`, `'` + removeItemAry + `'`, cartData.remove_group_id, cartData.add_group_id,
          cartData.is_advance_ingredient, `'` + specialNote + `'`,
          cartData.is_substitute, is_taken_for_new_item, cartData.is_variation_pricelookup, plu_type
        ]
        // , cartData.tax_include_price
        // tax_include_price
        await this.dbInstance.executeSql(`INSERT INTO order_store_detail(database_idorder_id, pricelookup_id, pricelookup_qty, pricelookup_name,
        pricelookup_item_price, price_override_item_status, price_override_item_price,
        price_override_item_id, price_override_item_comment, add_addons_detail, remove_addons_detail,
        remove_group_id,add_group_id,is_advance_ingredient,note, is_substitute,is_taken_for_new_item,is_variation_pricelookup, plu_type ) VALUES (`+ order_cart_data.toString() + `)`, [])
      });

      let tenders = ordData.order_type;
      tenders.forEach(async tender => {
        let data_order_type = [order.insertId, tender.order_type_id, tender.order_type, `'` + tender.order_type_name + `'`, tender.order_price]
        await this.dbInstance.executeSql(`INSERT INTO orders_store_order_type (order_id, order_type_id, order_type, order_type_name, order_price) VALUES (` + data_order_type.toString() + `)`, [])
      });
    })
    // this.sync_img = "assets/images/sync_white.png";

    if (ordData.local_transaction_no == 0) {

      ordData.order_id = ordData.id;
      ordData.local_transaction_no = new_transaction_number;
      
      
      
      // debugger
      await this._posService.placeOrder(ordData);
    }

  }

  async addKdsOrdersIntoWebSql(ordData) {//this fun call after start of day only

    await this.createTranscationNoTabel()

    // to create transction number
    // var local_transaction_no = ordData.local_transaction_no;
    // await this.sqlService.dbInstance.executeSql(`INSERT INTO transcation_no (tran_no, created_at) VALUES(` + local_transaction_no + `,` + transdata + `)`, []).then((res) => {
    
    //   // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
    //   // local_no = res['insertId']
    // })
    var new_transaction_number = 1;
    // device type = 1 for ios and 2 for android 0 for POS
    if (ordData.local_transaction_no == 0 && (ordData.device_type == 1 || ordData.device_type == 2)) {
      await this.dbInstance.executeSql(`SELECT tran_no FROM transcation_no ORDER BY tran_no DESC LIMIT 1`, {}).then((res) => {
        
        if (res.rows.length > 0) {
          var total_transaction_number = res.rows.item(0).tran_no;
          if (total_transaction_number > 0) {
            new_transaction_number = total_transaction_number + 1;
          } else {
            new_transaction_number = 1;
          }
        } else {
          new_transaction_number = 1;
        }

      })
    } else {
      new_transaction_number = ordData.local_transaction_no;
      // let date = [`'` + new Date() + `'`]
      // new_transaction_number = ordData.local_transaction_no;
      // await this.dbInstance.executeSql(`INSERT INTO transcation_no (tran_no, created_at) VALUES(` + new_transaction_number + `,` + date + `)`, []).then((res) => {

      // })
    }

    //end to create transction number

    let transdata = [`'` + new Date() + `'`]
    let local_no;
    await this.dbInstance.executeSql(`INSERT INTO transcation_no (tran_no, created_at) VALUES(` + new_transaction_number + `,` + transdata + `)`, []).then((res) => {
      
      // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
      // local_no = res['insertId']
    })
    // let currentDate = this._datePipe.transform(ordData.created_at, 'full')
    // let currentDate = ordData.created_at_formated
    // let created_date = Date.parse(currentDate.toString());
    let customer_name = ordData.order_customer.name + " " + ordData.order_customer.lname;
    let table_name = '';
    if (ordData.table_id != 0) {
      table_name = ordData.order_table.title;
    }

    let staffNote = ordData.staff_note ? ordData.staff_note : "null";
    let data = [`'` + ordData.store_order_sequence_id + `'`, new_transaction_number, `'` + ordData.id + `'`, ordData.business_id, ordData.state_id, ordData.store_id, ordData.terminal_id,
      0, `'` + ordData.customer_id + `'`, `'` + customer_name + `'`, `'` + ordData.order_customer.phone + `'`, `'` + ordData.adujusted_order_id + `'`, ordData.cash_change,
    ordData.cash_taken, ordData.total_item, ordData.total_item_qty, ordData.total_discount,
    ordData.total_tax, ordData.total_price, ordData.grand_total, 0, 0, 0, ordData.discount_id, ordData.discount_rate,
    ordData.discount_type, 0, 0, 0, 0, ordData.order_status, `'` + ordData.cancellation_reason + `'`, 0, 0, "null", `'` + staffNote + `'`, ordData.tender_profile_id,
    `'` + ordData.tender_profile_name + `'`, `'` + ordData.tender_profile_image + `'`, ordData.price_override_status, this.currentUser.id, ordData.created_at_formated, 1, ordData.round_off,
    ordData.delivery_charge, ordData.device_type, ordData.delivery_type,ordData.table_id,`'` + table_name + `'`];
    let sql = `INSERT INTO orders
    (store_order_sequence_id, local_transaction_no,database_id, business_id, state_id, store_id,terminal_id,
    reference_no,customer_id, customer_name,customer_phone, adujusted_order_id, cash_change,
    cash_taken,total_item,total_item_qty,total_discount,
    total_tax,total_price,grand_total,tax_id,
    tax_rate,tax_type, discount_id,discount_rate,
    discount_type,coupon_id,coupon_discount,coupon_type,
    shipping_cost, order_status, cancellation_reason, payment_status,
    paid_amount,sale_note, staff_note,tender_profile_id,
    tender_profile_name, tender_profile_image, price_override_status, created_by, created_at, isSync, round_off,delivery_charges, device_type, delivery_type,table_id,table_name) VALUES (`+ data.toString() + `)`;

    //insert Data into order_store_detail
    await this.dbInstance.executeSql(sql, []).then((data) => {

      let order = data;
      // Success

      ordData.order_detail.forEach(async cartData => {
        
        //   let price_cmt = cartData.price_override_item_comment != "" ? cartData.price_override_item_comment : 'null';
        //   let tax_in_prc = cartData.tax_include_price ? cartData.tax_include_price : 'null'
        //   let order_cart_data = [
        //     order.insertId, cartData.pricelookup_id, cartData.pricelookup_qty, `'` + cartData.pricelookup_name.replace("'", "`") + `'`,
        //     cartData.pricelookup_item_price, cartData.add_variation, cartData.price_override_item_status, cartData.price_override_item_price,
        //     cartData.price_override_item_id, `'` + price_cmt + `'`, cartData.add_addon, cartData.add_ingredient,
        //     cartData.addon_variation_combine, cartData.addon_ingredient_combine, tax_in_prc
        //   ]
        //   // , cartData.tax_include_price
        //   // tax_include_price
        //   await this.dbInstance.executeSql(`INSERT INTO order_store_detail(order_id, pricelookup_id, pricelookup_qty, pricelookup_name,
        //   pricelookup_item_price, add_variation, price_override_item_status, price_override_item_price,
        //   price_override_item_id, price_override_item_comment, add_addon, add_ingredient,
        //   addon_variation_combine, addon_ingredient_combine,tax_include_price ) VALUES (`+ order_cart_data.toString() + `)`, [])
        // });

        let price_cmt = cartData.price_override_item_comment != "" ? cartData.price_override_item_comment : 'null';
        // let tax_in_prc = cartData.tax_include_price ? cartData.tax_include_price : 'null'
        let specialNote = cartData.note != "" ? cartData.note : '';
        let addOnItemAry = cartData.order_detail_add_add_ons ? JSON.stringify(cartData.order_detail_add_add_ons) : '[]';
        let removeItemAry = cartData.order_detail_remove_add_ons ? JSON.stringify(cartData.order_detail_remove_add_ons) : '[]';
        let is_taken_for_new_item = cartData.is_taken_for_new_item ? cartData.is_taken_for_new_item : 0;
        let plu_type = cartData.plu_type;

        let order_cart_data = [cartData.id,
          order.insertId, cartData.pricelookup_id, cartData.pricelookup_qty, `'` + cartData.pricelookup_name.replace("'", "`") + `'`,
          cartData.pricelookup_item_price, cartData.price_override_item_status, cartData.price_override_item_price,
          cartData.price_override_item_id, `'` + price_cmt + `'`, `'` + addOnItemAry + `'`, `'` + removeItemAry + `'`, cartData.remove_group_id, cartData.add_group_id,
          cartData.is_advance_ingredient, `'` + specialNote + `'`,
          cartData.is_substitute, is_taken_for_new_item, cartData.is_variation_pricelookup, plu_type
        ]
        // , cartData.tax_include_price
        // tax_include_price
        await this.dbInstance.executeSql(`INSERT INTO order_store_detail(database_id,order_id, pricelookup_id, pricelookup_qty, pricelookup_name,
        pricelookup_item_price, price_override_item_status, price_override_item_price,
        price_override_item_id, price_override_item_comment, add_addons_detail, remove_addons_detail,
        remove_group_id,add_group_id,is_advance_ingredient,note, is_substitute,is_taken_for_new_item,is_variation_pricelookup, plu_type ) VALUES (`+ order_cart_data.toString() + `)`, [])

        if(cartData.order_kds_detail_items.length > 0){
          cartData.order_kds_detail_items.forEach(async res => {
            let order_kds_data = [
              res.id, res.kds_id, res.order_id, res.order_detail_id, res.group_id, res.pricelookup_id,res.pricelookup_qty, res.status, res.created_at_formatted
            ]
            await this.dbInstance.executeSql(`INSERT INTO order_store_kds(database_id,kds_id,order_id,order_detail_id,group_id,pricelookup_id,pricelookup_qty,status,created_at) VALUES (` + order_kds_data.toString() + `)`, [])
          });
        }

        // let order_kds_data = [
        //   cartData.get_k_d_s_detail.id,cartData.get_k_d_s_detail.kds_id,cartData.get_k_d_s_detail.order_id,cartData.get_k_d_s_detail.order_detail_id,cartData.get_k_d_s_detail.group_id,cartData.get_k_d_s_detail.pricelookup_id,cartData.get_k_d_s_detail.pricelookup_qty,cartData.get_k_d_s_detail.status,cartData.get_k_d_s_detail.created_at_formatted  
        // ]
        
        
        // // , cartData.tax_include_price
        // await this.dbInstance.executeSql(`INSERT INTO order_store_kds(database_id,kds_id,order_id,order_detail_id,group_id,pricelookup_id,pricelookup_qty,status,created_at) VALUES (`+ order_kds_data.toString() + `)`, [])

      });

      let tenders = ordData.order_type;
      tenders.forEach(async tender => {
        let data_order_type = [order.insertId, tender.order_type_id, tender.order_type, `'` + tender.order_type_name + `'`, tender.order_price]
        await this.dbInstance.executeSql(`INSERT INTO orders_store_order_type (order_id, order_type_id, order_type, order_type_name, order_price) VALUES (` + data_order_type.toString() + `)`, [])
      });
    })
    // this.sync_img = "assets/images/sync_white.png";

    if (ordData.local_transaction_no == 0) {

      ordData.order_id = ordData.id;
      ordData.local_transaction_no = new_transaction_number;
      
      
      
      // debugger
      await this._posService.placeOrder(ordData);
    }

  }

  async findOrderFromLocalData(order) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM orders where database_id = ` + order.id, []).then(async (data) => {
        let orders = [];
        for (let i = 0; i < data.rows.length; i++) {
          orders.push(data.rows.item(i));
        }
        resolve(orders.length);
        
      });
    });
  }

  //end sync online ot miss order

  getPluMenuItem() {
    return new Promise(resolve => {
      //debugger
      var data = {}
      this.http.post(appConfig.apiUrl + '/plumenuitem', data).subscribe(res => {
        
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM plu_menu_item`, []).then((webdata) => {

            let list = [];
            for (let i = 0; i < webdata.rows.length; i++) {
              list.push(webdata.rows.item(i));
            }
            var query = "INSERT INTO plu_menu_item (database_id, store_id, is_advance_ingredient, add_group_id,remove_group_id, background_image, background_color, tax_include, name, standard_price, description, extra_operation,tax_include_price, store_categories, store_tax, tender_profiles, add_groups_addons, remove_groups_addons, store_ingredients,plu_type) VALUES ";
            var rowArgs = [];
            if (list.length > 0) {

              res['data'].forEach(async item => {
                let dataInsert = await this.checkPluMenuItem(item);
                if (dataInsert['length'] == 0) {
                  let bgImage = item.background_image == "" ? 'null' : item.background_image

                  let elementData = [item.id, item.store_id, item.is_advance_ingredient, item.add_group_id, item.remove_group_id, `'` + bgImage + `'`, `'` + item.background_color + `'`, item.tax_include, `'` + item.name.replace(/\'/g, "''") + `'`, `'` + item.standard_price + `'`, `'` + item.description.replace(/\'/g, "''") + `'`, `'` + item.extra_operation + `'`, `'` + item.tax_include_price + `'`, `'` + JSON.stringify(item.store_categories) + `'`, `'` + JSON.stringify(item.store_tax) + `'`, `'` + JSON.stringify(item.tender_profiles) + `'`, `'` + JSON.stringify(item.add_groups_addons) + `'`, `'` + JSON.stringify(item.remove_groups_addons) + `'`, `'` + JSON.stringify(item.store_ingredients) + `'`, item.plu_type]

                  rowArgs.push("(" + elementData + ")");

                  //let data = this.dbInstance.executeSql(`INSERT INTO plu_menu_item(database_id, store_id, is_advance_ingredient, add_group_id,remove_group_id, background_image, background_color, tax_include, name, standard_price, description, extra_operation,tax_include_price, store_categories, store_tax, tender_profiles, add_groups_addons, remove_groups_addons, store_ingredients) VALUES(` + elementData.toString() + `)`, [])
                  //    .then((status) => {})

                  // insert
                  // let elementData = [element.id, element.add_addon, element.add_ingredient, element.add_variation, element.addon_ingredient_combine,
                  // element.addon_variation_combine, `'` + element.background_color + `'`, `'` + element.description + `'`, `'` + element.extra_operation + `'`,
                  // `'` + element.name + `'`, `'` + element.standard_price + `'`, `'` + JSON.stringify(element.store_addons) + `'`,
                  // `'` + JSON.stringify(element.store_categories) + `'`, element.store_id, `'` + JSON.stringify(element.store_tax) + `'`,
                  // `'` + JSON.stringify(element.store_variations) + `'`, element.tax_include, element.tax_include_price, `'` + JSON.stringify(element.tender_profiles) + `'`]
                  // this.dbInstance.executeSql(`INSERT INTO plu_menu_item(database_id, add_addon, add_ingredient, add_variation,
                  //   addon_ingredient_combine, addon_variation_combine, background_color, description, extra_operation,
                  //   name, standard_price, store_addons, store_categories, store_id, store_tax, store_variations, tax_include, tax_include_price, tender_profiles) VALUES(` + elementData.toString() + `)`, [])
                  //   .then((status) => {
                  
                  //   })
                }
              });
            } else {
              res['data'].forEach(async item => {
                let bgImage = item.background_image == "" ? 'null' : item.background_image

                let elementData = [item.id, item.store_id, item.is_advance_ingredient, item.add_group_id, item.remove_group_id, `'` + bgImage + `'`, `'` + item.background_color + `'`, item.tax_include, `'` + item.name.replace(/\'/g, "''") + `'`, `'` + item.standard_price + `'`, `'` + item.description.replace(/\'/g, "''") + `'`, `'` + item.extra_operation + `'`, `'` + item.tax_include_price + `'`, `'` + JSON.stringify(item.store_categories) + `'`, `'` + JSON.stringify(item.store_tax) + `'`, `'` + JSON.stringify(item.tender_profiles) + `'`, `'` + JSON.stringify(item.add_groups_addons) + `'`, `'` + JSON.stringify(item.remove_groups_addons) + `'`, `'` + JSON.stringify(item.store_ingredients) + `'`, item.plu_type]

                rowArgs.push("(" + elementData + ")");

                // this.dbInstance.executeSql(`INSERT INTO plu_menu_item(database_id, store_id, is_advance_ingredient, add_group_id,remove_group_id, background_image, background_color, tax_include, name, standard_price, description, extra_operation,tax_include_price, store_categories, store_tax, tender_profiles, add_groups_addons, remove_groups_addons, store_ingredients) VALUES(` + elementData.toString() + `)`, [])
                //   .then((status) => {
                
                //   })

                // insert data into plu_menu_item_categories
                if (item.store_categories.length > 0) {
                  var query_cat = "INSERT INTO plu_menu_item_categories (plu_menu_item_id, category_id) VALUES ";
                  var rowArgsCat = [];
                  item.store_categories.forEach(async cat_item => {
                    let itemData = [item.id, cat_item.id]
                    rowArgsCat.push("(" + itemData + ")");
                  });
                  query_cat += rowArgsCat.join(", ");
                  await this.dbInstance.executeSql(query_cat, []).then(function (res) {
                    
                  
                  });
                  //this.dbInstance.executeSql(`INSERT INTO plu_menu_item_categories (plu_menu_item_id, category_id) VALUES (` + itemData + `)`, [])
                }

              })
            }
            query += rowArgs.join(", ");
            this.dbInstance.executeSql(query, []).then(function (res) {
              
            }, function (err) {
              
            });
            resolve(res)
          })
        }
        else {
          resolve('')
        }
      }, (err) => {
        resolve(err);

      });
    })
  }
  // end new menu item table insertion

  getAllReasons() {
    return new Promise(resolve => {
      var data = {
        terminal_id: localStorage.getItem('terminal_id'),
        type: "0"
      }
      this.http.post(appConfig.apiUrl + '/getreasons', data).subscribe(res => {
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM reasons`, []).then((data) => {
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            var query = "INSERT INTO reasons(database_id, title, type, isSync) VALUES ";
            var rowArgs = [];
            if (list.length > 0) {
              res['data'].forEach(async element => {
                let dataInsert = await this.checkReasons(element);
                if (dataInsert['length'] == 0) {
                  // insert
                  let elementData = [element.id, `'` + element.title + `'`, `'` + element.type + `'`, 1]
                  // this.dbInstance.executeSql(`INSERT INTO reasons(database_id, title, type, isSync) VALUES(` + elementData.toString() + `)`, [])
                  // .then((res) => {
                  
                  // }).catch((err) => {
                  //   this.spinner.hide();
                  // })
                  rowArgs.push("(" + elementData + ")");
                }
              });
            } else {
              res['data'].forEach(async element => {
                let elementData = [element.id, `'` + element.title + `'`, `'` + element.type + `'`, 1]
                // this.dbInstance.executeSql(`INSERT INTO reasons(database_id, title, type, isSync) VALUES(` + elementData.toString() + `)`, [])
                //   .then((res) => {
                
                //   }).catch((err) => {
                //     this.spinner.hide();
                //   })
                rowArgs.push("(" + elementData + ")");
              })
            }
            query += rowArgs.join(", ");
            this.dbInstance.executeSql(query, [])
              .then((res) => {
                
              }).catch((err) => {
                this.spinner.hide();
              })
            //localStorage.setItem('all_reason_background_check', JSON.stringify(1));
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        
        resolve(err);
      });
    })
  }

  getPLUCategoryList() {
    return new Promise(resolve => {
      var data = {}
      this.http.post(appConfig.apiUrl + '/plucategorylist', data).subscribe(res => {

        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM plu_category_list`, []).then((webdata) => {
            let list = [];
            for (let i = 0; i < webdata.rows.length; i++) {
              list.push(webdata.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {
                let dataInsert = await this.checkPLUCategoryList(element);
                if (dataInsert['length'] == 0) {
                  // insert
                  let elementData = [element.id, `'` + element.name + `'`, `'` + element.code + `'`, `'` + element.short_description + `'`, element.parent_id]
                  await this.dbInstance.executeSql(`INSERT INTO plu_category_list(database_id, name, code, short_description, parent_id) VALUES(` + elementData.toString() + `)`, [])
                    .then((res) => {
                      
                    }).catch((err) => {
                      this.spinner.hide();
                    })
                }
              });
            } else {
              res['data'].forEach(async parentData => {

                let elementData = [parentData.id, `'` + parentData.name + `'`, `'` + parentData.code + `'`, `'` + parentData.short_description + `'`, parentData.parent_id]
                await this.dbInstance.executeSql(`INSERT INTO plu_category_list(database_id, name, code, short_description, parent_id) VALUES(` + elementData.toString() + `)`, [])
                  .then((res) => {
                    if (parentData.get_child_category.length > 0) {
                      //first child insert
                      parentData.get_child_category.forEach(async firstChild => {
                        let frChildData = [firstChild.id, `'` + firstChild.name + `'`, `'` + firstChild.code + `'`, `'` + firstChild.short_description + `'`, firstChild.parent_id]
                        await this.dbInstance.executeSql(`INSERT INTO plu_category_list(database_id, name, code, short_description, parent_id) VALUES(` + frChildData.toString() + `)`, [])
                          .then((res) => {
                            if (firstChild.get_child_category.length > 0) {
                              //second child insert
                              firstChild.get_child_category.forEach(async secondChild => {
                                let snChildData = [secondChild.id, `'` + secondChild.name + `'`, `'` + secondChild.code + `'`, `'` + secondChild.short_description + `'`, secondChild.parent_id]
                                await this.dbInstance.executeSql(`INSERT INTO plu_category_list(database_id, name, code, short_description, parent_id) VALUES(` + snChildData.toString() + `)`, [])
                                  .then((res) => {

                                  }).catch((err) => {
                                    this.spinner.hide();
                                    
                                  })
                              });
                            }
                          }).catch((err) => {
                            this.spinner.hide();
                            
                          })
                      });
                    }
                    
                  }).catch((err) => {
                    this.spinner.hide();
                  })

              })
            }
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        
        resolve(err);
      });
    })
  }

  getTenderTypeListItems() {
    return new Promise(resolve => {
      this.http.post(appConfig.apiUrl + '/tendertypelist', { department_id: 1 }).subscribe(res => {
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM tender_types`, []).then((data) => {
            // let tendertypelist = data.rows;
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {
                let dataInsert = await this.checkTenderType(element);
                if (dataInsert['length'] == 0) {
                  // insert
                  let elementData = [element.id, `'` + element.name + `'`, `'` + element.type + `'`]
                  await this.dbInstance.executeSql(`INSERT INTO tender_types(database_id, name, type) VALUES(` + elementData.toString() + `)`, [])
                    .then((res) => {
                      
                    }).catch((err) => {
                      this.spinner.hide();
                    })
                }
              });
            } else {
              res['data'].forEach(async element => {
                let elementData = [element.id, `'` + element.name + `'`, `'` + element.type + `'`]
                await this.dbInstance.executeSql(`INSERT INTO tender_types(database_id, name, type) VALUES(` + elementData.toString() + `)`, [])
                  .then((res) => {
                    
                  }).catch((err) => {
                    this.spinner.hide();
                  })

              })
            }
            //localStorage.setItem('tender_type_background_check', JSON.stringify(1));
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        
        resolve(err);
      });
    });
  }

  getTenderProfile() {
    return new Promise(resolve => {
      this.http.post(appConfig.apiUrl + '/tenderlist', '').subscribe(res => {
        if (res['status'] == 1) {
          localStorage.setItem('is_table_allow', JSON.stringify(false))
          this.dbInstance.executeSql(`SELECT * FROM tender_profiles_store`, []).then((data) => {
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {
                if (element.is_table_allow == 1) {
                  localStorage.setItem('is_table_allow', JSON.stringify(true))
                }
                let dataInsert = await this.checkTenderProfile(element);
                if (dataInsert['length'] == 0) {
                  // insert
                  let elementData = [element.id, `'` + element.name + `'`, `'` + element.backdround_color + `'`, `'` + element.type + `'`, `'` + element.delivery_type + `'`, `'` + element.is_table_allow + `'`]
                  await this.dbInstance.executeSql(`INSERT INTO tender_profiles_store(database_id, name, backdround_color, type, delivery_type,is_table_allow ) VALUES(` + elementData.toString() + `)`, [])
                    .then((res) => {
                    }).catch((err) => {
                      this.spinner.hide();
                    })
                }
              });

            } else {
              res['data'].forEach(async element => {
                if (element.is_table_allow == 1) {
                  localStorage.setItem('is_table_allow', JSON.stringify(true))
                }
                let elementData = [element.id, `'` + element.name + `'`, `'` + element.backdround_color + `'`, `'` + element.type + `'`, `'` + element.delivery_type + `'`, `'` + element.is_table_allow + `'`]
                await this.dbInstance.executeSql(`INSERT INTO tender_profiles_store(database_id, name, backdround_color, type, delivery_type,is_table_allow) VALUES(` + elementData.toString() + `)`, [])
                  .then((res) => {
                    
                  }).catch((err) => {
                    this.spinner.hide();
                  })
              })
            }
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        
        resolve(err);
      });
    });
  }

  getcustomerListToWebSql() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return new Promise(resolve => {
      this.http.post(appConfig.apiUrl + '/customerlist', '').subscribe(res => {
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM customers`, []).then((data) => {
            // let tendertypelist = data.rows;
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }

            if (list.length > 0) {
              res['data'].forEach(async element => {

                let dataInsert = await this.checkCustomer(element);

                if (dataInsert['length'] == 0) {
                  // insert
                  // res['data'].forEach(element => {
                  if (element.addresses.length > 0) {
                    element.addresses.forEach(async elementadd => {
                      //if(elementadd.isSync == 0){
                      let elementData = [elementadd.id, elementadd.user_id, `'` + elementadd.set_default + `'`, `'` + elementadd.type + `'`,
                      `'` + this.addslashes(elementadd.house_no) + `'`, `'` + this.addslashes(elementadd.society_name) + `'`, `'` + elementadd.city + `'`, `'` + elementadd.state + `'`,
                      `'` + elementadd.pincode + `'`, `'` + elementadd.phone + `'`,
                      `'` + elementadd.lat + `'`, `'` + elementadd.lng + `'`, `'` + elementadd.created_at + `'`, `'` + elementadd.updated_at + `'`, 1
                      ];
                      await this.dbInstance.executeSql(`INSERT INTO addresses(database_id, user_id, set_default, type, house_no, society_name, city,
                              state, pincode, phone, lat, lng, created_at, updated_at, isSync ) VALUES( ` + elementData.toString() + `)`, [])
                        .then((res) => {
                          
                        }).catch((err) => {
                          this.spinner.hide();
                        })
                      //}
                    });
                  }
                  let insertElement = [element.id, this.currentUser.business_id, this.currentUser.state_id, `'` + element.name + `'`, `'`
                    + element.lname + `'`, `'` + element.phone + `'`,
                  `'` + element.email + `'`, `'` + element.address + `'`, `'` + element.city + `'`, `'` + element.state
                  + `'`, `'` + element.postcode + `'`, `'` + element.country + `'`, 1, 1,
                  this.currentUser.id, `'` + new Date() + `'`, this.currentUser.id, `'` + new Date() + `'`]
                  await this.dbInstance.executeSql(`INSERT INTO customers(database_id, business_id, state_id, name, lname, phone, email,
                   address, city, state, postcode, country, status, isSync,
                   created_by, updated_by, created_at, updated_at) VALUES(`+ insertElement.toString() + `)`, [])
                    .then((res) => {
                      
                    }).catch((err) => {
                      this.spinner.hide();
                    })
                  // });
                } else {
                  // if (element.addresses.length > 0) {
                  //   element.addresses.forEach(async elementadd => {
                  //     //if(elementadd.isSync == 0){
                  //       let elementData = [elementadd.id, elementadd.user_id, `'` + elementadd.set_default + `'`, `'` + elementadd.type + `'`,
                  //       `'` + elementadd.house_no + `'`, `'` + elementadd.society_name + `'`, `'` + elementadd.city + `'`, `'` + elementadd.state + `'`,
                  //       `'` + elementadd.pincode + `'`, `'` + elementadd.phone + `'`,
                  //       `'` + elementadd.lat + `'`, `'` + elementadd.lng + `'`, `'` + elementadd.created_at + `'`, `'` + elementadd.updated_at + `'`, 1
                  //       ];
                  //       await this.dbInstance.executeSql(`INSERT INTO addresses(database_id, user_id, set_default, type, house_no, society_name, city,
                  //           state, pincode, phone, lat, lng, created_at, updated_at, isSync ) VALUES( ` + elementData.toString() + `)`, [])
                  //         .then((res) => {
                  
                  //         }).catch((err) => {
                  //           this.spinner.hide();
                  //         })
                  //     //}
                  //   });
                  // }
                }
              });
            } else {
              res['data'].forEach(async element => {
                if (element.addresses.length > 0) {
                  element.addresses.forEach(async elementadd => {
                    //if(elementadd.isSync == 0){
                    let elementData = [elementadd.id, elementadd.user_id, `'` + elementadd.set_default + `'`, `'` + elementadd.type + `'`,
                    `'` + this.addslashes(elementadd.house_no) + `'`, `'` + this.addslashes(elementadd.society_name) + `'`, `'` + elementadd.city + `'`, `'` + elementadd.state + `'`,
                    `'` + elementadd.pincode + `'`, `'` + elementadd.phone + `'`,
                    `'` + elementadd.lat + `'`, `'` + elementadd.lng + `'`, `'` + elementadd.created_at + `'`, `'` + elementadd.updated_at + `'`, 1
                    ];
                    await this.dbInstance.executeSql(`INSERT INTO addresses(database_id, user_id, set_default, type, house_no, society_name, city,
                          state, pincode, phone, lat, lng, created_at, updated_at, isSync ) VALUES( ` + elementData.toString() + `)`, [])
                      .then((res) => {
                        
                      }).catch((err) => {
                        this.spinner.hide();
                      })
                    //}
                  });
                }
                let insertElement = [element.id, this.currentUser.business_id, this.currentUser.state_id, `'` + element.name + `'`, `'`
                  + element.lname + `'`, `'` + element.phone + `'`,
                `'` + element.email + `'`, `'` + element.address + `'`, `'` + element.city + `'`, `'` + element.state
                + `'`, `'` + element.postcode + `'`, `'` + element.country + `'`, 1, 1,
                this.currentUser.id, `'` + new Date() + `'`, this.currentUser.id, `'` + new Date() + `'`]
                await this.dbInstance.executeSql(`INSERT INTO customers(database_id, business_id, state_id, name, lname, phone, email,
               address, city, state, postcode, country, status, isSync,
               created_by, updated_by, created_at, updated_at) VALUES(`+ insertElement.toString() + `)`, [])
                  .then((res) => {
                    
                  }).catch((err) => {
                    this.spinner.hide();
                  })
              });
            }
            //localStorage.setItem('customer_background_check', JSON.stringify(1));
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        
        resolve(err);
      })
    })
  }

  getRiderListToWebSql() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return new Promise(resolve => {
      var obj = {
        store_id: localStorage.getItem('store_id')
      }
      
      this.http.post(appConfig.apiUrl+'/api/mobile/v1/riderlist', obj).subscribe(res => {
        if (res['status'] == 1) {

          this.dbInstance.executeSql(`SELECT * FROM riders`, []).then((data) => {
            // let tendertypelist = data.rows;
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {
                let dataInsert = await this.checkRider(element);

                if (dataInsert['length'] == 0) {
                  // insert
                  // res['data'].forEach(element => {
                  let insertElement = [element.id, element.user_type, element.management_id, element.business_id, element.state_id, `'` + element.username + `'`, `'` + element.name + `'`, `'` + element.lname + `'`, `'` + element.email + `'`, `'` + element.phone + `'`, `'` + element.created_by + `'`,
                  `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`]
                  await this.dbInstance.executeSql(`INSERT INTO riders(database_id,user_type,management_id,  business_id, state_id,username , name, lname, email,phone,
                    created_by, updated_by, created_at, updated_at) VALUES(`+ insertElement.toString() + `)`, [])
                    .then((res) => {
                      
                    }).catch((err) => {
                      this.spinner.hide();
                    })
                  // });
                }
              });
            } else {
              res['data'].forEach(async element => {

                let insertElement = [element.id, element.user_type, element.management_id, element.business_id, element.state_id, `'` + element.username + `'`, `'` + element.name + `'`, `'` + element.lname + `'`, `'` + element.email + `'`, `'` + element.phone + `'`, `'` + element.created_by + `'`,
                `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`]
                await this.dbInstance.executeSql(`INSERT INTO riders(database_id, user_type, management_id,  business_id, state_id, username , name, lname, email, phone, created_by, updated_by, created_at, updated_at) VALUES(` + insertElement.toString() + `)`, [])
                  .then((res) => {
                    
                  }).catch((err) => {
                    this.spinner.hide();
                  })
              });
            }
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        
        resolve(err);
      })
    })
  }

  getDiscount() {
    return new Promise(resolve => {
      this.http.post(appConfig.apiUrl + '/discountlist', '').subscribe(res => {
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM discount`, []).then((data) => {
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {
                let dataInsert = await this.checkDiscount(element);
                if (dataInsert['length'] == 0) {
                  if (element.discount_rule_type == null) {
                    element.discount_rule_type = "null"
                  }
                  if (element.discount_rule == null) {
                    element.discount_rule = "null"
                  }

                  let elementData = [element.id, element.business_id, element.state_id, `'` + element.title + `'`, element.value,
                  element.type, element.add_on, element.rule_type, `'` + element.discount_rule_type + `'`,
                  `'` + element.discount_rule + `'`, `'` + element.description + `'`,
                  `'` + element.terms_condition + `'`, `'` + element.order_type + `'`, element.applicable_on, element.min_value,
                  element.max_value, element.allow_maximum_discount, `'` + element.from_date + `'`, `'` + element.to_date + `'`,
                  `'` + element.from_time + `'`, `'` + element.to_time + `'`, element.validation, `'` + element.discount_days + `'`,
                  element.status];

                  await this.dbInstance.executeSql(`INSERT INTO discount(database_id, business_id, state_id, title, value, type, add_on, rule_type,
                    discount_rule_type, discount_rule, description, terms_condition, order_type, applicable_on, min_value, max_value, allow_maximum_discount,
                    from_date, to_date, from_time, to_time, validation, discount_days, status ) VALUES( ` + elementData.toString() + `)`, [])
                    .then((res) => {
                      
                    }).catch((err) => {
                      this.spinner.hide();
                    })
                }
              });

            } else {
              res['data'].forEach(async element => {
                if (element.discount_rule_type == null) {
                  element.discount_rule_type = "null"
                }
                if (element.discount_rule == null) {
                  element.discount_rule = "null"
                }
                let elementData = [element.id, element.business_id, element.state_id, `'` + element.title + `'`, element.value,
                element.type, element.add_on, element.rule_type, element.discount_rule_type, element.discount_rule, `'` + element.description + `'`,
                `'` + element.terms_condition + `'`, `'` + element.order_type + `'`, element.applicable_on, element.min_value,
                element.max_value, element.allow_maximum_discount, `'` + element.from_date + `'`, `'` + element.to_date + `'`,
                `'` + element.from_time + `'`, `'` + element.to_time + `'`, element.validation, `'` + element.discount_days + `'`,
                element.status]
                await this.dbInstance.executeSql(`INSERT INTO discount(database_id, business_id, state_id, title, value, type, add_on, rule_type,
                  discount_rule_type, discount_rule, description, terms_condition, order_type, applicable_on, min_value, max_value, allow_maximum_discount,
                  from_date, to_date, from_time, to_time, validation, discount_days, status ) VALUES( ` + elementData.toString() + `)`, [])
                  .then((res) => {
                    
                  }).catch((err) => {
                    this.spinner.hide();
                  })
              })
            }
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        resolve(err);
      });
    });
  }

//   Request URL: https://adminpos.limerr.co/api/v1/kds-storeprinters
// Request URL: https://adminpos.limerr.co/api/v1/kds-storereceipttemplate


  getPrinterList() {
    return new Promise(resolve => {
      // var terminal_id = localStorage.getItem('terminal_id')
      var store_id = localStorage.getItem('store_id')
      var kds_id = localStorage.getItem('kds_id')
      var get_departments = JSON.parse(localStorage.getItem('get_departments'))[0]
      var kitchen_department_store_id = get_departments.kitchen_department_store_id;
      this.dbInstance.executeSql(`DELETE FROM store_printer_configurations`, []).then((data) => { })
      // this.http.post(appConfig.apiUrl + '/storeprinters', { store_id: store_id, terminal_id: terminal_id }).subscribe(res => {

     // this.http.post('https://adminpos.limerr.co/api/v1/kds-storeprinters', { store_id: store_id }).subscribe(res => {
      this.http.post(appConfig.apiUrl +'/kds-storeprinters', { store_id: store_id, kitchen_department_store_id: kitchen_department_store_id, kitchen_station_id:kds_id }).subscribe(res => {
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM store_printer_configurations`, []).then((data) => {
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {


                let dataInsert = await this.checkPrinter(element);
                if (dataInsert['length'] == 0) {
                  let elementData = [element.id, element.business_id, element.store_id, element.state_id, element.terminal_id, `'` + element.name + `'`, `'` + element.model + `'`,
                  `'` + element.description + `'`, element.connectivity, element.type, `'` + element.ip_address + `'`,
                  `'` + element.port + `'`, `'` + element.width + `'`,
                  `'` + element.cash_drawer_attached + `'`, `'` + element.language + `'`, element.is_rtl, element.status, `'` + element.created_by + `'`,
                  `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`
                  ];
                  await this.dbInstance.executeSql(`INSERT INTO store_printer_configurations(database_id, business_id, store_id, state_id, terminal_id, name, model, description, connectivity, type,
                    ip_address, port, width, cash_drawer_attached, language, is_rtl, status, created_by, updated_by, created_at, updated_at ) VALUES( ` + elementData.toString() + `)`, [])
                    .then((res) => {
                      
                    }).catch((err) => {
                      
                      this.spinner.hide();
                    })
                }
              });

            } else {
              res['data'].forEach(async element => {

                let elementData = [element.id, element.business_id, element.store_id, element.state_id, element.terminal_id, `'` + element.name + `'`, `'` + element.model + `'`,
                `'` + element.description + `'`, element.connectivity, element.type, `'` + element.ip_address + `'`,
                `'` + element.port + `'`, `'` + element.width + `'`,
                `'` + element.cash_drawer_attached + `'`, `'` + element.language + `'`, element.is_rtl, element.status, `'` + element.created_by + `'`,
                `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`
                ]
                await this.dbInstance.executeSql(`INSERT INTO store_printer_configurations(database_id, business_id, store_id, state_id, terminal_id, name, model, description, connectivity, type,
                  ip_address, port, width, cash_drawer_attached, language, is_rtl, status, created_by, updated_by, created_at, updated_at ) VALUES( ` + elementData.toString() + `)`, [])
                  .then((res) => {
                    
                  }).catch((err) => {
                    this.spinner.hide();
                  })
              })
            }
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        resolve(err);
      });
    });
  }

  getAddressList() {
    return new Promise(resolve => {
      this.http.post(appConfig.apiUrl + '/customeraddresslist', '').subscribe(res => {
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM addresses`, []).then((data) => {
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {
                let dataInsert = await this.checkAddress(element);
                if (dataInsert['length'] == 0) {
                  let elementData = [element.id, element.user_id, `'` + element.set_default + `'`, `'` + element.type + `'`,
                  `'` + element.house_no + `'`, `'` + element.society_name + `'`, `'` + element.city + `'`, `'` + element.state + `'`,
                  `'` + element.pincode + `'`, `'` + element.phone + `'`,
                  `'` + element.lat + `'`, `'` + element.lng + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`
                  ];
                  await this.dbInstance.executeSql(`INSERT INTO addresses(database_id, user_id, set_default, type, house_no, society_name, city,
                    state, pincode, phone, lat, lng, created_at, updated_at ) VALUES( ` + elementData.toString() + `)`, [])
                    .then((res) => {
                      
                    }).catch((err) => {
                      this.spinner.hide();
                    })
                }
              });

            } else {
              res['data'].forEach(async element => {
                let elementData = [element.id, element.user_id, `'` + element.set_default + `'`, `'` + element.type + `'`,
                `'` + element.house_no + `'`, `'` + element.society_name + `'`, `'` + element.city + `'`, `'` + element.state + `'`,
                `'` + element.pincode + `'`, `'` + element.phone + `'`,
                `'` + element.lat + `'`, `'` + element.lng + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`
                ]
                await this.dbInstance.executeSql(`INSERT INTO addresses(database_id, user_id, set_default, type, house_no, society_name, city,
                  state, pincode, phone, lat, lng, created_at, updated_at ) VALUES( ` + elementData.toString() + `)`, [])
                  .then((res) => {
                    
                  }).catch((err) => {
                    this.spinner.hide();
                  })
              })
            }
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        resolve(err);
      });
    });
  }

  posMenuList() {
    return new Promise(resolve => {
      this.http.post(appConfig.apiUrl + '/posmenulist', { parent_id: 0 }).subscribe(res => {
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM posmenulist`, []).then((data) => {
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {
                let dataInsert = await this.checkPosMenuList(element);
                if (dataInsert['length'] == 0) {
                  // insert
                  let elementData = [element.id, element.parent_id, `'` + element.sorting + `'`, `'` + element.name + `'`, `'` + element.permission_name + `'`, `'` + element.description + `'`, `'` + element.link + `'`, `'` + element.class_name + `'`, `'` + element.status + `'`, `'` + element.background_color_code + `'`, `'` + element.background_images + `'`, `'` + element.created_by + `'`,
                  `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`]
                  await this.dbInstance.executeSql(`INSERT INTO posmenulist(database_id, parent_id, sorting, name, permission_name, description, link,class_name, status, background_color_code, background_image, created_by, updated_by, created_at, updated_at ) VALUES(` + elementData.toString() + `)`, [])
                    .then((res) => {
                    }).catch((err) => {
                      this.spinner.hide();
                    })
                }
              });
            } else {
              res['data'].forEach(async element => {
                let elementData = [element.id, element.parent_id, `'` + element.sorting + `'`, `'` + element.name + `'`, `'` + element.permission_name + `'`, `'` + element.description + `'`, `'` + element.link + `'`, `'` + element.class_name + `'`, `'` + element.status + `'`, `'` + element.background_color_code + `'`, `'` + element.background_images + `'`, `'` + element.created_by + `'`,
                `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`]
                await this.dbInstance.executeSql(`INSERT INTO posmenulist(database_id, parent_id, sorting, name, permission_name, description, link,class_name, status, background_color_code, background_image, created_by, updated_by, created_at, updated_at ) VALUES(` + elementData.toString() + `)`, [])
                  .then((res) => {
                    
                  }).catch((err) => {
                    this.spinner.hide();
                  })
              })
            }
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        
        resolve(err);
      });
    });
  }

  storeTableCategories() {
    return new Promise(resolve => {
      this.http.post(appConfig.apiUrl + '/store-table-categories', '').subscribe(res => {
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM store_table_categories`, []).then((data) => {
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {
                let dataInsert = await this.checkStoreTabelCategories(element);
                if (dataInsert['length'] == 0) {
                  // insert
                  let elementData = [element.id, element.business_id, `'` + element.state_id + `'`, `'` + element.store_id + `'`, `'` + element.title + `'`, `'` + element.status + `'`, `'` + element.created_by + `'`, `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`]
                  await this.dbInstance.executeSql(`INSERT INTO store_table_categories(database_id, business_id, state_id, store_id, title, status, created_by, updated_by, created_at, updated_at ) VALUES(` + elementData.toString() + `)`, [])
                    .then((res) => {
                    }).catch((err) => {
                      this.spinner.hide();
                    })
                }
              });
            } else {
              res['data'].forEach(async element => {
                let elementData = [element.id, element.business_id, `'` + element.state_id + `'`, `'` + element.store_id + `'`, `'` + element.title + `'`, `'` + element.status + `'`, `'` + element.created_by + `'`, `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`]
                await this.dbInstance.executeSql(`INSERT INTO store_table_categories(database_id, business_id, state_id, store_id, title, status, created_by, updated_by, created_at, updated_at ) VALUES(` + elementData.toString() + `)`, [])
                  .then((res) => {
                  }).catch((err) => {
                    this.spinner.hide();
                  })
              })
            }
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        
        resolve(err);
      });
    });
  }

  storeTables() {
    return new Promise(resolve => {
      this.http.post(appConfig.apiUrl + '/store-table-list', '').subscribe(res => {
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM store_tables`, []).then((data) => {
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {
                let dataInsert = await this.checkStoreTabels(element);
                if (dataInsert['length'] == 0) {
                  // insert

                  if(element.order_id == 'undefined' || element.order_id == '' || element.order_id == null || element.order_id == 0){
                    element.order_id = 0;
                  }
                  if(element.numberofguest == 'undefined' || element.numberofguest == '' || element.numberofguest == null || element.numberofguest == 0){
                    element.numberofguest = 0;
                  }

                  if (element.table_occupied_time == null || element.table_occupied_time == '' || element.table_occupied_time == 0 || element.table_occupied_time == 'undefined') {
                    element.table_occupied_time = '0';
                  }
                  if (element.merge_tables == null || element.merge_tables == '' || element.merge_tables == 0 || element.merge_tables == 'undefined') {
                    element.merge_tables = '0';
                  }
                  if (element.isMerge == null || element.isMerge == '' || element.isMerge == 0 || element.isMerge == 'undefined') {
                    element.isMerge = '0';
                  }


                  let elementData = [element.id, element.business_id, `'` + element.state_id + `'`, `'` + element.store_id + `'`, `'` + element.category_id + `'`, `'` + element.title + `'`, `'` + element.numberofguest + `'`, `'` + element.capacity + `'`, `'` + element.location + `'`, `'` + element.description + `'`, `'` + element.booking_status + `'`, `'` + element.status + `'`, `'` + element.table_occupied_time + `'`, `'` + element.order_id + `'`, `'` + element.created_by + `'`, `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`]
                  await this.dbInstance.executeSql(`INSERT INTO store_tables(database_id, business_id, state_id, store_id,category_id, title,numberofguest,capacity,location,description,booking_status, status,table_occupied_time,order_id, created_by, updated_by, created_at, updated_at ) VALUES(` + elementData.toString() + `)`, [])
                    .then((res) => {
                    }).catch((err) => {
                      this.spinner.hide();
                    })
                } else {
                  var wherequery;
                  if (isNaN(element.id)) {
                    wherequery = ' where database_id = "' + element.id + '"'
                  } else {
                    wherequery = ' where database_id = ' + element.id + ''
                  }
                  if(element.order_id == 'undefined' || element.order_id == '' || element.order_id == null || element.order_id == 0){
                    element.order_id = 0;
                  }
                  if(element.numberofguest == 'undefined' || element.numberofguest == '' || element.numberofguest == null || element.numberofguest == 0){
                    element.numberofguest = 0;
                  }

                  if (element.table_occupied_time == null || element.table_occupied_time == '' || element.table_occupied_time == 0 || element.table_occupied_time == 'undefined') {
                    element.table_occupied_time = '0';
                  }
                  if (element.merge_tables == null || element.merge_tables == '' || element.merge_tables == 0 || element.merge_tables == 'undefined') {
                    element.merge_tables = '0';
                  }
                  if (element.isMerge == null || element.isMerge == '' || element.isMerge == 0 || element.isMerge == 'undefined') {
                    element.isMerge = '0';
                  }

                  await this.dbInstance.executeSql(`UPDATE store_tables SET isSync = 1, business_id = ` + element.business_id + `, state_id = '` + element.state_id + `', store_id = '` + element.store_id + `', category_id = '` + element.category_id + `', title = '` + element.title + `', numberofguest = '` + element.numberofguest + `', capacity = '` + element.capacity + `', location = '` + element.location + `', description = '` + element.description + `', booking_status = '` + element.booking_status + `', status = '` + element.status + `', table_occupied_time = '` + element.table_occupied_time + `', order_id = '` + element.order_id + `', merge_tables = '` + element.merge_tables + `', isMerge = '` + element.isMerge + `', updated_by = '` + element.updated_by + `', updated_at = '` + element.updated_at + `'` + wherequery, []).then((res) => {
                  }).catch((err) => {
                    this.spinner.hide();
                  })

                }
              });
            } else {
              res['data'].forEach(async element => {

                if(element.order_id == 'undefined' || element.order_id == '' || element.order_id == null || element.order_id == 0){
                  element.order_id = 0;
                }
                if(element.numberofguest == 'undefined' || element.numberofguest == '' || element.numberofguest == null || element.numberofguest == 0){
                  element.numberofguest = 0;
                }

                if (element.table_occupied_time == null || element.table_occupied_time == '' || element.table_occupied_time == 0 || element.table_occupied_time == 'undefined') {
                  element.table_occupied_time = '0';
                }
                if (element.merge_tables == null || element.merge_tables == '' || element.merge_tables == 0 || element.merge_tables == 'undefined') {
                  element.merge_tables = '0';
                }
                if (element.isMerge == null || element.isMerge == '' || element.isMerge == 0 || element.isMerge == 'undefined') {
                  element.isMerge = '0';
                }

                let elementData = [element.id, element.business_id, `'` + element.state_id + `'`, `'` + element.store_id + `'`, `'` + element.category_id + `'`, `'` + element.title + `'`, `'` + element.numberofguest + `'`, `'` + element.capacity + `'`, `'` + element.location + `'`, `'` + element.description + `'`, `'` + element.booking_status + `'`, `'` + element.status + `'`, `'` + element.table_occupied_time + `'`, `'` + element.order_id + `'`, `'` + element.created_by + `'`, `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`]
                await this.dbInstance.executeSql(`INSERT INTO store_tables(database_id, business_id, state_id, store_id,category_id, title,numberofguest,capacity,location,description,booking_status, status,table_occupied_time,order_id, created_by, updated_by, created_at, updated_at ) VALUES(` + elementData.toString() + `)`, [])
                  .then((res) => {
                  }).catch((err) => {
                    this.spinner.hide();
                  })
              })
            }
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        
        resolve(err);
      });
    });
  }

  storeReceiptTemplate() {
    // this.spinner.show();
    var data = {
      store_id: localStorage.getItem('store_id')
    }
    this.cmsService.storeReceiptTemplate(data)
      .subscribe(
        res => {
          if (res.status == 1) {
            this.storeReceiptData = res.data
            localStorage.setItem('storeReceiptData', JSON.stringify(this.storeReceiptData))
          } else {
          }
        },
        error => {
          
        });
  }

  getstorePosBehavior() {
    var data = {
      store_id: localStorage.getItem('store_id')
    }
    this.cmsService.storePosBehavior(data)
      .subscribe(
        res => {
          if (res.status == 1) {
            this.storePosBehavior = res.data;
            localStorage.setItem("is_login_with_pin", this.storePosBehavior.is_login_with_pin);
            
            localStorage.setItem('storePosBehavior', JSON.stringify(this.storePosBehavior))
          } else {
          }
        },
        error => {
          
        });
  }

  // Attendance start
  setCheckAttendance() {
    return new Promise(resolve => {
      var terminal_id = localStorage.getItem('terminal_id')
      var store_id = localStorage.getItem('store_id')
      var temp_attendance_date = new Date();
      var attendance_date = this.transformDate(temp_attendance_date)
      var today = new Date();
      var in_time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var out_time = ""
      var note = ""
      this.http.post(appConfig.apiUrl + '/storeuserattendanceadd', { store_id: store_id, terminal_id: terminal_id, attendance_date: attendance_date, in_time: in_time, out_time: out_time, note: note }).subscribe(res => {
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM store_user_attendance`, []).then((data) => {
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {
                let dataInsert = await this.checkAttendance(element);
                if (dataInsert['length'] == 0) {
                  let elementData = [element.id, element.business_id, element.store_id, element.state_id, element.terminal_id, element.user_id, `'` + new Date() + `'`, `'` + element.in_time + `'`,
                  `'` + element.out_time + `'`, `'` + element.note + `'`, `'` + element.user.name + `'`, element.status, `'` + element.created_by + `'`, `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`
                  ];
                  await this.dbInstance.executeSql(`INSERT INTO store_user_attendance(database_id, business_id, store_id, state_id, terminal_id, user_id, attendance_date, in_time, out_time, note, username, status, created_by, updated_by, created_at, updated_at ) VALUES( ` + elementData.toString() + `)`, [])
                    .then((res) => {
                      
                    }).catch((err) => {
                      
                      this.spinner.hide();
                    })
                }
              });

            } else {
              res['data'].forEach(async element => {
                let elementData = [element.id, element.business_id, element.store_id, element.state_id, element.terminal_id, element.user_id, `'` + new Date() + `'`, `'` + element.in_time + `'`,
                `'` + element.out_time + `'`, `'` + element.note + `'`, `'` + element.user.name + `'`, element.status, `'` + element.created_by + `'`, `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`
                ]
                await this.dbInstance.executeSql(`INSERT INTO store_user_attendance(database_id, business_id, store_id, state_id, terminal_id, user_id, attendance_date, in_time, out_time, note, username, status, created_by, updated_by, created_at, updated_at ) VALUES( ` + elementData.toString() + `)`, [])
                  .then((res) => {
                    
                  }).catch((err) => {
                    
                    this.spinner.hide();
                  })
              })
            }
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        
        resolve(err);
      });
    });
  }

  setCheckoutAttendance() {
    //debugger
    return new Promise(resolve => {
      var terminal_id = localStorage.getItem('terminal_id')
      var store_id = localStorage.getItem('store_id')
      var temp_attendance_date = new Date();
      var attendance_date = this.transformDate(temp_attendance_date)
      var today = new Date();
      var in_time = ""
      var out_time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var note = ""
      this.http.post(appConfig.apiUrl + '/storeuserattendanceadd', { store_id: store_id, terminal_id: terminal_id, attendance_date: attendance_date, in_time: in_time, out_time: out_time, note: note }).subscribe(res => {
        if (res['status'] == 1) {
          this.dbInstance.executeSql(`SELECT * FROM store_user_attendance`, []).then((data) => {
            let list = [];
            for (let i = 0; i < data.rows.length; i++) {
              list.push(data.rows.item(i));
            }
            if (list.length > 0) {
              res['data'].forEach(async element => {
                
                let dataInsert = await this.checkAttendance(element);
                if (dataInsert['length'] == 0) {
                  let elementData = [element.id, element.business_id, element.store_id, element.state_id, element.terminal_id, element.user_id, `'` + element.attendance_date + `'`, `'` + element.in_time + `'`,
                  `'` + element.out_time + `'`, `'` + element.note + `'`, `'` + element.user.name + `'`, element.status, `'` + element.created_by + `'`, `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`
                  ];
                  await this.dbInstance.executeSql(`INSERT INTO store_user_attendance(database_id, business_id, store_id, state_id, terminal_id, user_id, attendance_date, in_time, out_time, note, username, status, created_by, updated_by, created_at, updated_at ) VALUES( ` + elementData.toString() + `)`, [])
                    .then((res) => {
                      
                    }).catch((err) => {
                      this.spinner.hide();
                    })
                }
              });

            } else {
              res['data'].forEach(async element => {
                
                let elementData = [element.id, element.business_id, element.store_id, element.state_id, element.terminal_id, element.user_id, `'` + element.attendance_date + `'`, `'` + element.in_time + `'`,
                `'` + element.out_time + `'`, `'` + element.note + `'`, `'` + element.user.name + `'`, element.status, `'` + element.created_by + `'`, `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`
                ]
                await this.dbInstance.executeSql(`INSERT INTO store_user_attendance(database_id, business_id, store_id, state_id, terminal_id, user_id, attendance_date, in_time, out_time, note, username, status, created_by, updated_by, created_at, updated_at ) VALUES( ` + elementData.toString() + `)`, [])
                  .then((res) => {
                    
                  }).catch((err) => {
                    this.spinner.hide();
                  })
              })
            }
            resolve(res)
          })
        } else {
          resolve('')
        }
      }, (err) => {
        resolve(err);
      });
    });
  }

  getAttendanceList() {
    var data = {
      store_id: localStorage.getItem('store_id'),
      terminal_id: localStorage.getItem("terminal_id"),
      start_date: this.datePipe.transform(new Date(), "yyyy-MM-dd"),
      end_date: this.datePipe.transform(new Date(), "yyyy-MM-dd")
    }
    this._posService.getAttendanceList(data)
      .subscribe(
        res => {
          if (res.status == 1) {
            this.dbInstance.executeSql(`SELECT * FROM store_user_attendance`, []).then((data) => {
              let list = [];
              for (let i = 0; i < data.rows.length; i++) {
                list.push(data.rows.item(i));
              }
              if (list.length > 0) {
                res['data'].forEach(async element => {
                  let dataInsert = await this.checkAttendance(element);
                  if (dataInsert['length'] == 0) {
                    let elementData = [element.id, element.business_id, element.store_id, element.state_id, element.terminal_id, element.user_id, `'` + new Date() + `'`, `'` + element.in_time + `'`,
                    `'` + element.out_time + `'`, `'` + element.note + `'`, `'` + element.user.name + `'`, element.status, `'` + element.created_by + `'`, `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`
                    ];
                    await this.dbInstance.executeSql(`INSERT INTO store_user_attendance(database_id, business_id, store_id, state_id, terminal_id, user_id, attendance_date, in_time, out_time, note, username, status, created_by, updated_by, created_at, updated_at ) VALUES( ` + elementData.toString() + `)`, [])
                      .then((res) => {
                        
                      }).catch((err) => {
                        
                        this.spinner.hide();
                      })
                  }
                });

              } else {
                res['data'].forEach(async element => {
                  let elementData = [element.id, element.business_id, element.store_id, element.state_id, element.terminal_id, element.user_id, `'` + new Date() + `'`, `'` + element.in_time + `'`,
                  `'` + element.out_time + `'`, `'` + element.note + `'`, `'` + element.user.name + `'`, element.status, `'` + element.created_by + `'`, `'` + element.updated_by + `'`, `'` + element.created_at + `'`, `'` + element.updated_at + `'`
                  ]
                  await this.dbInstance.executeSql(`INSERT INTO store_user_attendance(database_id, business_id, store_id, state_id, terminal_id, user_id, attendance_date, in_time, out_time, note, username, status, created_by, updated_by, created_at, updated_at ) VALUES( ` + elementData.toString() + `)`, [])
                    .then((res) => {
                      
                    }).catch((err) => {
                      
                      this.spinner.hide();
                    })
                })
              }
            })
          }
        },
        err => {
          
        }
      )
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }



  calculateDuration(datetime) {
    this.currentDate = new Date();
    var hours = '';
    var minutes = '';
    var diff = this.currentDate.getTime() - new Date(datetime).getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var temphours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var tempminutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (temphours * 60));
    // var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    // return { day: days, hour: hours, minute: minutes, second: seconds };
    if (temphours == 0) {
      hours = "00"
    } else {
      if (temphours < 10) {
        hours = "0" + temphours.toString()
      } else {
        hours = temphours.toString()
      }
    }
    if (tempminutes == 0) {
      minutes = "00"
    } else {

      if (tempminutes < 10) {
        minutes = "0" + tempminutes.toString()
      } else {
        minutes = tempminutes.toString()
      }

    }
    return hours + ":" + minutes
  }

  // Attendance finish

  checkPaidOut(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM paidout where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  checkTenderType(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM tender_types where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  checkTenderProfile(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM tender_profiles_store where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  checkRider(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM riders where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  checkCustomer(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM customers where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  checkPLUCategoryList(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM plu_category_list where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  checkReasons(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM reasons where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  checkPluMenuItem(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM plu_menu_item where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  checkDiscount(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM discount where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);

      });
    })
  }

  checkPrinter(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM store_printer_configurations where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  checkPosMenuList(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM posmenulist where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  checkStoreTabelCategories(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM store_table_categories where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }
  checkStoreTabels(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM store_tables where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  checkAddress(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM addresses where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }


  checkAttendance(data) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM store_user_attendance where database_id = ` + data.id, []).then((data) => {
        resolve(data.rows);
      });
    })
  }

  orderStoreGroupKds(data) {
    
    if (data.length > 0) {
      data.forEach(element => {
        
        this.dbInstance.executeSql(`SELECT * FROM order_store_group_kds where database_id = "` + element.id + `" LIMIT 1`, []).then(async (res) => {

          if (res.rows.length > 0) {
              let transdata = [`'` + new Date() + `'`]
              this.dbInstance.executeSql(`UPDATE order_store_group_kds SET order_id = ` + element.order_id + ` ,status = ` + element.status + ` ,updated_at = ` + transdata + ` where database_id = ` + element.id, []).then((res) => {
              }).catch((err) => {
                console.log(err);
              });
          } else {
            let transdata = [`'` + new Date() + `'`]
            await this.dbInstance.executeSql(`INSERT INTO order_store_group_kds (database_id, order_id, status,created_at,updated_at) VALUES(` + element.id + `,` + element.order_id + `,` + element.status + `,` + transdata + `,` + transdata + `)`, []).then((res) => {
            }).catch((err) => {
                  console.log(err);
                });
          }
        });
      });
    }
  }

  async createSql() {
    //debugger
    // await this.dbInstance.executeSql('CREATE TABLE IF NOT EXISTS user(id INTEGER PRIMARY KEY AUTO_INCREMENT,name TEXT)', []);

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS orders (
     id INTEGER PRIMARY KEY  AUTOINCREMENT,
     database_id TEXT ,
     local_transaction_no INTEGER,
     business_id INTEGER ,
     state_id INTEGER ,
     store_id INTEGER ,
     terminal_id INTEGER ,
     reference_no TEXT DEFAULT NULL,
     customer_id TEXT ,
     customer_name TEXT,
     customer_phone TEXT,
     adujusted_order_id TEXT,
     cash_change DECIMAL(15,2)  DEFAULT '0.00',
     cash_taken DECIMAL(15,2)  DEFAULT '0.00',
     total_item INTEGER ,
     total_item_qty INTEGER ,
     total_discount DECIMAL(15,2) ,
     total_tax DECIMAL(15,2) ,
     total_price DECIMAL(15,2) NOT NULL,
     grand_total DECIMAL(15,2) NOT NULL,
     tax_id INTEGER ,
     tax_rate DECIMAL(15,2) ,
     tax_type INTEGER  ,
     discount_id INTEGER ,
     discount_rate DECIMAL(15,2) ,
     discount_type INTEGER  ,
     coupon_id INTEGER ,
     coupon_discount DECIMAL(15,2) ,
     coupon_type INTEGER ,
     shipping_cost DECIMAL(15,2) ,
     order_status INTEGER ,
     cancellation_reason TEXT DEFAULT NULL,
     payment_status INTEGER ,
     paid_amount DECIMAL(15,2) NOT NULL,
     sale_note TEXT DEFAULT NULL,
     staff_note TEXT DEFAULT NULL,
     tender_profile_id INTEGER,
     tender_profile_name TEXT ,
     tender_profile_image TEXT,
     round_off INTEGER,
     delivery_charges INTEGER,
     price_override_status INTEGER  DEFAULT '0',
     created_by INTEGER ,
     updated_by INTEGER ,
     table_id INTEGER  DEFAULT '0',
     table_name TEXT,
     created_at DATE,
     updated_at DATE,
     isSync INTEGER DEFAULT '0',
     device_type INTEGER,
     delivery_type INTEGER,
     store_order_sequence_id TEXT,
     store_order_id TEXT,
     kds_order_obj TEXT,
     kds_print_flag DEFAULT '1'
   )`, []);

   await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS order_store_kds (
    id INTEGER PRIMARY KEY  AUTOINCREMENT,
    database_id TEXT,
    kds_id INTEGER,
    order_id TEXT,
    order_detail_id TEXT,
    group_id INTEGER DEFAULT '0',
    pricelookup_id TEXT,
    pricelookup_qty TEXT,
    status INTEGER  DEFAULT '0',
    created_at DATE
  )`, []);

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS transcation_no(
    tran_no INTEGER NOT NULL,
    created_at TEXT
  )`, []);

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS start_end_tbl(
    id INTEGER PRIMARY KEY AUTOINCREMENT,
    start_date DATE,
    end_date DATE
  )`, []);

    // for adress
    // type = {0=Home,1=Work,2=Otherr}, set default = {0=No,1=Yes}

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS addresses(
    id INTEGER PRIMARY KEY AUTOINCREMENT,
    database_id INTEGER,
    user_id INTEGER,
    set_default INTEGER,
    type INTEGER,
    house_no TEXT,
    society_name TEXT,
    city TEXT,
    state TEXT,
    pincode TEXT,
    phone TEXT,
    lat TEXT,
    lng TEXT,
    isSync INTEGER DEFAULT '0',
    created_at timestamp NULL DEFAULT CURRENT_TIMESTAMP,
    updated_at timestamp NULL DEFAULT CURRENT_TIMESTAMP)`, []);


    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS orders_store_order_type(
    id INTEGER PRIMARY KEY AUTOINCREMENT,
    order_id INTEGER,
    order_type_id INTEGER,
    order_type INTEGER,
    order_type_name TEXT NOT NULL,
    order_price INTEGER
  )`, []);

  await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS order_store_group_kds(
    id INTEGER PRIMARY KEY AUTOINCREMENT,
    database_id INTEGER,
    order_id INTEGER,
    status INTEGER DEFAULT '0',
    created_at timestamp NULL DEFAULT CURRENT_TIMESTAMP,
    updated_at timestamp NULL DEFAULT CURRENT_TIMESTAMP)`, []);


    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS order_store_detail (
    id INTEGER PRIMARY KEY AUTOINCREMENT,
    database_id INTEGER NOT NULL,
    order_id INTEGER NOT NULL,
    pricelookup_id INTEGER NOT NULL,
    pricelookup_qty INTEGER NOT NULL,
    pricelookup_name TEXT NOT NULL,
    pricelookup_item_price DECIMAL(15,2) NOT NULL,
    tax_include_price DECIMAL(15,2),
    add_variation INTEGER NOT NULL DEFAULT '0',
    price_override_item_status INTEGER NOT NULL DEFAULT '0',
    price_override_item_price DECIMAL(15,2) NOT NULL,
    price_override_item_id INTEGER NOT NULL DEFAULT '0',
    price_override_item_comment TEXT,
    add_addon INTEGER NOT NULL DEFAULT '0',
    add_ingredient INTEGER NOT NULL DEFAULT '0',
    addon_variation_combine INTEGER NOT NULL DEFAULT '0',
    addon_ingredient_combine INTEGER NOT NULL DEFAULT '0',
    add_addons_detail TEXT,
    remove_addons_detail TEXT,
    store_categories TEXT,
    taxes TEXT,
    customer_address TEXT,
    remove_group_id INTEGER,
    add_group_id INTEGER ,
    is_advance_ingredient INTEGER,
    note TEXT,
    is_substitute INTEGER,
    is_taken_for_new_item INTEGER,
    is_variation_pricelookup INTEGER,
    plu_type INTEGER
  )`, []);

    //   await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS pricelookup_addgroup_addons_store(
    //   pricelookup_id INTEGER,
    //   pricelookup_variation_id INTEGER,
    //   standardaddons_id INTEGER
    // )`, []);

    //   await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS pricelookup_removegroup_addons_store(
    //   pricelookup_id INTEGER,
    //   pricelookup_variation_id INTEGER,
    //   standardaddons_id INTEGER
    // )`, []);

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS order_store_detail_addons (
    id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
    order_id INTEGER NOT NULL,
    pricelookup_id INTEGER NOT NULL,
    order_store_detail_id INTEGER NOT NULL,
    order_store_detail_variation_id INTEGER NOT NULL DEFAULT '0',
    addons_id INTEGER NOT NULL,
    addons_name TEXT NOT NULL,
    addons_price DECIMAL(15,2) NOT NULL,
    uom_id INTEGER NOT NULL DEFAULT '0',
    uom_value INTEGER NOT NULL DEFAULT '0'
  )`, []);

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS order_store_detail_variation (
        id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
        order_id INTEGER NOT NULL,
        pricelookup_id INTEGER NOT NULL,
        order_store_detail_id INTEGER NOT NULL,
        variation_id INTEGER NOT NULL,
        variation_name TEXT NOT NULL,
        variation_price DECIMAL(15,2) NOT NULL DEFAULT '0.00'
      )`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS customers (
        id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
        database_id INTEGER,
        business_id INTEGER NOT NULL,
        state_id INTEGER NOT NULL,
        name TEXT DEFAULT NULL,
        lname TEXT DEFAULT NULL,
        phone TEXT DEFAULT NULL,
        email TEXT DEFAULT NULL,
        address TEXT DEFAULT NULL,
        city TEXT DEFAULT NULL,
        state TEXT DEFAULT NULL,
        postcode TEXT DEFAULT NULL,
        country TEXT DEFAULT NULL,
        status INTEGER NULL DEFAULT '0',
        isSync INTEGER DEFAULT '0',
        created_by INTEGER NULL,
        updated_by INTEGER NULL,
        created_at timestamp NULL DEFAULT CURRENT_TIMESTAMP,
        updated_at timestamp NULL DEFAULT CURRENT_TIMESTAMP)`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS tender_profiles_store (
          id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
          database_id INTEGER,
          name TEXT NOT NULL,
          backdround_color TEXT NOT NULL,
          type TEXT NOT NULL,
          delivery_type TEXT NOT NULL,
          is_table_allow TEXT NOT NULL)`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS tender_types (
      id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
      database_id INTEGER,
      name TEXT NOT NULL,
      type TEXT NOT NULL)`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS reasons (
        id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
        database_id INTEGER NULL,
        business_id INTEGER NULL,
        state_id INTEGER NULL,
        title TEXT NOT NULL,
        type TEXT NOT NULL,
        status INTEGER NULL,
        created_by INTEGER NULL,
        updated_by INTEGER NULL,
        created_at timestamp NULL DEFAULT CURRENT_TIMESTAMP,
        updated_at timestamp NULL DEFAULT CURRENT_TIMESTAMP,
        isSync INTEGER DEFAULT '0')`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS plu_menu_item_categories (
          plu_menu_item_id INTEGER NOT NULL,
          category_id INTEGER NOT NULL
          )`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS plu_menu_item(
      database_id INTEGER,
      store_id INTEGER NOT NULL,
      is_advance_ingredient INTEGER NOT NULL,
      add_group_id INTEGER NOT NULL,
      remove_group_id INTEGER NOT NULL,
      background_image TEXT NOT NULL,
      background_color TEXT NOT NULL,
      tax_include INTEGER NOT NULL,
      name TEXT NOT NULL,
      standard_price TEXT NOT NULL,
      description TEXT NOT NULL,
      extra_operation TEXT NOT NULL,
      tax_include_price TEXT NOT NULL,
      store_categories TEXT NOT NULL,
      store_tax TEXT NOT NULL,
      tender_profiles TEXT NOT NULL,
      add_groups_addons TEXT NOT NULL,
      remove_groups_addons TEXT NOT NULL,
      store_ingredients TEXT NOT NULL,
      plu_type INTEGER DEFAULT '0'

         )`, [])

    //  id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
    //   database_id INTEGER,
    //   add_addon INTEGER NOT NULL,
    //   add_ingredient INTEGER NOT NULL,
    //   add_variation INTEGER NOT NULL,
    //   addon_ingredient_combine INTEGER NOT NULL,
    //   addon_variation_combine INTEGER NOT NULL,
    //   background_color TEXT NOT NULL,
    //   description TEXT NOT NULL,
    //   extra_operation TEXT NOT NULL,
    //   name TEXT NOT NULL,
    //   standard_price TEXT NOT NULL,
    //   store_addons TEXT NOT NULL,
    //   store_categories TEXT NOT NULL,
    //   store_id INTEGER NOT NULL,
    //   store_tax TEXT NOT NULL,
    //   store_variations TEXT NOT NULL,
    //   tax_include INTEGER NOT NULL,
    //   tax_include_price INTEGER NOT NULL,
    //   tender_profiles TEXT NOT NULL

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS plu_category_list (
        database_id INTEGER,
        name TEXT NOT NULL,
        code TEXT NOT NULL,
        parent_id INTEGER NOT NULL,
        short_description TEXT NOT NULL)`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS discount (
        id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
        database_id INTEGER,
        business_id INTEGER NOT NULL,
        state_id INTEGER NOT NULL,
        title TEXT NOT NULL,
        value INTEGER NOT NULL,
        type INTEGER NOT NULL,
        add_on INTEGER DEFAULT NULL,
        rule_type INTEGER DEFAULT NULL,
        discount_rule_type INTEGER NULL DEFAULT NULL,
        discount_rule INTEGER NULL DEFAULT NULL,
        description TEXT,
        terms_condition TEXT,
        order_type TEXT DEFAULT NULL,
        applicable_on INTEGER DEFAULT NULL,
        min_value INTEGER DEFAULT NULL,
        max_value INTEGER DEFAULT NULL,
        allow_maximum_discount INTEGER DEFAULT NULL,
        from_date TEXT DEFAULT NULL,
        to_date TEXT DEFAULT NULL,
        from_time TEXT DEFAULT NULL,
        to_time TEXT DEFAULT NULL,
        validation INTEGER DEFAULT NULL,
        discount_days TEXT DEFAULT NULL,
        status INTEGER NOT NULL)`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS paidout (
          id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
          database_id INTEGER,
          amount TEXT,
          amount_type INTEGER,
          clerk_id INTEGER,
          comment TEXT,
          paid_out_reason_id INTEGER,
          isSync INTEGER DEFAULT '0',
          created_time TEXT,
          created_time_formated INTEGER
          )`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS cashout (
            id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
            database_id INTEGER,
            amount TEXT,
            amount_type INTEGER,
            clerk_id INTEGER,
            comment TEXT,
            cash_out_reason_id INTEGER,
            isSync INTEGER DEFAULT '0',
            created_time TEXT,
            created_time_formated INTEGER
            )`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS push_notifications (
              id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
              order_id INTEGER,
              isSync INTEGER DEFAULT '0',
              created_time TEXT DEFAULT NULL,
              created_time_formated INTEGER DEFAULT NULL
              )`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS riders (
                  database_id INTEGER,
                  user_type INTEGER,
                  management_id INTEGER,
                  business_id INTEGER NOT NULL,
                  state_id INTEGER NOT NULL,
                  username INTEGER,
                  name TEXT DEFAULT NULL,
                  lname TEXT DEFAULT NULL,
                  email TEXT DEFAULT NULL,
                  phone TEXT DEFAULT NULL,
                  created_by INTEGER NULL,
                  updated_by INTEGER NULL,
                  created_at timestamp NULL DEFAULT CURRENT_TIMESTAMP,
                  updated_at timestamp NULL DEFAULT CURRENT_TIMESTAMP)`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS store_printer_configurations (
                  id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
                  database_id INTEGER,
                  business_id INTEGER,
                  store_id INTEGER,
                  state_id INTEGER,
                  terminal_id INTEGER,
                  name TEXT NOT NULL,
                  model TEXT NOT NULL,
                  description TEXT NOT NULL,
                  connectivity INTEGER NOT NULL,
                  type INTEGER DEFAULT 0,
                  ip_address TEXT NOT NULL,
                  port TEXT NOT NULL,
                  width TEXT DEFAULT NULL,
                  cash_drawer_attached INTEGER DEFAULT '0',
                  language INTEGER DEFAULT '0',
                  is_rtl INTEGER DEFAULT '1',
                  status INTEGER DEFAULT '0',
                  created_by INTEGER NOT NULL,
                  updated_by INTEGER NOT NULL,
                  created_at timestamp NULL DEFAULT CURRENT_TIMESTAMP,
                  updated_at timestamp NULL DEFAULT CURRENT_TIMESTAMP)`, [])

    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS store_user_attendance (
                    id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
                    database_id INTEGER,
                    business_id INTEGER,
                    store_id INTEGER,
                    state_id INTEGER,
                    terminal_id INTEGER,
                    user_id INTEGER,
                    attendance_date TEXT,
                    in_time TEXT,
                    out_time TEXT,
                    note TEXT NOT NULL,
                    username TEXT NOT NULL,
                    status INTEGER DEFAULT '0',
                    created_by INTEGER NOT NULL,
                    updated_by INTEGER NOT NULL,
                    created_at timestamp NULL DEFAULT CURRENT_TIMESTAMP,
                    updated_at timestamp NULL DEFAULT CURRENT_TIMESTAMP)`, [])


    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS posmenulist (
      id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
      database_id INTEGER,
      parent_id INTEGER,
      sorting INTEGER,
      name TEXT,
      permission_name TEXT,
      description TEXT,
      link TEXT,
      class_name TEXT,
      status INTEGER DEFAULT '0',
      background_color_code TEXT,
      background_image TEXT,
      created_by INTEGER NOT NULL,
      updated_by INTEGER NOT NULL,
      created_at timestamp NULL DEFAULT CURRENT_TIMESTAMP,
      updated_at timestamp NULL DEFAULT CURRENT_TIMESTAMP)`, [])


    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS store_table_categories (
      id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
      database_id INTEGER,
      business_id INTEGER,
      state_id INTEGER,
      store_id INTEGER,
      title TEXT,
      status INTEGER DEFAULT '0',
      created_by INTEGER NOT NULL DEFAULT '0',
      updated_by INTEGER NOT NULL DEFAULT '0',
      created_at timestamp NULL DEFAULT CURRENT_TIMESTAMP,
      updated_at timestamp NULL DEFAULT CURRENT_TIMESTAMP)`, [])


    await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS store_tables (
      id  INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
      database_id INTEGER,
      business_id INTEGER,
      state_id INTEGER,
      store_id INTEGER,
      category_id INTEGER,
      title TEXT,
      numberofguest INTEGER DEFAULT '0',
      capacity INTEGER,
      location TEXT,
      description TEXT,
      booking_status INTEGER NOT NULL DEFAULT '0',
      status INTEGER DEFAULT '0',
      table_occupied_time TEXT DEFAULT '0',
      order_id TEXT DEFAULT '0',
      merge_tables TEXT DEFAULT '0',
      isMerge TEXT DEFAULT '0',
      isSync TEXT DEFAULT '1',
      created_by INTEGER NOT NULL DEFAULT '0',
      updated_by INTEGER NOT NULL DEFAULT '0',
      created_at timestamp NULL DEFAULT CURRENT_TIMESTAMP,
      updated_at timestamp NULL DEFAULT CURRENT_TIMESTAMP)`, [])

      
    // await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS kds_orders(
    //   id INTEGER PRIMARY KEY AUTOINCREMENT,
    //   order_id TEXT DEFAULT '0',
    //   title TEXT,
    //   number_of_items INTEGER,
    //   status INTEGER DEFAULT '0',
    //   time timestamp NULL DEFAULT CURRENT_TIMESTAMP,
    //   open_time timestamp NULL DEFAULT CURRENT_TIMESTAMP
    // )`, []);

    // await this.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS kds_order_items(
    //   id INTEGER PRIMARY KEY AUTOINCREMENT,
    //   title TEXT,
    //   number_of_items INTEGER,
    //   time timestamp NULL DEFAULT CURRENT_TIMESTAMP,
    //   open_time timestamp NULL DEFAULT CURRENT_TIMESTAMP
    // )`, []);

    // await this.dbInstance.executeSql(`INSERT INTO store_printer_configurations (title, model,description,connectivity,type,ip_address,port,width,cash_drawer_attached,language,is_rtl,status,created_by,updated_by) VALUES('KOT','1','TEST','1',0,'EA:19:04:00:21:1F','0','0','0','0','1','1','1','1'),('Order','1','TEST','1',1,'EA:19:04:00:21:1F','0','0','0','0','1','1','1','1')`, []).then((res) => {
    
    // })
  }

  syncReasons() {
    this.dbInstance.executeSql(`SELECT * FROM reasons where isSync = 0`, []).then(async (data) => {
      let reasons = [];
      for (let i = 0; i < data.rows.length; i++) {
        reasons.push(data.rows.item(i));
      }
      reasons.forEach(async reas => {
        reas.reason_title = reas.title
        let status = await this.cmsService.newpaidOutReason(reas);
        if (status['status'] != 0) {
          let serverReason = status['data'];
          let update = await this.updateReasonSyncInWbSql(reas, serverReason);
        }
      });

    });
  }

  async updateReasonSyncInWbSql(reas, serverReason) {
    await this.dbInstance.executeSql(`UPDATE reasons SET isSync = 1, database_id = ` + serverReason.id + `  where id = ` + reas.id, [])
  }


  syncAddress() {
    this.dbInstance.executeSql(`SELECT * FROM addresses where isSync = 0`, []).then(async (data) => {

      let address = [];
      for (let i = 0; i < data.rows.length; i++) {
        address.push(data.rows.item(i));
      }

      address.forEach(async addre => {
        let addrenew = addre;
        addrenew.id = addre.database_id;
        let status = await this._posService.saveAddressForCustomer(addrenew);
        if (status['status'] != 0) {
          // debugger;
          let serverAddress = status['data'];
          let update = await this.updateAddressSyncInWbSql(addre, serverAddress);
        }
      });
    });
  }

  // syncUpdateAddress() {
  //   this.dbInstance.executeSql(`UPDATE addresses SET isSync = 1`, []).then(async (data) => {
  //     let address = [];
  //     for (let i = 0; i < data.rows.length; i++) {
  //       address.push(data.rows.item(i));
  //     }
  //     address.forEach(async addre => {
  
  //       let status = await this._posService.saveAddressForCustomer(addre);
  //       if (status['status'] != 0) {
  //         let serverAddress = status['data'];
  //         let update = await this.updateAddressSyncInWbSql(addre, serverAddress);
  //       }
  //     });
  //   });
  // }


  async updateAddressSyncInWbSql(addre, serverAddress) {
    await this.dbInstance.executeSql(`UPDATE addresses SET isSync = 1, database_id = ` + serverAddress.id + ` where database_id = '` + addre.database_id + `'`, [])
  }

  syncCustomer() {
    this.dbInstance.executeSql(`SELECT * FROM customers where isSync = 0`, []).then(async (data) => {
      let customers = [];
      for (let i = 0; i < data.rows.length; i++) {
        customers.push(data.rows.item(i));
      }
      customers.forEach(async cust => {
        let status = await this.cmsService.addCustomer(cust);
        if (status['status'] != 0) {
          let serverCustomer = status['data'];
          let update = await this.updateCustomerSyncInWbSql(cust, serverCustomer);
        }
      });

    });
  }

  async updateCustomerSyncInWbSql(order, serverCustomer) {
    await this.dbInstance.executeSql(`UPDATE customers SET isSync = 1, database_id = ` + serverCustomer.id + `  where id = ` + order.id, [])
  }

  syncStoreTable() {
    this.dbInstance.executeSql(`SELECT * FROM store_tables where isSync = 0`, []).then(async (data) => {
      let store_tables = [];
      for (let i = 0; i < data.rows.length; i++) {
        store_tables.push(data.rows.item(i));
      }
      store_tables.forEach(async table => {
        table.table_id = table.database_id;
        let status = await this.cmsService.addTable(table);
        if (status['status'] != 0) {
          let serverTables = status['data'];
          let update = await this.updatetablesSyncInWbSql(table, serverTables);
        }
      });

    });
  }



  async updatetablesSyncInWbSql(table, serverTables) {
    await this.dbInstance.executeSql(`UPDATE store_tables SET isSync = 1, database_id = ` + serverTables.id + `  where id = ` + table.id, [])
  }



  async paidoutSync() {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM paidout where isSync = 0`, []).then(async (data) => {
        let paidouts = [];
        for (let i = 0; i < data.rows.length; i++) {
          paidouts.push(data.rows.item(i));
        }
        let index = 0;
        for (index; index < paidouts.length; index++) {
          const paid = paidouts[index];
          var obj = {
            terminal_id: localStorage.getItem('terminal_id'),
            store_id: localStorage.getItem('store_id'),
            clerk_id: paid.clerk_id,
            paid_out_reason_id: paid.paid_out_reason_id,
            comment: paid.comment,
            amount_type: paid.amount_type,
            amount: paid.amount,
            created_time: paid.time
          }
          let status = await this.cmsService.paidOut((obj))
          let serverPaidout = status['data'];
          // Update isSync local
          let update = await this.updatePaidoutSyncInWbSql(paid, serverPaidout);
        }
        resolve(paidouts)
      })
    });
  }

  async updatePaidoutSyncInWbSql(paid, serverPaidout) {
    await this.dbInstance.executeSql(`UPDATE paidout SET isSync = 1, database_id = ` + serverPaidout.id + `  where id = ` + paid.id, [])
  }

  async cashoutSync() {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM cashout where isSync = 0`, []).then(async (data) => {
        let cashouts = [];
        for (let i = 0; i < data.rows.length; i++) {
          cashouts.push(data.rows.item(i));
        }
        let index = 0;
        for (index; index < cashouts.length; index++) {
          const cash = cashouts[index];
          var obj = {
            terminal_id: localStorage.getItem('terminal_id'),
            store_id: localStorage.getItem('store_id'),
            clerk_id: cash.clerk_id,
            cash_out_reason_id: cash.cash_out_reason_id,
            comment: cash.comment,
            amount_type: cash.amount_type,
            amount: cash.amount,
            created_time: cash.time
          }
          let status = await this.cmsService.cashOut((obj))
          let serverCashout = status['data'];
          // Update isSync local
          let update = await this.updateCashoutSyncInWbSql(cash, serverCashout);
        }
        resolve(cashouts)
      })
    });
  }

  async updateCashoutSyncInWbSql(cash, serverCashout) {
    await this.dbInstance.executeSql(`UPDATE cashout SET isSync = 1, database_id = ` + serverCashout.id + `  where id = ` + cash.id, [])
  }
  // Sqlite to Server Db sync
  async syncForOrders() {//fun for checkout and checkout with KOT//API Call

    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM orders where isSync = 0`, []).then(async (data) => {
        let orders = [];
        let order_type: any = [];
        let adjustedOrder: any = [];
        for (let i = 0; i < data.rows.length; i++) {
          orders.push(data.rows.item(i));
        }
        let index = 0;
        for (index; index < orders.length; index++) {
          const order = orders[index];
          // let customer = await this.getCustomerByDatabaseId(order.customer_id);
          // order.customer_id = customer['database_id'];
          // order.customer_name = customer['name']
          let details = await this.getOrderDetailsById(order.id);
          for (let i = 0; i < details['length']; i++) {
            details[i].add_addons_detail = JSON.parse(details[i].add_addons_detail)
            details[i].remove_addons_detail = JSON.parse(details[i].remove_addons_detail)
            details[i].taxes = JSON.parse(details[i].taxes)
          }
          order.order_detail = details;
          
          order_type = await this.getTenderTypeById(order.id);
          order.order_type = order_type;
          // get order if adjusted
          adjustedOrder = await this.getAdjustedOrderById(order.database_id);
          if (order.isSync < 1 && order.order_status == 2 && !isNaN(parseInt(order.database_id))) {
            
            //return
            var obj = {
              terminal_id: JSON.parse(localStorage.getItem('terminal_id')),
              store_id: JSON.parse(localStorage.getItem('store_id')),
              clerk_id: this.currentUser.id,
              order_status: 2,
              order_id: order.database_id,
              cancel_reason: order.cancellation_reason
            }
            let status = await this._posService.ordercancel(obj);
            let serverOrder = status['data'];
            // Update isSync local
            let update = await this.updateOrderSyncInWbSql(order, serverOrder);
          }
          if (order.isSync < 1) {
            // if (order.isSync < 1 && isNaN(parseInt(order.database_id))) {
            
            if (order.adujusted_order_id == 0 && order.database_id > 0) {//recall order
              order.order_id = order.database_id;
            }
            
            //return
            // debugger
            let api_order_response = await this._posService.placeOrder(order);
            
            
            if (api_order_response['status'] != 0) {
              let serverOrder = api_order_response['data'];
              if (adjustedOrder.length > 0) {
                // local order
                adjustedOrder = adjustedOrder[0];
                let update = await this.updateOrderForAdjustedInWbSql(adjustedOrder, serverOrder);
                
              }
              let update = await this.updateOrderSyncInWbSql(order, serverOrder);
              
            }
          }

          // if(order.adujusted_order_id != 0){
          //   // Order cancel and adjust
          //   // cancel api
          //   var obj = {
          //     terminal_id:  localStorage.getItem('terminal_id'),
          //     store_id: localStorage.getItem('store_id'),
          //     clerk_id: this.currentUser.id,
          //     order_status: 2,
          //     order_id: order.adujusted_order_id,
          //     cancel_reason: order.cancellation_reason
          //   }
          //   let cancelStatus = await this._posService.ordercancel(obj);
          
          //   // placeorder new
          //   let status = await this._posService.placeOrder(order);
          
          //   if (status['status'] != 0) {
          //     let serverOrder = status['data'];
          //     let update = await this.updateOrderSyncInWbSql(order, serverOrder);
          
          //   }
          // }else{
          //   // placeorder new
          //   let status = await this._posService.placeOrder(order);
          
          //   if (status['status'] != 0) {
          //     let serverOrder = status['data'];
          //     let update = await this.updateOrderSyncInWbSql(order, serverOrder);
          
          //   }
          // }
        }

        // orders.forEach(async order => {
        //   let customer = await this.getCustomerById(order.customer_id);
        //   order.customer_id = customer['database_id'];
        //   order.customer_name = customer['name']
        //   let details = await this.getOrderDetailsById(order.id);
        //   order.order_detail = details;
        //   order_type = await this.getTenderTypeById(order.id);
        //   order.order_type = order_type;
        //   let status = await this._posService.placeOrder(order);
        
        //   if (status['status'] != 0) {
        //     let serverOrder = status['data'];
        //     let update = await this.updateOrderSyncInWbSql(order, serverOrder);
        
        //     // let serverOrder = update['data'];
        //     // let syncdOrder = await this.updateOrderInWbSql(serverOrder, order);
        
        //   }
        // });
        
        resolve(orders)
      });
    })
  }

  // syncForOrders() {
  //   return new Promise(resolve => {
  //     this.dbInstance.executeSql(`SELECT * FROM orders where isSync = 0`, []).then(async (data) => {
  //       let orders = [];
  //       let order_type: any = [];
  //       for (let i = 0; i < data.rows.length; i++) {
  //         orders.push(data.rows.item(i));
  //       }
  
  //       orders.forEach(async order => {
  //         let customer = await this.getCustomerById(order.customer_id);
  //         order.customer_id = customer['database_id'];
  //         order.customer_name = customer['name']
  //         let details = await this.getOrderDetailsById(order.id);
  //         order.order_detail = details;
  //         order_type = await this.getTenderTypeById(order.id);
  //         order.order_type = order_type;
  //         let status = await this._posService.placeOrder(order);
  
  //         if (status['status'] != 0) {
  //           let serverOrder = status['data'];
  //           let update = await this.updateOrderSyncInWbSql(order, serverOrder);
  
  //           // let serverOrder = update['data'];
  //           // let syncdOrder = await this.updateOrderInWbSql(serverOrder, order);
  
  //         }
  //       });
  
  //       resolve(orders)
  //     });
  //   })

  // }

  async updateOrderSyncInWbSql(order, serverOrder) {
    let query
    if (isNaN(order.database_id)) {
      query = ' AND database_id = "' + order.database_id + '" '
    } else {
      query = ' AND database_id = ' + order.database_id + ''
    }
    let query_table;
    if (isNaN(order.database_id)) {
      query_table = ' where order_id = "' + order.database_id + '" '
    } else {
      query_table = ' where order_id = ' + order.database_id + ''
    }
    let sql = `UPDATE store_tables SET order_id = ` + serverOrder.id + ' ' + query_table;
    await this.dbInstance.executeSql(sql, []).then((data) => {
      if (this.internetStatus != "Offline") {
        this.syncStoreTable();
      }
    });
    await this.dbInstance.executeSql(`UPDATE orders SET isSync = 1, database_id = ` + serverOrder.id + `  where id = ` + order.id + query, []);
    await this.dbInstance.executeSql(`UPDATE customers SET database_id = ` + serverOrder.customer_id + `  where phone = ` + serverOrder.customer_phone, [])
  }

  async updateOrderForAdjustedInWbSql(order, serverOrder) {
    let query
    if (isNaN(order.database_id)) {
      query = ' AND database_id = "' + order.database_id + '" '
    } else {
      query = ' AND database_id = ' + order.database_id + ''
    }
    await this.dbInstance.executeSql(`UPDATE orders SET isSync = 0, adujusted_order_id = ` + serverOrder.id + `  where id = ` + order.id + query, [])
    await this.dbInstance.executeSql(`UPDATE customers SET database_id = ` + serverOrder.customer_id + `  where phone = ` + serverOrder.customer_phone, [])
  }

  getOrdersfromWebSql(filter) {
    return new Promise(async resolve => {
      // let sql = `SELECT * FROM orders where order_status = `+ filter.order_status +` order by created_at DESC`;
      let sql = `SELECT * FROM orders `;
      var where = 'where 1=1';
      if (filter.order_status != null) {
        where += ' AND order_status = ' + filter.order_status;
      }
      if (filter.order_status_array != null) {
        where += ' AND order_status In (' + filter.order_status_array + ')';
      }
      if (filter.clerk_id != null && filter.clerk_id != undefined && filter.clerk_id != "") {
        where += ' AND created_by = ' + filter.clerk_id;
      }
      if (filter.transaction_no != null && filter.transaction_no != undefined && filter.transaction_no != "") {
        // where += ' AND database_id = ' + filter.transaction_no;
        where += ' AND local_transaction_no = ' + filter.transaction_no;
      }
      if (filter.tender_profile_id != null && filter.tender_profile_id != undefined) {
        where += ' AND tender_profile_id = ' + filter.tender_profile_id;
      }
      // debugger
      if (filter.filter_tender_profile_id != undefined && filter.filter_tender_profile_id.length != 0) {
        where += ' AND tender_profile_id  IN (' + filter.filter_tender_profile_id.toString() + ')'
      }
      if (filter.order_no != null && filter.order_no != undefined) {
        where += ' AND store_order_sequence_id LIKE "%' + filter.order_no + '"';
      }
      //if (filter.order_no != null && filter.order_no != undefined) {
      //  where += ' AND database_id = ' + filter.order_no;
      //}

      if (filter.mobile_no != null && filter.mobile_no != undefined) {
        where += ' AND customer_phone = ' + filter.mobile_no;
      }
      if (filter.name != null && filter.name != undefined) {
        where += ` AND customer_name LIKE "%` + filter.name + `%"`;
      }

      sql += where;
      let startDate = await this.getStartDate()

      //let start_date = startDate['start_date'];
      let newStartDate = Date.parse(startDate['start_date']);
      //  let newStartDate1 = Date.parse('Fri Jul 23 2021 01:05:09 GMT+0530 (India Standard Time)')
      sql += ` AND created_at >= ` + newStartDate + ` order by local_transaction_no DESC`;
      
      this.dbInstance.executeSql(sql, []).then(async (data) => {
        let orders = [];
        let order_type: any = [];
        for (let i = 0; i < data.rows.length; i++) {
          orders.push(data.rows.item(i));
        }

        orders.forEach(async element => {
          // let customer = await this.getCustomerByDatabaseId(element.customer_id);
          // element.order_customer = customer;
          let details = await this.getOrderDetailsById(element.id);
          element.order_details = details;
          order_type = await this.getTenderTypeById(element.id);
          let tender_type_name_list = []
          order_type.forEach(async type => {
            if (parseInt(type.order_price) > 0) {
              tender_type_name_list.push(type.order_type_name);
            }
          });
          element.tender_type_name = tender_type_name_list.toString()
          element.order_type = order_type;
          element.selectionColor = false;
        });
        let rowNumbers = await this.getOrderCounts(filter);
        let datas = {
          orders: orders,
          totals: rowNumbers
        }
        
        resolve(datas);
      });
    });
  }


  getKdsOrdersfromWebSqlNew(filter,is_for_completed = 0) {
    return new Promise(async resolve => {
      // let sql = `SELECT * FROM orders where order_status = `+ filter.order_status +` order by created_at DESC`;
      let sql = `SELECT * FROM orders `;
      var where = 'where 1=1';
      if (filter.order_status != null) {
        where += ' AND order_status = ' + filter.order_status;
      }
      
      if (filter.order_status_array != null) {
        where += ' AND order_status In (' + filter.order_status_array + ')';
      }
      if (filter.clerk_id != null && filter.clerk_id != undefined && filter.clerk_id != "") {
        where += ' AND created_by = ' + filter.clerk_id;
      }
      if (filter.transaction_no != null && filter.transaction_no != undefined && filter.transaction_no != "") {
        // where += ' AND database_id = ' + filter.transaction_no;
        where += ' AND local_transaction_no = ' + filter.transaction_no;
      }
      if (filter.tender_profile_id != null && filter.tender_profile_id != undefined) {
        where += ' AND tender_profile_id = ' + filter.tender_profile_id;
      }
      // debugger
      if (filter.filter_tender_profile_id != undefined && filter.filter_tender_profile_id.length != 0) {
        where += ' AND tender_profile_id  IN (' + filter.filter_tender_profile_id.toString() + ')'
      }
      if (filter.order_no != null && filter.order_no != undefined) {
        where += ' AND store_order_sequence_id LIKE "%' + filter.order_no + '"';
      }
      //if (filter.order_no != null && filter.order_no != undefined) {
      //  where += ' AND database_id = ' + filter.order_no;
      //}

      if (filter.mobile_no != null && filter.mobile_no != undefined) {
        where += ' AND customer_phone = ' + filter.mobile_no;
      }
      if (filter.name != null && filter.name != undefined) {
        where += ` AND customer_name LIKE "%` + filter.name + `%"`;
      }
      
      sql += where;
      //let startDate = await this.getStartDate()

      //let start_date = startDate['start_date'];
      //let newStartDate = Date.parse(startDate['start_date']);
      //  let newStartDate1 = Date.parse('Fri Jul 23 2021 01:05:09 GMT+0530 (India Standard Time)')
      
      // if(filter.order_status_array.indexOf(7) !== -1) {
      //   sql += ` order by local_transaction_no DESC`;
      //   sql += ` LIMIT 10`
      // }else{
      //   sql += ` order by local_transaction_no ASC`;
      // }

      if(filter.order_status_array.indexOf(7) !== -1) {
        sql += ` order by local_transaction_no DESC`;
        sql += ` LIMIT 15`
      }else{
        sql += ` order by local_transaction_no ASC`;
        if(filter.page_no > 0) {
          sql += ` LIMIT `+(filter.page_no*15)+`, 15`
        }else{
          sql += ` LIMIT 0, 15`
        }
      }
      // if(filter.page_no > 0) {
      //   sql += ` order by local_transaction_no DESC`;
      //   sql += ` LIMIT `+(filter.page_no*10)+`, 10`
      // }else{
      //   sql += ` order by local_transaction_no ASC`;
      //   sql += ` LIMIT 0, 10`
      // }
      // console.log("sql",sql)
      await this.dbInstance.executeSql(sql, []).then(async (data) => {
        
        let orders = [];
        let order_type: any = [];
        for (let i = 0; i < data.rows.length; i++) {

          let sql_main = `SELECT database_id as group_id,created_at FROM order_store_group_kds where order_id =`+ data.rows.item(i).database_id;
          await this.dbInstance.executeSql(sql_main, []).then(async (group_ids) => {

            for (let j = 0; j < group_ids.rows.length; j++) {
              
              let sql = `SELECT order_detail_id FROM order_store_kds where group_id =`+ group_ids.rows.item(j).group_id;
              await this.dbInstance.executeSql(sql, []).then(async (data_by_group_id) => {
                var details_ids = [];
                for (let jk = 0; jk < data_by_group_id.rows.length; jk++) {
                  
                  details_ids.push(data_by_group_id.rows.item(jk).order_detail_id)
                }
                if(details_ids.length > 0){
                  const processedData = {
                    ...data.rows.item(i),
                    group_id: group_ids.rows.item(j).group_id,
                    group_created_at: group_ids.rows.item(j).created_at,
                    order_details_database_ids : details_ids.toString()
                  };
                  orders.push(processedData);
                }
              });     
            }
              
          });

             
        }

      
        //await this.delay(1000);


        await orders.forEach(async element => {
                  
          let details = await this.getOrderDetailsByIdNew(element.id,element.order_details_database_ids);
         
          element.order_details = details;
          order_type = await this.getTenderTypeById(element.id);
          let tender_type_name_list = []
          await order_type.forEach(async type => {
            if (parseInt(type.order_price) > 0) {
              tender_type_name_list.push(type.order_type_name);
            }
          });
          element.tender_type_name = tender_type_name_list.toString()
          element.order_type = order_type;
          element.selectionColor = false;


          /////////////////
          let order_details = element.order_details;
          let new_order_details = [];
          //this.delay(1000)
          await order_details.forEach(async item => {
            item.kds_data = await this.getOrderKdsDetailsByIdNew(item.database_id,element.group_id)
            if(item.kds_data){
              item.pricelookup_qty = item.kds_data.pricelookup_qty
            }
            item.group_id = item.kds_data.group_id
            new_order_details.push(item);
          });
          element.order_details = new_order_details;
          
          /////////////////

        });
        //let rowNumbers = await this.getOrderCounts(filter);

        let sortedOrders = orders.sort((p1, p2) => (p1.group_id > p2.group_id) ? 1 : (p1.group_id < p2.group_id) ? -1 : 0);

        let datas = {
          orders: sortedOrders,
          totals: 0
        }
        resolve(datas);
      });
    });
  }

  getKdsOrdersfromWebSql(filter) {
    return new Promise(async resolve => {
      // let sql = `SELECT * FROM orders where order_status = `+ filter.order_status +` order by created_at DESC`;
      let sql = `SELECT * FROM orders `;
      var where = 'where 1=1';
      if (filter.order_status != null) {
        where += ' AND order_status = ' + filter.order_status;
      }
      
      if (filter.order_status_array != null) {
        where += ' AND order_status In (' + filter.order_status_array + ')';
      }
      if (filter.clerk_id != null && filter.clerk_id != undefined && filter.clerk_id != "") {
        where += ' AND created_by = ' + filter.clerk_id;
      }
      if (filter.transaction_no != null && filter.transaction_no != undefined && filter.transaction_no != "") {
        // where += ' AND database_id = ' + filter.transaction_no;
        where += ' AND local_transaction_no = ' + filter.transaction_no;
      }
      if (filter.tender_profile_id != null && filter.tender_profile_id != undefined) {
        where += ' AND tender_profile_id = ' + filter.tender_profile_id;
      }
      // debugger
      if (filter.filter_tender_profile_id != undefined && filter.filter_tender_profile_id.length != 0) {
        where += ' AND tender_profile_id  IN (' + filter.filter_tender_profile_id.toString() + ')'
      }
      if (filter.order_no != null && filter.order_no != undefined) {
        where += ' AND store_order_sequence_id LIKE "%' + filter.order_no + '"';
      }
      //if (filter.order_no != null && filter.order_no != undefined) {
      //  where += ' AND database_id = ' + filter.order_no;
      //}

      if (filter.mobile_no != null && filter.mobile_no != undefined) {
        where += ' AND customer_phone = ' + filter.mobile_no;
      }
      if (filter.name != null && filter.name != undefined) {
        where += ` AND customer_name LIKE "%` + filter.name + `%"`;
      }
      
      sql += where;
      //let startDate = await this.getStartDate()

      //let start_date = startDate['start_date'];
      //let newStartDate = Date.parse(startDate['start_date']);
      //  let newStartDate1 = Date.parse('Fri Jul 23 2021 01:05:09 GMT+0530 (India Standard Time)')
      
      // if(filter.order_status_array.indexOf(7) !== -1) {
      //   sql += ` order by local_transaction_no DESC`;
      //   sql += ` LIMIT 10`
      // }else{
      //   sql += ` order by local_transaction_no ASC`;
      // }

      if(filter.order_status_array.indexOf(7) !== -1) {
        sql += ` order by local_transaction_no DESC`;
        sql += ` LIMIT 15`
      }else{
        sql += ` order by local_transaction_no ASC`;
        if(filter.page_no > 0) {
          sql += ` LIMIT `+(filter.page_no*15)+`, 15`
        }else{
          sql += ` LIMIT 0, 15`
        }
      }
      // if(filter.page_no > 0) {
      //   sql += ` order by local_transaction_no DESC`;
      //   sql += ` LIMIT `+(filter.page_no*10)+`, 10`
      // }else{
      //   sql += ` order by local_transaction_no ASC`;
      //   sql += ` LIMIT 0, 10`
      // }
      // console.log("sql",sql)
      this.dbInstance.executeSql(sql, []).then(async (data) => {
        let orders = [];
        let order_type: any = [];
        for (let i = 0; i < data.rows.length; i++) {
          orders.push(data.rows.item(i));
        }

        await orders.forEach(async element => {
                  
          let details = await this.getOrderDetailsById(element.id);
         
          element.order_details = details;
          order_type = await this.getTenderTypeById(element.id);
          let tender_type_name_list = []
          await order_type.forEach(async type => {
            if (parseInt(type.order_price) > 0) {
              tender_type_name_list.push(type.order_type_name);
            }
          });
          element.tender_type_name = tender_type_name_list.toString()
          element.order_type = order_type;
          element.selectionColor = false;


          /////////////////
          let order_details = element.order_details;
          this.delay(1000)
          await order_details.forEach(async item => {

            item.kds_data = await this.getOrderKdsDetailsById(item.database_id)
          });
          
          /////////////////

        });
        //let rowNumbers = await this.getOrderCounts(filter);
        let datas = {
          orders: orders,
          totals: 0
        }
        resolve(datas);
      });
    });
  }
  getTotalNumberOfKdsOrdersfromWebSql(filter) {
    return new Promise(async resolve => {
      // let sql = `SELECT * FROM orders where order_status = `+ filter.order_status +` order by created_at DESC`;
      let sql = `SELECT * FROM orders `;
      var where = 'where 1=1';
      if (filter.order_status != null) {
        where += ' AND order_status = ' + filter.order_status;
      }
      
      if (filter.order_status_array != null) {
        where += ' AND order_status In (' + filter.order_status_array + ')';
      }
      if (filter.clerk_id != null && filter.clerk_id != undefined && filter.clerk_id != "") {
        where += ' AND created_by = ' + filter.clerk_id;
      }
      if (filter.transaction_no != null && filter.transaction_no != undefined && filter.transaction_no != "") {
        // where += ' AND database_id = ' + filter.transaction_no;
        where += ' AND local_transaction_no = ' + filter.transaction_no;
      }
      if (filter.tender_profile_id != null && filter.tender_profile_id != undefined) {
        where += ' AND tender_profile_id = ' + filter.tender_profile_id;
      }
      // debugger
      if (filter.filter_tender_profile_id != undefined && filter.filter_tender_profile_id.length != 0) {
        where += ' AND tender_profile_id  IN (' + filter.filter_tender_profile_id.toString() + ')'
      }
      if (filter.order_no != null && filter.order_no != undefined) {
        where += ' AND store_order_sequence_id LIKE "%' + filter.order_no + '"';
      }
      //if (filter.order_no != null && filter.order_no != undefined) {
      //  where += ' AND database_id = ' + filter.order_no;
      //}

      if (filter.mobile_no != null && filter.mobile_no != undefined) {
        where += ' AND customer_phone = ' + filter.mobile_no;
      }
      if (filter.name != null && filter.name != undefined) {
        where += ` AND customer_name LIKE "%` + filter.name + `%"`;
      }
      
      sql += where;
      
      //console.log("sql",sql)
      this.dbInstance.executeSql(sql, []).then(async (data) => {
        resolve(data.rows.length);
      });
    });
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getOrdersByOrderidfromWebSql(order_id) {
    return new Promise(async resolve => {
      // debugger;
      // let sql = `SELECT * FROM orders where order_status = `+ filter.order_status +` order by created_at DESC`;
      let sql = `SELECT * FROM orders`;
      var where = ' where 1=1';
      if (order_id != null) {
        where += ' AND id = ' + order_id;
      }
      sql += where;
      let startDate = await this.getStartDate()

      //let start_date = startDate['start_date'];
      let newStartDate = Date.parse(startDate['start_date']);
      //  let newStartDate1 = Date.parse('Fri Jul 23 2021 01:05:09 GMT+0530 (India Standard Time)')

      
      this.dbInstance.executeSql(sql, []).then(async (data) => {
        let orders = [];
        let order_type: any = [];
        for (let i = 0; i < data.rows.length; i++) {
          orders.push(data.rows.item(i));
        }

        orders.forEach(async element => {
          // let customer = await this.getCustomerByDatabaseId(element.customer_id);
          // element.order_customer = customer;

          let details = await this.getOrderDetailsById(element.id);



          for (let i = 0; i < details['length']; i++) {
            details[i].add_addons_detail = JSON.parse(details[i].add_addons_detail)
            details[i].remove_addons_detail = JSON.parse(details[i].remove_addons_detail)
            details[i].taxes = JSON.parse(details[i].taxes)
          }
          

          element.order_details = details;
          order_type = await this.getTenderTypeById(element.id);
          let tender_type_name_list = []
          order_type.forEach(async type => {
            if (parseInt(type.order_price) > 0) {
              tender_type_name_list.push(type.order_type_name);
            }
          });
          element.tender_type_name = tender_type_name_list.toString()
          element.order_type = order_type;
          element.selectionColor = false;
        });

        let datas = orders;
        resolve(orders[0]);
      });
    });
  }

 getOrdersByOrderDatabaseidfromWebSql(order_id) {
    return new Promise(async resolve => {
      //debugger;
      // let sql = `SELECT * FROM orders where order_status = `+ filter.order_status +` order by created_at DESC`;
      let sql = `SELECT * FROM orders`;
      var where = ' where 1=1';
      if (order_id != null) {
        if (isNaN(order_id)) {
          where += ' AND database_id = "' + order_id + '"';
        } else {
          where += ' AND database_id = ' + order_id;
        }

      }
      sql += where;
      let startDate = await this.getStartDate()

      //let start_date = startDate['start_date'];
      let newStartDate = Date.parse(startDate['start_date']);
      //  let newStartDate1 = Date.parse('Fri Jul 23 2021 01:05:09 GMT+0530 (India Standard Time)')

      
      this.dbInstance.executeSql(sql, []).then(async (data) => {
        let orders = [];
        let order_type: any = [];
        for (let i = 0; i < data.rows.length; i++) {
          orders.push(data.rows.item(i));
        }

        orders.forEach(async element => {
          // let customer = await this.getCustomerByDatabaseId(element.customer_id);
          // element.order_customer = customer;

          let details = await this.getOrderDetailsById(element.id);

          

          for (let i = 0; i < details['length']; i++) {
            details[i].add_addons_detail = JSON.parse(details[i].add_addons_detail)
            details[i].remove_addons_detail = JSON.parse(details[i].remove_addons_detail)
            details[i].taxes = JSON.parse(details[i].taxes)
          }

          element.order_details = details;
          order_type = await this.getTenderTypeById(element.id);
          let tender_type_name_list = []
          order_type.forEach(async type => {
            if (parseInt(type.order_price) > 0) {
              tender_type_name_list.push(type.order_type_name);
            }
          });
          element.tender_type_name = tender_type_name_list.toString()
          element.order_type = order_type;
          element.selectionColor = false;
        });

        let datas = orders;
        resolve(orders[0]);
      });
    });
  }
  
  getOrdersByTableidfromWebSql(table_id) {
    return new Promise(async resolve => {

      // let sql = `SELECT * FROM orders where order_status = `+ filter.order_status +` order by created_at DESC`;
      let sql = `SELECT * FROM orders`;
      var where = ' where 1=1';
      if (table_id != null) {
        where += ' AND table_id = ' + table_id;
      }
      sql += where + ' ORDER BY id DESC LIMIT 1';
      let startDate = await this.getStartDate()

      //let start_date = startDate['start_date'];
      let newStartDate = Date.parse(startDate['start_date']);
      //  let newStartDate1 = Date.parse('Fri Jul 23 2021 01:05:09 GMT+0530 (India Standard Time)')

      
      this.dbInstance.executeSql(sql, []).then(async (data) => {
        let orders = [];
        let order_type: any = [];
        for (let i = 0; i < data.rows.length; i++) {
          orders.push(data.rows.item(i));
        }

        orders.forEach(async element => {
          // let customer = await this.getCustomerByDatabaseId(element.customer_id);
          // element.order_customer = customer;

          // debugger;
          let details = await this.getOrderDetailsById(element.id);


          
          for (let i = 0; i < details['length']; i++) {
            details[i].add_addons_detail = JSON.parse(details[i].add_addons_detail)
            details[i].remove_addons_detail = JSON.parse(details[i].remove_addons_detail)
            details[i].taxes = JSON.parse(details[i].taxes)
          }
          
          element.order_details = details;
          order_type = await this.getTenderTypeById(element.id);
          let tender_type_name_list = []
          order_type.forEach(async type => {
            if (parseInt(type.order_price) > 0) {
              tender_type_name_list.push(type.order_type_name);
            }
          });
          element.tender_type_name = tender_type_name_list.toString()
          element.order_type = order_type;
          element.selectionColor = false;
        });

        let datas = orders;
        resolve(orders[0]);
      });
    });
  }

  getKdsPrintOrders(id) {
    return new Promise(async resolve => {
      // let sql = `SELECT * FROM orders where order_status = `+ filter.order_status +` order by created_at DESC`;
      let sql = `SELECT * FROM orders `;
      var where = 'where 1=1';

     // where += ' AND kds_print_flag = 0';
      where += ' AND database_id = "'+id+'"';

      sql += where;
      sql += ` LIMIT 1`

      this.dbInstance.executeSql(sql, []).then(async (data) => {
        if(data.rows.length > 0){
  
          resolve(data.rows.item(0).kds_order_obj);
        }else{
          resolve("");
        }
        
      });
    });
  }

  getStartDate() {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * from  start_end_tbl where start_date != '' and end_date IS NULL`, []).then(async (data) => {
        resolve(data.rows.item(0))
      })
    })
  }

  getCustomerById(id) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM customers where id = ` + id, []).then(async (data) => {
        resolve(data.rows.item(0));
      });
    });
  }

  getCustomerByDatabaseId(id) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM customers where database_id = '` + id + `'`, []).then(async (data) => {
        let customers = [];
        for (let i = 0; i < data.rows.length; i++) {
          customers.push(data.rows.item(i));
        }
        resolve(customers);
      });
    });
  }

  getOrderDetailsById(id) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM order_store_detail where order_id = ` + id, []).then(async (data) => {
         
        let details = [];
        for (let i = 0; i < data.rows.length; i++) {
          details.push(data.rows.item(i));
        }
        resolve(details)
      });
    });
  }

  getOrderDetailsByIdNew(id,order_details_database_ids) {
    return new Promise(resolve => {
      
      this.dbInstance.executeSql(`SELECT * FROM order_store_detail where order_id = ` + id + ` AND database_id IN (`+ order_details_database_ids +`)`, []).then(async (data) => {
         
        let details = [];
        for (let i = 0; i < data.rows.length; i++) {
          details.push(data.rows.item(i));
        }
        resolve(details)
      });
    });
  }

  

  getOrderKdsDetailsById(id) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM order_store_kds where order_detail_id = ` + id, []).then(async (data) => {
        // resolve(data.rows.item(0));
        if(data.rows.length > 0){
          resolve(data.rows.item(0))  
        }
        
      });
    });
  }

  getOrderKdsDetailsByIdNew(id,group_id) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM order_store_kds where order_detail_id = ` + id +` AND group_id =`+group_id, []).then(async (data) => {
        // resolve(data.rows.item(0));
        if(data.rows.length > 0){
          resolve(data.rows.item(0))  
        }
        
      });
    });
  }

  getTenderTypeById(id) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM orders_store_order_type where order_id = ` + id, []).then(async (data) => {
        // resolve(data.rows.item(0));
        let details = [];
        for (let i = 0; i < data.rows.length; i++) {
          details.push(data.rows.item(i));
        }
        resolve(details)
      });
    });
  }

  getAdjustedOrderById(database_id) {
    return new Promise(resolve => {
      this.dbInstance.executeSql(`SELECT * FROM orders where adujusted_order_id = '` + database_id + `'`, []).then(async (data) => {
        // resolve(data.rows.item(0));
        let orders = [];
        for (let i = 0; i < data.rows.length; i++) {
          orders.push(data.rows.item(i));
        }
        resolve(orders)
      });
    });
  }

  getOrderCounts(filter) {
    return new Promise(async resolve => {
      // let startDate = await this.getStartDate();
      // let newStartDate = Date.parse(startDate['start_date']);

      // let newStartDate = Date.parse('Fri Jul 23 2021 01:05:09 GMT+0530 (India Standard Time)')

      let sql = `SELECT * FROM orders `;
      var where = 'where 1=1';
      // if (filter.order_status != null) {
      //   where += ' AND order_status = ' + filter.order_status;
      // }
      if (filter.clerk_id != null && filter.clerk_id != undefined && filter.clerk_id != "") {
        where += ' AND created_by = ' + filter.clerk_id;
      }
      if (filter.transaction_no != null && filter.transaction_no != undefined && filter.transaction_no != "") {
        // where += ' AND database_id = ' + filter.transaction_no;
        where += ' AND local_transaction_no = ' + filter.transaction_no;
      }
      if (filter.tender_profile_id != null && filter.tender_profile_id != undefined) {
        where += ' AND tender_profile_id = ' + filter.tender_profile_id;
      }
      if (filter.filter_tender_profile_id != undefined && filter.filter_tender_profile_id.length != 0) {
        where += ' AND tender_profile_id  IN (' + filter.filter_tender_profile_id.toString() + ')'
      }
      if (filter.order_no != null && filter.order_no != undefined) {
        // where += ' AND database_id = ' + filter.order_no;
        where += ' AND store_order_sequence_id LIKE "%' + filter.order_no + '"';
      }
      if (filter.mobile_no != null && filter.mobile_no != undefined) {
        where += ' AND customer_phone = ' + filter.mobile_no;
      }
      if (filter.name != null && filter.name != undefined) {
        where += ` AND customer_name LIKE '%` + filter.name + `%'`;
      }
      sql += where;
      let startDate = await this.getStartDate()

      // let newStartDate = Date.parse(startDate['start_date']);
      // sql += ` AND created_at >= ` + newStartDate;

      let newStartDate = Date.parse(startDate['start_date']);
      //  let newStartDate1 = Date.parse('Fri Jul 23 2021 01:05:09 GMT+0530 (India Standard Time)')
      sql += ` AND created_at >= ` + newStartDate + ` order by local_transaction_no DESC`;
      

      this.dbInstance.executeSql(sql, []).then(async (data) => {

        // this.dbInstance.executeSql(`SELECT * FROM orders where created_at >= ` + newStartDate, []).then(async (data) => {
        let orders = [];
        for (let i = 0; i < data.rows.length; i++) {
          orders.push(data.rows.item(i));
        }

        let saved = 0, comp = 0, cancel = 0, pending = 0, accepted = 0, processing = 0, delivery = 0;;
        orders.forEach(async element => {
          if (element.order_status == 0) {
            saved++;
          } else if (element.order_status == 1) {
            comp++;
          } else if (element.order_status == 2) {
            cancel++;
          } else if (element.order_status == 3) {
            pending++;
          } else if (element.order_status == 4) {
            accepted++;
          } else if (element.order_status == 6) {
            processing++;
          } else if (element.order_status == 5) {
            delivery++;
          }
        });
        let rs = {
          saved: saved,
          comp: comp,
          cancel: cancel,
          pending: pending,
          accepted: accepted,
          processing: processing,
          delivery: delivery
        }
        resolve(rs)
      });
    });
  }


  // Sync Data Comman Function for push notifiction only

  async syncData() {
    var mobile_orders = await this.syncfromMobileOrders()
  }

  addslashes(data) {
    return data.replace(/'/g, "\''")
  }

  storeDetail() {
    var data = new FormData();
    data.append('store_id', localStorage.getItem('store_id'));
    this._posService.getstore(data)
      .subscribe(
        res => {
          if (res.status == 1) {
            this.storeDetailData = res.data;
            localStorage.setItem('store_detail', JSON.stringify(this.storeDetailData));
          }
        },
        err => {
        })
  }

}
