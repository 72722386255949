import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
// import { DBSchema, IDBPDatabase, openDB } from 'idb';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndexDbService {

  // private db: IDBPDatabase<Mydb>;

  tenderProfiles = new BehaviorSubject([]);
  private _dbPromise;

  constructor(private http: HttpClient, private plt: Platform) {
    // this.plt.ready().then(() => {
    //   this.connectToDb();
    // });

  }

  // async connectToDb(){
  //   console.log("Indexdb called")
  //   this.db = await openDB<Mydb>('my-db', 1, {
  //     upgrade(db){
  //       console.log("db", db)
  //       db.createObjectStore('POS-tenderprofiles');
  //     }
  //   });
  // }

  // storeTenderProfile(data: OrderTypes[]) {
  //   return this.db.put('POS-tenderprofiles', JSON.stringify(data), 'tenderprofiles')
  // }

  // getTenderProfile() {
  //   return new Promise(resolve => {
  //     this.http.post(appConfig.apiUrl + '/tenderlist', '').subscribe(data => {
  //       let ordersTypes: OrderTypes[] = [];
  //       ordersTypes = data['data'];
  //       // this.storeTenderProfile(ordersTypes)
  //       resolve(data);
  //     }, (err) => {
  //       resolve(err);
  //     });
  //   });
  // }

 

  // getTenderProfiles() {
  //   return this.db.getAll('POS-tenderprofiles', 'tenderprofiles')

  // }

  //   getTenderProfiles() {
  //   return this._dbPromise.then((db: any) => {
  //     const tx = db.transaction('POS-tenderprofiles', 'readonly');
  //     const store = tx.objectStore('POS-tenderprofiles');
  //     return store.getAll();
  //   });
  // }

  // setAllDataToIndexDb() {
  //   this.getTenderProfile();
  // }

  deleteUser(key: string) {
    // return this.db.delete('POS-Store', key)
  }
}

// interface Mydb extends DBSchema {
//   'POS-tenderprofiles': {
//     key: string,
//     value: string
//   }
// }
