import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CMSService } from './services/cms.service';
import { AuthenticationService } from './authservices';
import { JwtInterceptor } from './helpers';
import { AuthGuard, TerminalGuard } from './guards';
import { NgxPrintModule } from 'ngx-print';
import { NgxSanitizerModule } from 'ngx-sanitizer';
import { NgxPaginationModule } from 'ngx-pagination';
import { Printer } from '@ionic-native/printer/ngx';
import { AsyncPipe, DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
// import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { WebSqlService } from './services/web-sql.service';
import { DecodeHtmlEntities, DecodeHtmlEntitiesModule } from 'decode-html-entities';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { ModalModule } from 'ngx-bootstrap/modal';
// import { ConnectionServiceModule } from 'ng-connection-service';
// import { AngularFireModule } from '@angular/fire';
// import { MessagingService } from './services/messaging.service';
import { ConnectionServiceModule, ConnectionServiceOptions, ConnectionServiceOptionsToken } from 'ngx-connection-service';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { AgmCoreModule } from '@agm/core';
import { FormBuilder } from '@angular/forms';
import { TranslateModule, TranslateLoader, TranslateService, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QRCodeModule } from 'angularx-qrcode';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { IpService } from './services/ip.service';
// import { ForegroundService } from '@awesome-cordova-plugins/foreground-service/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
// import { PosDashboardPageModule } from './pages/pos-dashboard/pos-dashboard.module';
import { PosDashboardPage } from './pages/pos-dashboard/pos-dashboard.page';
import * as Socket from '@vendus/sockets-for-cordova';

import { appConfig } from './app.config';
//const config: SocketIoConfig = { url: 'https://socket.limerr.co:443', options: {} };

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


// const dbConfig: DBConfig = {
//   name: 'Santusthi',
//   version: 5,
//   objectStoresMeta: [
//     {
//       store: 'tenderprofiles',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },
//     {
//       store: 'customerList',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },
//     {
//       store: 'reasons',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//         { name: 'created_time', keypath: 'created_time', options: { unique: false } },
//         { name: 'id', keypath: 'id', options: { unique: false } },
//         { name: 'title', keypath: 'title', options: { unique: false } },
//         { name: 'type', keypath: 'type', options: { unique: false } }
//       ]
//     },
//     {
//       store: 'pluCategoryList',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },
//     {
//       store: 'plumenuitem',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },
//     {
//       store: 'storedetail',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },
//     {
//       store: 'startofday',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },
//     {
//       store: 'paidout',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },
//     {
//       store: 'cashout',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },
//     {
//       store: 'ejectdrawer',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },
//     {
//       store: 'tendertypelist',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },
//     {
//       store: 'orders',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },
//     {
//       store: 'mycart',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//       ]
//     },

//   ]
// };

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    //SocketIoModule.forRoot(config),
    BrowserAnimationsModule,
    // PosDashboardPageModule,
    IonicModule.forRoot({ animated: false }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }

    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyA7nY5PCE1fwg0KxUoS4h5wWEBfMJuS4AM',
      apiKey: 'AIzaSyCBvLOSy-pquUBu-q6hUZvLQUBhNddmDZs',
      libraries: ["places"]
    }),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxPrintModule,
    NgxSanitizerModule,
    NgxPaginationModule,
    QRCodeModule,
    ConnectionServiceModule,
    ModalModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: "registerImmediately" }),
    // ServiceWorkerModule.register('service-worker.js', {
    //   enabled: environment.production
    // }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    // AngularFireMessagingModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // NgxIndexedDBModule.forRoot(dbConfig),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // })
    DecodeHtmlEntitiesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // })
  ],
  providers: [
    CMSService,
    AuthenticationService,
    AuthGuard,
    TerminalGuard,
    DatePipe,
    Printer,
    InAppBrowser,
    // IndexDbService,
    SQLite,
    SQLitePorter,
    WebSqlService,
    IpService,
    DecodeHtmlEntities,
    // MessagingService, 
    AsyncPipe,
    Keyboard,
    Geolocation,
    FormBuilder,
    TranslateService,
    TranslateStore,
    FCM,
    StatusBar,
    SplashScreen,
    Network,
    PosDashboardPage,
    Socket,
    // ForegroundService,
    {
      provide: ConnectionServiceOptionsToken,
      useValue: <ConnectionServiceOptions>{
        enableHeartbeat: false,
        heartbeatUrl: '/assets/ping.json',
        requestMethod: 'get',
        heartbeatInterval: 3000
      }
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  truncateText(text, length) {
    if (typeof text != 'string') {
      text = text.toString();
    }
    if (text.length <= length) {
      return text;
    }
    return text.substr(0, length - 1) + '-'
  }

  stripHtml(html) {
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

}
