// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAXu7USJLPe_Z3hCyGWtAzIthQtycbZZtk",
    authDomain: "restaurants2-fe2df.firebaseapp.com",
    databaseURL: "https://restaurants2-fe2df.firebaseio.com",
    projectId: "restaurants2-fe2df",
    storageBucket: "restaurants2-fe2df.appspot.com",
    messagingSenderId: "380062583748",
    appId: "1:380062583748:web:39fe3ec0fdadd9925fb291",
    measurementId: "G-XKLHB2X8BE"
  }
};


// apiKey: "AIzaSyAXu7USJLPe_Z3hCyGWtAzIthQtycbZZtk",
// authDomain: "restaurants2-fe2df.firebaseapp.com",
// databaseURL: "https://restaurants2-fe2df.firebaseio.com",
// projectId: "restaurants2-fe2df",
// storageBucket: "restaurants2-fe2df.appspot.com",
// messagingSenderId: "380062583748",
// appId: "1:380062583748:web:39fe3ec0fdadd9925fb291",
// measurementId: "G-XKLHB2X8BE"
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
