import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/map';
import { appConfig } from '../app.config';
// import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators'
import { DataServiceService } from '../services/data-service.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    public userList = []
    constructor(private http: HttpClient,
        private _router: Router,) {
        // this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        // this.currentUser = this.currentUserSubject.asObservable();
    }

    storeLogin(data) {
        return this.http.post<any>(appConfig.apiUrl + '/storeterminallogin', data)
            .pipe(map(res => {
                return res;
            }));
    }

    kitchenLogin(data) {
        return this.http.post<any>(appConfig.apiUrl + '/kitchen_login', data)
            .pipe(map(res => {
                return res;
            }));
    }

    login(data) {

        return this.http.post<any>(appConfig.apiUrl + '/login', data)
            // return this.http.post<any>("https://foodmonkey.app/mobileappv2/api/POSLogin/?api_key=FOOOOD", data)
            .pipe(map(res => {
                var user = res.data;
                // login successful if there's a jwt token in the response
                if (user && user.user_token) {

                    // this.userList.push(user)
                    // let skills = this.developer['skills'].split(',');
                    //   skills = skills.map(skill => skill.trim());
                    //   this.db.addLogindata(user)
                    //   .then(_ => {
                    //     // this.developer = {};
                    //     alert("data Inserted in sqlite")
                    //   });
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    // localStorage.setItem('checkinUserList', JSON.stringify(this.userList));
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('token', JSON.stringify(user.user_token));
                    localStorage.setItem("defaultUser", user.username);

                    // localStorage.setItem('customer_background_check', JSON.stringify(0));
                    // localStorage.setItem('tender_type_background_check', JSON.stringify(0));
                    // localStorage.setItem('all_reason_background_check', JSON.stringify(0));
                }
                return res;
            }));
    }

    public get currentUserValue() {
        var tok = localStorage.getItem('token')
        if (tok) {
            return true
        } else {
            return false
        }

    }

    logout() {
        // localStorage.removeItem('checkinUserList');
        // localStorage.removeItem('currentUser');
        // localStorage.removeItem('token');
        // localStorage.removeItem('permissions');
        // localStorage.removeItem('isStartofDay');
        // localStorage.removeItem('isOneMinCashIn');
        // localStorage.removeItem('terminal_id');
        // localStorage.removeItem('expressMode');
        // localStorage.removeItem('isTerminalLogin');
        // localStorage.setItem('isTerminalLogin', JSON.stringify(false))
        localStorage.clear()
        this._router.navigate(['login'])
    }

    // public isAuthenticated(): boolean {
    //     const token = localStorage.getItem('token');
    //     const helper = new JwtHelperService();
    //     return !helper.isTokenExpired(token);
    // }

    // public isLogout (){
    //     localStorage.removeItem('token');
    //     localStorage.clear();
    // }

    // logout() {
    //     localStorage.removeItem('token');
    // }
}
