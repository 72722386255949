import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { appConfig } from './app.config';
import { AuthGuard } from './guards';
import { TerminalGuard } from './guards/terminal.guard';

const routes: Routes = [
  
  {
    path: '',
    loadChildren: () => import('./pages/kds/kds.module').then(m => m.KdsPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/kds/kds.module').then( m => m.KdsPageModule),
    canActivate : [TerminalGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./system/kds-login/kds-login.module').then(m => m.KdsLoginPageModule),
    // canActivate: [TerminalGuard]
  },
  {
    path: 'pos',
    loadChildren: () => import('./pages/pos/pos.module').then(m => m.PosPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'pos-dashboard',
  //   redirectTo: '/pos-dashboard/saved',

  // },
  
  {
    path: 'pos-dashboard',
    loadChildren: () => import('./pages/pos-dashboard/pos-dashboard.module').then(m => m.PosDashboardPageModule),
    canActivate: [TerminalGuard, AuthGuard]
  },
  {
    path: 'transcation',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'manager-desk',
    loadChildren: () => import('./pages/manager-desk/manager-desk.module').then(m => m.ManagerDeskPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'start-of-day',
    loadChildren: () => import('./pages/start-of-day/start-of-day.module').then(m => m.StartOfDayPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'end-of-day',
    loadChildren: () => import('./pages/end-of-day/end-of-day.module').then(m => m.EndOfDayPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'one-minute-cash-in',
    loadChildren: () => import('./pages/one-minute-cash-in/one-minute-cash-in.module').then(m => m.OneMinuteCashInPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'paidout',
    loadChildren: () => import('./pages/paidout/paidout.module').then(m => m.PaidoutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cashout',
    loadChildren: () => import('./pages/cashout/cashout.module').then(m => m.CashoutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'under-development',
    loadChildren: () => import('./pages/under-development/under-development.module').then(m => m.UnderDevelopmentPageModule),
  },
  {
    path: 'customer-lookup',
    loadChildren: () => import('./pages/customer-lookup/customer-lookup.module').then(m => m.CustomerLookupPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'attendance',
    loadChildren: () => import('./pages/attendance/attendance.module').then( m => m.AttendancePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'delivery',
    loadChildren: () => import('./pages/delivery/delivery.module').then( m => m.DeliveryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tablebooking',
    loadChildren: () => import('./pages/tablebooking/tablebooking.module').then( m => m.TablebookingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./system/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./pages/setting/setting.module').then( m => m.SettingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
