export const appConfig = {
    // stagingadminpos
    //apiUrl: 'https://stagingadminpos.limerr.co/api/v1',
    //mainUrl:'https://stagingadminpos.limerr.co',
    
    // productionadminpos
    apiUrl: "https://adminpos.limerr.co/api/v1",
    mainUrl:'https://adminpos.limerr.co',

    //AWS productionadminpos
    // apiUrl: "https://aadminpos.limerr.co/api/v1",
    // mainUrl:'https://aadminpos.limerr.co',


    // apiUrl: "https://kiosk.limerr.co/adminpos/api/v1",
    // mainUrl:'https://kiosk.limerr.co/adminpos',

    buildCreateFor: 'web', // 'web' , 'android' , 'ios'

    jsonURL: 'http://localhost:3000',
    currency_symbol: localStorage.getItem('currency_symbol'),
    buildFor: 1   // 0 = POS , 1 = KDS
};
