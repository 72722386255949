export const browserDBInstance = (db) => {

  // return new Promise(function (resolve, reject) {
  //   this.db.transaction(
  //     function (tx) {
  //       tx.executeSql(sqlToExecute, bracketValues, success, error);
  //       function success(tx, rs) {
  //         resolve(rs);
  //       }
  //       function error(tx, error) {
  //         console.log('execSqlCustom error ' + error.message);
  //         reject(error);
  //       }
  //     }
  //   )
  // }.bind(this)
  // );

  return {

    executeSql: (sql) => {
      return new Promise((resolve, reject) => {
        db.transaction((tx) => {
          tx.executeSql(sql, [], success, error);
          function success(tx, rs) {
            resolve(rs);
          }
          function error(tx, error) {
            console.log('execSqlCustom error ' + error.message);
            console.log('execSqlCustom sql ' + sql);
            reject(error);
          }
        });
      })
    },
    // executeSql: (sql) => {
    //   // console.log(sql)
    //   return new Promise((resolve, reject) => {
    //     db.transaction((tx) => {
    //       tx.executeSql(sql, [], (tx, rs) => {
    //         resolve(rs)
    //       });
    //     });
    //   })
    // },
    sqlBatch: (arr) => {
      return new Promise((r, rr) => {
        let batch = [];
        db.transaction((tx) => {
          for (let i = 0; i < arr.length; i++) {
            batch.push(new Promise((resolve, reject) => {
              tx.executeSql(arr[i], [], () => { resolve(true) })
            }))
            Promise.all(batch).then(() => r(true));
          }
        });
      })
    }
  }
}

function handleTxError(tx, error) {
  // Util.log(JSON.stringify(error));
  console.log('jr tx error', error);
  return error; // This is required for rollback
}
