import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';
import { appConfig } from '../app.config';
import { map } from 'rxjs/operators'

@Injectable()
export class IpService {
  constructor(private http: HttpClient) { }


  getIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }


}
