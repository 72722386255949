import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PassingDataService {

  private content = new BehaviorSubject<object>({});
  public share = this.content.asObservable()

  private check = new BehaviorSubject<string>("");
  public checkShare = this.check.asObservable()

  private tableOrder = new BehaviorSubject<string>("");
  public TableOrder = this.tableOrder.asObservable()

  private storetime = new BehaviorSubject<boolean>(false);
  public shareStoreTime = this.storetime.asObservable()

  private lastsynctime = new BehaviorSubject<boolean>(false);
  public lastSyncTime = this.lastsynctime.asObservable()

  private keyboardStatus = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('virtual_keyboard')));
  public keyboardShare = this.keyboardStatus.asObservable();

  private newOrder = new BehaviorSubject<boolean>(false);
  public checkNewOrder = this.newOrder.asObservable();


  private newOrderReciveKds = new BehaviorSubject<boolean>(false);
  public checkNewOrderReciveKds = this.newOrderReciveKds.asObservable();

  constructor() { }

  newOrderStatus(data){
    this.newOrder.next(data)
  }

  getnewOrderReciveKds(data){
    this.newOrderReciveKds.next(data)
  }

  recallCartData(data) {
    this.content.next(data)
  }

  internetCheck(data) {
    this.check.next(data)
  }

  storeOpeningTimeUpdate(data) {
    this.storetime.next(data)
  }

  keyboardCheck(data) {
    this.keyboardStatus.next(data)
  }

  tableNewOrder(data){
    this.tableOrder.next(data)
  }
}
