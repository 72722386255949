import { DebugElement, Injectable } from '@angular/core';
import { appConfig } from '../app.config';
import { catchError, elementAt, map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http';
// import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
  modifiedCartAry = []
  // private database: SQLiteObject;
  constructor(private plt: Platform, public http: HttpClient, private datePipe: DatePipe) {
    // this.plt.ready().then(() =>{
    //   this.createDB();
    // })
  }



  getProductData() {
    return this.http.get<any>("assets/data/data.json")
      .pipe(map(res => {
        return res[0];
      }));
  }

  getOrderData() {
    return this.http.get<any>("assets/data/data.json")
      .pipe(map(res => {
        return res[0];
      }));
  }

  qtyminus(product, list) {
    list.forEach(item => {
      if (item.pricelookup_id === product.pricelookup_id && item.variation_detail[0].variation_id === product.variation_detail[0].variation_id) {
        if (item.pricelookup_qty > 0) {
          item.pricelookup_qty--;
          if (item.price_override_item_status == 1) {
            item.cal_price = (parseFloat(item.price_override_item_price)).toFixed(2);
          } else {
            item.cal_price = (parseFloat(item.price) * item.pricelookup_qty).toFixed(2)
          }
          // item.cal_price = parseFloat(item.price) * item.pricelookup_qty
        }
        if (item.pricelookup_qty == 0) {
          list.splice(list.findIndex(a => a.pricelookup_id === product.pricelookup_id), 1)
        }
      }
    });
    return list;
  }

  qtyplus(product, list) {
    // let list = JSON.parse(localStorage.getItem('rawCartItems'));
    //alert('list.length '+ list.length)
    list.forEach(item => {
      // alert('if item?.addOnItemAry?.length ' + item?.addOnItemAry?.length)
      //  alert('if item?.removeItemAry?.length ' + item?.removeItemAry?.length)
      // if (item.variation_detail) {
      if ((item?.addOnItemAry?.length > 0 || item?.removeItemAry?.length > 0)) {
        //
        // && item.variation_detail[0].variation_id === product.variation_detail[0].variation_id
        if (parseInt(item.pricelookup_id) === product.database_id && item.is_taken_for_new_item != 1) {
          //alert('if item.is_taken_for_new_item '+ item.is_taken_for_new_item)
          if (item.pricelookup_qty >= 0) {
            item.pricelookup_qty++;
            // item.cal_price = parseFloat(item.size.price) * item.qty
            item.cal_price = (parseFloat(item.price) * item.pricelookup_qty).toFixed(2);
          }
        }
      } else {
        //  alert('else item?.addOnItemAry?.length ' + item?.addOnItemAry?.length)
        //  alert('else item?.removeItemAry?.length ' + item?.removeItemAry?.length)
        if (parseInt(item.pricelookup_id) === product.database_id && (item?.addOnItemAry?.length === undefined || item?.removeItemAry?.length === undefined || item?.addOnItemAry?.length === 0 || item?.removeItemAry?.length === 0) && item.is_taken_for_new_item != 1) {

          // alert('else item.is_taken_for_new_item ' + item.is_taken_for_new_item)
          //alert('else item?.addOnItemAry?.length ' + item?.addOnItemAry?.length)
          // alert('else ' + item?.addOnItemAry?.length)
          if (item.pricelookup_qty >= 0) {
            item.pricelookup_qty++;
            if (item.price_override_item_status == 1) {
              item.cal_price = (parseFloat(item.price_override_item_price)).toFixed(2);
            } else {
              item.cal_price = (parseFloat(item.price) * item.pricelookup_qty).toFixed(2)
            }
          }
        }
      }
    });

    return list;

  }

  finalCalculation(list, config) {

    if (list) {
      let sub_total = 0;
      let tax_amt = 0;
      let grand_total = 0;
      let qty = 0;
      let price_override_status = 0;
      let taxList = [];
      if (list.length == 0) {
        config.discount_data.id = 0;
        config.discount_data.discount_rate = 0;
        config.discount_data.discount_type = 0;
        config.isDiscountApply = false;
      }
      //debugger;
      list.forEach(item => {
        // debugger;
        // Tender type wise price check
        let valid = this.IsJsonString(item.tender_profiles);
        let selectedTender = JSON.parse(localStorage.getItem('selectedTender'));
        let tender_profiles = [];
        if (valid) {
          tender_profiles = JSON.parse(item.tender_profiles);
        } else {
          tender_profiles = item.tender_profiles;
        }

        if (tender_profiles?.length > 0) {

          //debugger;
          let findTender = tender_profiles.find(x => x.name === selectedTender.name);
          if (item.add_group_id == 0 && item.remove_group_id == 0) {

            // For skip variation product price change
            if (findTender) {

              item.price = parseFloat(findTender.pivot.price).toFixed(2);
              item.pricelookup_item_price = item.price;
              // item.cal_price = item.price;
              let into = item.price * item.pricelookup_qty;
              item.cal_price = into.toFixed(2);
            } else {
              // if(item.tax_include == 1){
              //   item.price = parseFloat(item.standard_price).toFixed(2);
              //   // item.tax_cal_price = parseFloat(item.tax_include_price).toFixed(2);
              //   item.pricelookup_item_price = parseFloat(item.tax_include_price).toFixed(2);
              // }else{
              //   item.price = parseFloat(item.standard_price).toFixed(2);
              //   item.pricelookup_item_price = parseFloat(item.standard_price).toFixed(2);
              // }
              // item.price = parseFloat(item.standard_price).toFixed(2);
              // item.pricelookup_item_price = parseFloat(item.standard_price).toFixed(2);
              // item.cal_price = item.price;

              item.price = parseFloat(item.standard_price).toFixed(2);
              item.pricelookup_item_price = parseFloat(item.standard_price).toFixed(2);
              let into = item.price * item.pricelookup_qty;
              item.cal_price = into.toFixed(2);
            }
          } else {

            if (item.is_substitute == 1) { // 1--> subtitute
              item.price = parseFloat(item.standard_price).toFixed(2);
              item.pricelookup_item_price = parseFloat(item.standard_price).toFixed(2);
              let into = parseFloat(item.price) * item.pricelookup_qty;
              // let into = (parseFloat(item.price) + parseFloat(item.addOnprice)) * item.pricelookup_qty;
              item.cal_price = into.toFixed(2);
            } else { // 0 --> continue
              //Check Add Addons price
              if (item.addOnprice == 0 || item.addOnprice == undefined) {
                let cal = 0;
                if (item.addOnItemAry?.length > 0) {
                  item.addOnItemAry.forEach(element => {
                    cal += parseFloat(element.price);
                  });
                } else {
                  cal = 0;
                }
                item.addOnprice = cal;
              }
              //END Check Add Addons price

              item.price = parseFloat(item.standard_price).toFixed(2);
              //alert(item.price)
              item.pricelookup_item_price = parseFloat(item.standard_price).toFixed(2);
              let into = (parseFloat(item.price) + parseFloat(item.addOnprice)) * item.pricelookup_qty;
              item.cal_price = into.toFixed(2);
            }

            if (findTender) {
              if (item.is_substitute == 1) { // 1--> subtitute
                item.price = parseFloat(findTender.pivot.price).toFixed(2);
                item.pricelookup_item_price = item.price;
                let into = parseFloat(item.price) * item.pricelookup_qty;
                // let into = (parseFloat(item.price) + parseFloat(item.addOnprice)) * item.pricelookup_qty;
                item.cal_price = into.toFixed(2);
              } else {
                item.price = parseFloat(findTender.pivot.price).toFixed(2);
                item.pricelookup_item_price = item.price;
                //let into = item.price * item.pricelookup_qty;
                let into = (parseFloat(item.price) + parseFloat(item.addOnprice)) * item.pricelookup_qty;
                item.cal_price = into.toFixed(2);
              }
            }

          }

        } else {
          // item.price = parseFloat(item.standard_price).toFixed(2);
          //item.price = parseFloat(item.cal_price).toFixed(2);
          item.pricelookup_item_price = item.price;
          //let into = item.price * item.pricelookup_qty;
          //item.cal_price = into.toFixed(2);

        }

        if (item.price_override_item_status == 1) {
          price_override_status = 1;
          if (item?.isSpecialOffer == true) {
            sub_total += parseFloat(item.price_override_item_price) * parseInt(item.pricelookup_qty);
            let cal_price = parseFloat(item.price_override_item_price) * parseInt(item.pricelookup_qty);
            item.cal_price = cal_price.toFixed(2);
          } else {
            let cal_price = parseFloat(item.price_override_item_price);
            item.cal_price = cal_price.toFixed(2);
            sub_total += parseFloat(item.price_override_item_price);
          }
          qty += parseInt(item.pricelookup_qty);
        } else {
          // if (parseInt(item.add_variation) === 1) {
          //   if (item.add_group_id === 1) {
          //   sub_total += parseFloat(item.price) * parseInt(item.pricelookup_qty);
          //   qty += parseInt(item.pricelookup_qty);
          // } else {
          //   sub_total += parseFloat(item.price) * parseInt(item.pricelookup_qty);
          //   qty += parseInt(item.pricelookup_qty);
          // }

          if (item.add_group_id == 0 && item.remove_group_id == 0) {
            // if (item.add_group_id === 1) {
            sub_total += parseFloat(item.price) * parseInt(item.pricelookup_qty);
            qty += parseInt(item.pricelookup_qty);
          } else {
            // sub_total += parseFloat(item.cal_price) * parseInt(item.pricelookup_qty);

            sub_total += parseFloat(item.cal_price)
            qty += parseInt(item.pricelookup_qty);
          }
          // }
          // sub_total += parseFloat(item.price) * parseInt(item.pricelookup_qty);
          // qty += parseInt(item.pricelookup_qty);
        }

        if (item?.store_tax?.length > 0) {
          let stTax = JSON.parse(item.store_tax)
          let totaltax = 0;
          stTax.forEach(element => {
            totaltax = Number(totaltax) + Number(element.tax_value)
          })

          let price_override_item_price_without_tax = (item.price_override_item_price / (totaltax+100)) * 100;
          let newstTax = [];
		      let findTender = tender_profiles.find(x => x.name === selectedTender.name);
          stTax.forEach(element => {
            //debugger;



            let cal = 0;
            if (parseInt(element.tax_type) == 1) {
              
              // Working
              // if (parseInt(item.addon_variation_combine) != 1) {
              if (parseInt(item.add_group_id) == 0 || parseInt(item.remove_group_id) == 0) {

                let per = 0;
                let findTender = tender_profiles.find(x => x.name === selectedTender.name);
                if (findTender) {

                  if(item.price_override_item_status == 1){
                    if (item?.isSpecialOffer == true) {
                      let taxPrice = Number(price_override_item_price_without_tax) * item.pricelookup_qty;;
                      per = (taxPrice * parseFloat(element.tax_value)) / 100;
                    } else {
                      let taxPrice = Number(price_override_item_price_without_tax);
                      per = (taxPrice * parseFloat(element.tax_value)) / 100;
                    }
                  }else{
                    let taxPrice = Number(findTender.pivot.tax_include_price) * item.pricelookup_qty;
                    per = (taxPrice * parseFloat(element.tax_value)) / 100;
                  }


                  //   if (item.price_override_item_status == 0) {
                  //     // not override tax calculation
                  //     let taxPrice = Number(findTender.pivot.tax_include_price) * item.pricelookup_qty;
                  //     per = (taxPrice * parseFloat(element.tax_value)) / 100;
                  //   }
                  //   else {
                  //     //after override tax calclation
                  //     let taxPrice = Number(item.price_override_item_price);
                  //     per = (taxPrice * parseFloat(element.tax_value)) / 105;
                  // }
                } else {

                  if (item.price_override_item_status == 0) {
                    // debugger
                    // if(config.isDiscountApply){
                    //   var disVal = Number(item.tax_include_price) * item.pricelookup_qty;

                    // }else{

                    // }
                    // not override tax calculation
                    // let taxPrice = Number(item.cal_price) * item.pricelookup_qty;

                    let taxPrice = Number(item.tax_include_price) * item.pricelookup_qty;
 					          if (findTender) {
                      taxPrice = Number(findTender.pivot.tax_include_price) * item.pricelookup_qty;
                    }
                    per = (taxPrice * parseFloat(element.tax_value)) / 100;
                  } else {
                    //after override tax calclation
                    // let taxPrice = Number(item.price_override_item_price) * item.pricelookup_qty;

                    if (item?.isSpecialOffer == true) {
                      let taxPrice = Number(price_override_item_price_without_tax) * item.pricelookup_qty;;
                      per = (taxPrice * parseFloat(element.tax_value)) / 100;
                    } else {
                      let taxPrice = Number(price_override_item_price_without_tax);
                      per = (taxPrice * parseFloat(element.tax_value)) / 100;
                    }


                  }
                }
                cal += per;
              } else {
              //  debugger;
                let per = 0;
                // let taxPrice = Number(item.size.tax_include_price) * item.pricelookup_qty;
                // per = (taxPrice * parseFloat(element.tax_value)) / 100;
                // cal += per;
                if (item.add_group_id > 0 || item.remove_group_id > 0) {
                  // || item?.removeItemAry?.length > 0
                  if (item?.addOnItemAry?.length > 0) { // addon and reomve select
                    let cal = 0;
                    if (item.is_substitute == 0) {
                      item.addOnItemAry.forEach(element => {
                        cal += parseFloat(element.price);
                      });
                    }
                    // } else {
                    //   cal = 0;
                    // }
                    // item.addOnprice = cal
                    if(item.price_override_item_status == 1){

                      if (item?.isSpecialOffer == true) {
                        let taxPrice = (Number(price_override_item_price_without_tax) * item.pricelookup_qty);
                        per = (taxPrice * parseFloat(element.tax_value)) / 100;
                      } else {
                        let taxPrice = (Number(price_override_item_price_without_tax));
                        per = (taxPrice * parseFloat(element.tax_value)) / 100;
                      }
                    }else{
                      let taxPrice = (Number(item.tax_include_price) + cal) * item.pricelookup_qty;

                      if (findTender) {
                        taxPrice = (Number(findTender.pivot.tax_include_price) + cal) * item.pricelookup_qty;
                        //element.tax_include_price = (Number(item.tax_include_price) + cal);
                      }
                      per = (taxPrice * parseFloat(element.tax_value)) / 100;
                    }

                  } else { // not select

                    if(item.price_override_item_status == 1){

                      if (item?.isSpecialOffer == true) {
                        let taxPrice = Number(price_override_item_price_without_tax)* item.pricelookup_qty;
                        per = (taxPrice * parseFloat(element.tax_value)) / 100;
                      } else {
                        let taxPrice = Number(price_override_item_price_without_tax);
                        per = (taxPrice * parseFloat(element.tax_value)) / 100;
                      }



                    }else{
                        let taxPrice = Number(item.tax_include_price) * item.pricelookup_qty;
                      if (findTender) {
                          taxPrice = Number(findTender.pivot.tax_include_price) * item.pricelookup_qty;
                          //element.tax_include_price = (Number(item.tax_include_price) + cal);
                        }
                        per = (taxPrice * parseFloat(element.tax_value)) / 100;
                      }
                    }
                }
                // let size = item.size;
                // let sum = 0;
                // // let per = 0;
                // sum += parseFloat(size.tax_include_price);
                // // item.store_addons.forEach(addon => {
                // //   if (addon.selected) {
                // //     sum += parseFloat(addon.tax_include_price);
                // //   }
                // // });
                // let taxPrice = Number(sum) * item.pricelookup_qty;
                // per = (taxPrice * parseFloat(element.tax_value)) / 100;
                cal += per;
              }

              // Per %
              // let per = 0;
              // let taxPrice = Number(item.tax_include_price) * item.pricelookup_qty;
              // per = (taxPrice * parseFloat(element.tax_value)) / 100;
              // if(item.tax_include == 1){
              //   let taxPrice = Number(item.tax_include_price) * item.pricelookup_qty;
              //   per = (taxPrice * parseFloat(element.tax_value)) / 100;
              // }else{
              //   per = (Number(item.cal_price) * parseFloat(element.tax_value)) / 100;
              // }

            } else {
              let per = parseInt(element.tax_value);
              cal = per;
            }

            let standard_price_discount = 0;
            let taxdisVal = 0;
            if (config.isDiscountApply) { //For Discount
			        let calAddOns = 0;
              if (item.add_group_id > 0 || item.remove_group_id > 0) {
                // || item?.removeItemAry?.length > 0
                if (item?.addOnItemAry?.length > 0) {
                  item.addOnItemAry.forEach(element => {
                    calAddOns += parseFloat(element.price);
                  });
                }
              }
              if (parseInt(config.discount_data.discount_type) == 0) {
                if(item.price_override_item_status == 1){

                  if (item?.isSpecialOffer == true) {
                    standard_price_discount = ((Number(item.price_override_item_price) * item.pricelookup_qty ) * (parseFloat(element.tax_value) / 100));
                    if (findTender) {
                      standard_price_discount = (((Number(item.price_override_item_price) * item.pricelookup_qty)) * (parseFloat(element.tax_value) / 100));
                      element.price_override_item_price = (Number(item.price_override_item_price) * item.pricelookup_qty);
                    }
                    taxdisVal = standard_price_discount - (standard_price_discount * (parseFloat(config.discount_data.discount_rate) / 100));
                  } else {
                    standard_price_discount = ((Number(item.price_override_item_price)) * (parseFloat(element.tax_value) / 100));
                    if (findTender) {
                      standard_price_discount = (((Number(item.price_override_item_price))) * (parseFloat(element.tax_value) / 100));
                      element.price_override_item_price = (Number(item.price_override_item_price));
                    }
                    taxdisVal = standard_price_discount - (standard_price_discount * (parseFloat(config.discount_data.discount_rate) / 100));
                  }


                }else{
                  standard_price_discount = ((Number(item.tax_include_price * item.pricelookup_qty) + calAddOns) * (parseFloat(element.tax_value) / 100));
                  if (findTender) {
                    standard_price_discount = (((Number(findTender.pivot.tax_include_price) + calAddOns) * item.pricelookup_qty) * (parseFloat(element.tax_value) / 100));
                    element.tax_include_price = (Number(findTender.pivot.tax_include_price));
                  }
                  taxdisVal = standard_price_discount - (standard_price_discount * (parseFloat(config.discount_data.discount_rate) / 100));
                }
              } else {
                if(item.price_override_item_status == 1){

                  if (item?.isSpecialOffer == true) {
                    standard_price_discount = ((Number(item.price_override_item_price) * item.pricelookup_qty) * (parseFloat(element.tax_value) / 100));
                    if (findTender) {
                      standard_price_discount = (((Number(item.price_override_item_price) * item.pricelookup_qty)) * (parseFloat(element.tax_value) / 100));
                      element.price_override_item_price = (Number(item.price_override_item_price) * item.pricelookup_qty );
                    }
                    taxdisVal = standard_price_discount - (standard_price_discount * (parseFloat(config.discount_data.discount_rate) / 100));
                  } else {
                    standard_price_discount = ((Number(item.price_override_item_price)) * (parseFloat(element.tax_value) / 100));
                    if (findTender) {
                      standard_price_discount = (((Number(item.price_override_item_price))) * (parseFloat(element.tax_value) / 100));
                      element.price_override_item_price = (Number(item.price_override_item_price));
                    }
                    taxdisVal = standard_price_discount - (standard_price_discount * (parseFloat(config.discount_data.discount_rate) / 100));
                  }


                }else{
                  //standard_price_discount = ((item.tax_include_price * item.pricelookup_qty) * (parseFloat(element.tax_value) / 100));
                  standard_price_discount = ((Number(item.tax_include_price * item.pricelookup_qty) + calAddOns) * (parseFloat(element.tax_value) / 100));
                  if (findTender) {
                    standard_price_discount = (((Number(findTender.pivot.tax_include_price)) * item.pricelookup_qty) * (parseFloat(element.tax_value) / 100));
                    element.tax_include_price = (Number(findTender.pivot.tax_include_price));
                  }
                  taxdisVal = standard_price_discount - (standard_price_discount * (parseFloat(config.discount_data.discount_rate) / 100));
                  //standard_price_discount = parseInt(element.tax_value);
                  //taxdisVal = standard_price_discount - parseInt(element.tax_value);
                }
              }
              cal = taxdisVal;
            }
            element.tax_cal = cal;
            element.standard_price = item.standard_price;
            element.tax_include_price = item.tax_include_price;
            taxList.push(element)
            newstTax.push(element)
            // element.tax_cal = cal;
            // taxList.push(element)
          });
          item.taxes = newstTax;//adding new tax to item

          // taxList.forEach(element => {
          // });

        }

        // sub_total += parseFloat(item.cal_price) * parseInt(item.pricelookup_qty);

      });
      // tax_amt = Number(sub_total * tax_amt) / 100;
      // grand_total = grand_total - config.disValue;
      grand_total = sub_total;
      let disVal = 0;
      if (config.isDiscountApply) {
        if (parseInt(config.discount_data.discount_type) == 0) {
          disVal = grand_total * parseFloat(config.discount_data.discount_rate) / 100;
          if (disVal >= parseInt(config.discount_data.allow_maximum_discount)) {
            disVal = parseInt(config.discount_data.allow_maximum_discount)
          }
          grand_total = grand_total - disVal;
        } else {
          disVal = parseFloat(config.discount_data.discount_rate);
          if (disVal >= parseInt(config.discount_data.allow_maximum_discount)) {
            disVal = parseInt(config.discount_data.allow_maximum_discount)
          }
          grand_total = sub_total - disVal;
        }
        // if(taxList.length > 0 && grand_total > 0){
        //   taxList.forEach((element,key) => {
        //     let standard_price_discount = 0;
        //     let taxdisVal = 0;
        //     let standard_price = parseFloat(element.standard_price);
        //     //let tax_value = parseFloat(element.tax_value);
        //     if (parseInt(config.discount_data.discount_type) == 0) {
        //       standard_price_discount = (standard_price * (parseFloat(element.tax_value) / 100));
        //       taxdisVal = standard_price_discount - (standard_price_discount * (parseFloat(config.discount_data.discount_rate) / 100));
        //     }else{
        //       standard_price_discount = (standard_price * (parseFloat(element.tax_value) / 100));
        //       taxdisVal = standard_price_discount - (standard_price_discount * (parseFloat(config.discount_data.discount_rate) / 100));
        //       //standard_price_discount = parseInt(element.tax_value);
        //       //taxdisVal = standard_price_discount - parseInt(element.tax_value);
        //     }
        //     taxList[key]['tax_cal'] = taxdisVal;
        //   });
        // }
      }

      let gt = grand_total.toFixed(2);
      let roundvalue = Math.round(parseFloat(gt));
      let roundOff = Math.abs(roundvalue - parseFloat(gt));
      let data = {
        products: list,
        sub_total: sub_total.toFixed(2),
        tax_amt: this.getTaxAmt(taxList),
        grand_total: roundvalue,
        roundOff: roundOff.toFixed(2),
        total_qty: qty,
        discountAmt: disVal.toFixed(2),
        price_override_status: price_override_status,
        tax_array: taxList,
        discount_data: config.discount_data,
        isDiscountApply: config.isDiscountApply
      }
      localStorage.setItem('rawCartItems', JSON.stringify(list));
      return data;
    }
  }


  getTaxAmt(taxList) {
    let cal = 0;
    taxList.forEach(element => {
      cal += element.tax_cal;
    });
    return cal.toFixed(2);
  }



  addToCart(list, cartData) {

    if (parseInt(cartData.add_group_id) > 0 || parseInt(cartData.remove_group_id) > 0) {
      var find = list.find(x => x.pricelookup_id === cartData.pricelookup_id);
      if (find === undefined) {
        list.push(cartData)
      } else {
        // find.pricelookup_qty++;
        find.cal_price = find.cal_price * find.pricelookup_qty;
      }

      return list
    }
    // if (parseInt(cartData.addon_variation_combine) == 1) {
    //   var find = list.find(x => x.pricelookup_id === cartData.pricelookup_id && x.variation_detail[0].variation_id === cartData.variation_detail[0].variation_id && this.isAddonsMatch(x, cartData));
    //   if (find === undefined) {
    //     list.push(cartData)
    //   } else {
    //     find.pricelookup_qty++;
    //     find.cal_price = find.cal_price * find.pricelookup_qty;
    //   }
    //   return list
    // }
  }

  isAddonsMatch(x, item) {

    const xIds = []
    x.addonIds.forEach(element => {
      xIds.push(element.addons_id)
    });

    const itemIds = []
    item.addonIds.forEach(element => {
      itemIds.push(element.addons_id)
    });
    const a = xIds;
    const b = itemIds;

    const equalsIgnoreOrder = (a, b) => {
      if (a.length !== b.length) return false;
      const uniqueValues = new Set([...a, ...b]);
      for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) return false;
      }
      return true;
    }
    if (equalsIgnoreOrder(a, b)) {
      return true
    } else {
      return false
    }

  }

  formateDate(date, format) {
    return this.datePipe.transform(date, format);
  }

  getCartData(data) {
    data?.forEach(element => {

      // let size = { name: '', id: '' };
      // if (element?.order_detail_variation_addons?.length > 0) {
      //   size = {
      //     name: element.order_detail_variation_addons[0].variation_name,
      //     id: element.order_detail_variation_addons[0].variation_id
      //   }
      // }
      // element.size = size;

      if (parseInt(element.price_override_item_status) == 0) {
        element.cal_price = (parseFloat(element.pricelookup_item_price) * parseInt(element.pricelookup_qty)).toFixed(2);
      } else {
        let price = parseFloat(element.price_override_item_price) * parseInt(element.pricelookup_qty);
        element.cal_price = price.toFixed(2)
      }

      let addCal: any = 0;
      let is_substitute = element.is_substitute;
      //Add AddsOn

      let addAddOnsvalid = element['add_addons_detail'];
	    if (addAddOnsvalid?.length > 0) {
        if (addAddOnsvalid?.length > 0 && is_substitute == 0 && parseInt(element.price_override_item_status) != 1) {
          addAddOnsvalid.forEach(addons_element => {
            addCal += parseFloat(addons_element.price) * parseInt(element.pricelookup_qty);
          });
        }
        if (addCal > 0) {
          element.cal_price = parseFloat(element.cal_price) + parseFloat(addCal);
          element.cal_price = element.cal_price.toFixed(2)
        }
      }

      //END Add AddsOn

      element.price = element.pricelookup_item_price;


      // let variation_addons_detail = [];
      // if (element?.order_detail_variation_addons?.length > 0) {
      //   variation_addons_detail = element.order_detail_variation_addons[0].variationaddons;
      // }
      // let obj = {
      //   variation_addons_detail: variation_addons_detail
      // }
      // element.variation_detail = [obj]

    });
    return data;
  }

  cal_tax(item, flg) {

    let taxes = JSON.parse(item.store_tax);

    if (flg == 0) {
      if (taxes.length > 0) {
        let selectedTender = JSON.parse(localStorage.getItem('selectedTender'))
        let tender_profiles = item.tender_profiles;
        item.store_variations.forEach(variation => {
          let value = 0;
          let per = 0;
          // let findTender = tender_profiles.find(x => x.name === selectedTender.name);
          // if (Number(item.addon_variation_combine) == 1) {
          //   variation.cal_price = variation.tax_include_price;
          //   taxes.forEach(element => {
          //     if (parseInt(element.tax_type) == 1) {
          //       // Percentage
          //       per = parseFloat(variation.cal_price) * parseInt(element.tax_value) / 100;
          //       value += per;
          //     } else {
          //       if (per != 0) {
          //         value = value + parseInt(element.tax_value);
          //       }
          //     }
          //   });
          //   variation.price = (parseFloat(variation.price) + value).toFixed(2);

          // } else {
          //   // variation.price = variation.cal_price;
          //   variation.cal_price = variation.tax_include_price;
          //   taxes.forEach(element => {
          //     if (parseInt(element.tax_type) == 1) {
          //       // Percentage
          //       per = parseFloat(variation.cal_price) * parseInt(element.tax_value) / 100;
          //       value += per;
          //     } else {
          //       if (per != 0) {
          //         value = value + parseInt(element.tax_value);
          //       }
          //     }
          //   });
          //   variation.price = (parseFloat(variation.price) + value).toFixed(2);
          // }
          // variation.price = variation.cal_price;
          variation.cal_price = variation.tax_include_price;
          taxes.forEach(element => {
            if (parseInt(element.tax_type) == 1) {
              // Percentage
              per = parseFloat(variation.cal_price) * parseInt(element.tax_value) / 100;
              value += per;
            } else {
              if (per != 0) {
                value = value + parseInt(element.tax_value);
              }
            }
          });
          variation.price = (parseFloat(variation.price) + value).toFixed(2);
        });
      }
      return item.store_variations;
    } else if (flg == 1) {
      // addons
      if (taxes.length > 0) {
        item.store_addons.forEach(variation => {
          let value = 0;
          let per = 0;
          // variation.price = variation.cal_price;
          variation.cal_price = variation.tax_include_price;
          taxes.forEach(element => {
            if (parseInt(element.tax_type) == 1) {
              // Percentage
              per = parseFloat(variation.cal_price) * parseInt(element.tax_value) / 100;
              value += per;
            } else {
              if (per != 0) {
                value = value + parseInt(element.tax_value);
              }
            }
          });
          variation.price = (parseFloat(variation.price) + value).toFixed(2);
        });
      }
      return item.store_addons;
    } else {
      // Single produect
      let selectedTender = JSON.parse(localStorage.getItem('selectedTender'))
      let tender_profiles = JSON.parse(item.tender_profiles);
      if (taxes.length > 0) {
        taxes.forEach(taxValue => {
          if (tender_profiles.length > 0) {
            let findTender = tender_profiles.find(x => x.id === selectedTender.id);
            if (findTender) {
              if (parseInt(taxValue.tax_type) == 1) {
                // percentag
                let per = Number(findTender.pivot.price) * taxValue.tax_value / 100;
                item.price = Number(findTender.pivot.price) + per;
                item.pricelookup_item_price = item.price;
                item.cal_price = item.price
              } else {
                // Fix
                if (taxValue.tax_type == 0 && taxValue.tax_value == 0) {
                  item.price = Number(item.standard_price);
                  item.pricelookup_item_price = Number(item.standard_price);
                  item.cal_price = Number(item.standard_price);
                } else {
                  item.price = Number(findTender.pivot.price) - taxValue.tax_value;
                  item.pricelookup_item_price = item.price;
                  item.cal_price = item.price
                }
              }
            } else {
              item.price = Number(item.standard_price).toFixed(2);
              item.pricelookup_item_price = Number(item.standard_price).toFixed(2);
            }

          } else {
            item.price = Number(item.standard_price).toFixed(2);
            item.pricelookup_item_price = Number(item.standard_price).toFixed(2);
          }
        });
      }
      // else{
      //   // item.price = Number(item.standard_price).toFixed(2);
      //   // item.pricelookup_item_price = Number(item.standard_price).toFixed(2);
      //   if (tender_profiles.length > 0) {
      //     let findTender = tender_profiles.find(x => x.name === selectedTender.name);
      //     if(findTender){
      //       item.price = parseFloat(findTender.pivot.price);
      //       item.pricelookup_item_price = item.price;
      //       item.cal_price = item.price
      //     }else {
      //       item.price = parseFloat(item.standard_price).toFixed(2);
      //       item.pricelookup_item_price = parseFloat(item.standard_price).toFixed(2);
      //     }
      //   } else {
      //     item.price = Number(item.standard_price).toFixed(2);
      //     item.pricelookup_item_price = Number(item.standard_price).toFixed(2);
      //   }
      // }
      return item;
    }
  }

  checkPermission(name) {
    let permissions = JSON.parse(localStorage.getItem('permissions'));
    if (permissions?.length > 0) {
      let check = permissions.find(x => x == name);
      if (check) {
        return false
      } else {
        return true
      }
    } else {
      return true;
    }
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

}
