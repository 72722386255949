import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { appConfig } from '../app.config';
import { OrderItems } from '../models/order-items';

@Injectable({
  providedIn: 'root'
})
export class PosService {

  constructor(private http: HttpClient,) { }

  assignRider(data) {

    return new Promise((resolve, reject) => {
      
      this.http.post(appConfig.apiUrl+'/api/mobile/v1/orderassign', data).subscribe(res => {
          resolve(res);
        });
    });
    // return this.http.post<any>(appConfig.apiUrl + '/orderupdate', data)
    // .pipe(map(res => {
    //   return res;
    // }));
  }

  orderUpdate(data) {

    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/orderupdate', data)
        .subscribe(res => {
          resolve(res);
        });
    });
    // return this.http.post<any>(appConfig.apiUrl + '/orderupdate', data)
    // .pipe(map(res => {
    //   return res;
    // }));
  }

  getAllOrderData(data) {
    return this.http.post<any>(appConfig.apiUrl + '/orderlistsync', data)
      .pipe(map(res => {
        return res;
      }));
  }

  syncKdsOrders(data) {
    var mheaders =  { 'Access-Control-Allow-Origin': '*' };
    return this.http.post<any>(appConfig.apiUrl + '/kds-orders-list', data,{ headers : mheaders })
      .pipe(map(res => {
        return res;
      }));
  }


  getOrderDetail(data) {
    return this.http.post<any>(appConfig.apiUrl + '/orderdetail', data)
      .pipe(map(res => {
        return res;
      }));
  }
  
  getKdsOrderDetail(data) {
    var mheaders =  { 'Access-Control-Allow-Origin': '*' };
    return this.http.post<any>(appConfig.apiUrl + '/kds-order-detail', data, { headers : mheaders })
      .pipe(map(res => {
        return res;
      }));
  }

  getOrderData(data) {
    return this.http.post<any>(appConfig.apiUrl + '/orderlist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getCountryList() {
    return this.http.post<any>(appConfig.apiUrl + '/countrylist', '')
      .pipe(map(res => {
        return res;
      }));
  }

  addInquiry(data) {
    return this.http.post<any>(appConfig.apiUrl + '/businessenquiry', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAttendanceList(data) {
    return this.http.post<any>(appConfig.apiUrl + '/storeuserattendanceget', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllNotification(data) {
    return this.http.post<any>(appConfig.apiUrl + '/getAllNotification', data)
      .pipe(map(res => {
        return res;
      }));
  }

  clearNotifiction(data) {
    return this.http.post<any>(appConfig.apiUrl + '/clearNotification', data)
      .pipe(map(res => {
        return res;
      }));
  }

  removeNotification(data) {
    return this.http.post<any>(appConfig.apiUrl + '/deleteNotification', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getDiscount() {
    return this.http.post<any>(appConfig.apiUrl + '/discountlist', '')
      .pipe(map(res => {
        return res;
      }));
  }

  // placeOrder(data) {
  //   return this.http.post<any>(appConfig.apiUrl + '/orderplace', data)
  //     .pipe(map(res => {
  //       return res;
  //     }));
  // }

  placeOrder(data) {
    // return new Promise(resolve => {
    //   this.http.post<any>(appConfig.apiUrl + '/orderplace', data)
    //     .pipe(map(res => {
    //       resolve(res);
    //     }));
    // });
    console.log(data)
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/orderplace', data)
        .subscribe(res => {
          resolve(res);
        });
    });
  }


  getStoreProductData(Fdata): Observable<OrderItems[]> {
    return this.http.post<any>(appConfig.apiUrl + '/plumenuitem', Fdata)
      .pipe(map(res => {
        if (res.status == 1) {
          return res.data;
        } else {
          return [];
        }
      }));
  }

  getMainCategoryData(data) {
    return this.http.post<any>(appConfig.apiUrl + '/plucategorylist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getTenderList() {
    return this.http.post<any>(appConfig.apiUrl + '/tenderlist', '')
      .pipe(map(res => {
        if (res.status == 1) {
          return res.data;
        } else {
          return [];
        }
      }));
  }

  getTenderTypeList() {
    return this.http.post<any>(appConfig.apiUrl + '/tendertypelist', { department_id: 1 })
      .pipe(map(res => {
        if (res.status == 1) {
          return res.data;
        } else {
          return [];
        }
      }));
  }

  getCustomerList(data) {
    return this.http.post<any>(appConfig.apiUrl + '/customerlist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  orderListData(data) {
    return this.http.post<any>(appConfig.apiUrl + '/tendertypelist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  posMenuList(data) {
    return this.http.post<any>(appConfig.apiUrl + '/posmenulist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  tenderListData() {
    return this.http.post<any>(appConfig.apiUrl + '/tenderlist', '')
      .pipe(map(res => {
        return res;
      }));
  }

  // ordercancel(data) {
  //   return this.http.post<any>(appConfig.apiUrl + '/ordercancel', data)
  //     .pipe(map(res => {
  //       return res;
  //     }));
  // }

  ordercancel(data) {
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/ordercancel', data)
        .subscribe(res => {
          resolve(res);
        });
    });
  }


  customerAddressList(data) {
    return this.http.post<any>(appConfig.apiUrl + '/customeraddresslist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  saveAddressForCustomer(data) {
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/customeraddressaddupdate', data)
        .subscribe(res => {

          resolve(res);
        });
    });
  }

  removeAddressForCustomer(data) {
    return this.http.post<any>(appConfig.apiUrl + '/customeraddressdelete', data)
      .pipe(map(res => {
        return res;
      }));
  }

  ProfileForCustomer(data) {
    return this.http.post<any>(appConfig.apiUrl + '/customeraddupdate', data)
      .pipe(map(res => {
        return res;
      }))
  }

  transactionLookupOrder(data) {
    return this.http.post<any>(appConfig.apiUrl + '/transaction_lookup_order', data)
      .pipe(map(res => {
        return res;
      }))
  }

  updateKdsItem(data) {
    var mheaders =  { 'Access-Control-Allow-Origin': '*' };
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/update-kds-item', data, {headers : mheaders})
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  getstore(data) {
    return this.http.post<any>(appConfig.apiUrl + '/getstore', data)
      .pipe(map(res => {
        return res;
      }))
  }


}
