import { Component, ElementRef, Inject, TemplateRef, ViewChild } from '@angular/core';
import { PassingDataService } from './services/passing-data.service';
// import { ConnectionService } from 'ng-connection-service';
import { WebSqlService } from './services/web-sql.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// var moment = require('moment');
// import { req } from
// import  'rxjs/add/observable/interval';
import { empty, interval, Subscription } from 'rxjs'
import { AngularFireMessaging } from '@angular/fire/messaging';
import { PosService } from './services/pos.service';
import { BehaviorSubject } from 'rxjs'
import { ConnectionService } from 'ngx-connection-service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { async } from '@angular/core/testing';
//import { DashboardPage } from './pages/dashboard/dashboard.page';
// import { PosDashboardPage } from './pages/pos-dashboard/pos-dashboard.page';
import { AppModule } from 'src/app/app.module';
import EscPosEncoder from 'esc-pos-encoder-ionic';
//import { Socket } from 'ng-socket-io';
//import * as io from 'socket.io-client';
// import { MessagingService } from './services/messaging.service';
// import { OneSignalService } from './services/one-signal.service';
// import { ForegroundService } from '@awesome-cordova-plugins/foreground-service/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  title = 'santushti';
  pos_order_type: any = []
  status = 'Online';
  isConnected = true;
  message;
  isSync: number = 0;
  public notificationAry = [];
  list = []
  currentMessage = new BehaviorSubject(null);
  isDarkMode: boolean;
  internetStatus: any = "Online";
  // customer_background_check: any = 0;
  // tender_type_background_check: any = 0;
  // all_reason_background_check: any = 0;
  modalRef: BsModalRef;
  @ViewChild('mobile', { static: true }) mobileDevice: TemplateRef<any>;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  supportedPlatform: any;
  langFlag: boolean = false;
  counter: number = 0;
  store_order_id: any = '';
  error_try_flag = 0;
  kdsData: any = {};
  store_name: any = '';
  payload: any;

  constructor(public connectionService: ConnectionService, private toastr: ToastrService,
    private modalService: BsModalService, private splashScreen: SplashScreen,
    private statusBar: StatusBar, private fcm: FCM, public alertController: AlertController,
    public network: Network, public appmodule: AppModule,
    //private socket: Socket,
    // public foregroundService: ForegroundService,
    public _router: Router, private dataPass: PassingDataService, private sqlService: WebSqlService,
    private afMessaging: AngularFireMessaging, private _posService: PosService, private elementRef: ElementRef,
    public translate: TranslateService, public platform: Platform, private alertCtrl: AlertController, public toastController: ToastController,
    @Inject(DOCUMENT) private document: Document
    //  private os: OneSignalService
    // private messagingService: MessagingService
  ) {
    // this.socketPushNotification();
    this.checkConnection();
    this.setConfig();
    this.syncAfter5min();
    this.syncMobileOrder();
    this.set_network();
    //this.syncBackground();
    this.initializeApp();

    // this.backButton()
    this.isDarkMode = JSON.parse(localStorage.getItem('darkMode'))
    if (this.isDarkMode == null) {
      this.isDarkMode = true;
    }
    if (this.isDarkMode) {
      var s = document.createElement('link')
      s.rel = "stylesheet";
      s.type = "text/css";
      s.href = 'assets/styles/dark.css';
      this.elementRef.nativeElement.appendChild(s)
      // this.darkorlight= "Light Mode"
    } else {
      var s = document.createElement('link')
      s.rel = "stylesheet";
      s.type = "text/css";
      s.href = 'assets/styles/light.css';
      this.elementRef.nativeElement.appendChild(s)
      // this.darkorlight= "Dark Mode"
    }

    // this.pos_order_type = [

    //   {
    //     orderType_id: 1,
    //     orderType_name: "Dine In",
    //     cssClass:"btn-success"
    //   },

    //   {
    //     orderType_id: 2,
    //     orderType_name: "Take Away",
    //     cssClass:"btn-primary"
    //   },

    //   {
    //     orderType_id: 3,
    //     orderType_name: "Zomato",
    //     cssClass:"btn-danger"
    //   },

    //   {
    //     orderType_id: 4,
    //     orderType_name: "Swiggy",
    //     cssClass:"btn-warning"
    //   }
    // ]
    // this.setPOSGeneralSetting()


    this.translate.addLangs(['en', 'ar']);
    let browserLang = localStorage.getItem('locale');
    if (localStorage.getItem('locale')) {
      this.translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      this.translate.setDefaultLang('en');
      browserLang = localStorage.getItem('locale');
    }
    if (browserLang == 'ar') {
      this.langFlag = true;
    } else {
      this.langFlag = false;;
    }
    localStorage.setItem('locale', browserLang);
    this.translate.use(browserLang);
    let htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    htmlTag.dir = browserLang === 'ar' ? 'rtl' : 'ltr';

  }


  // async socketPushNotification() {
  //   let tempStoreId = JSON.parse(localStorage.getItem('store_id'))
  //   let kds_id = JSON.parse(localStorage.getItem('kds_id'));

  //   var channel = 'store'+tempStoreId+'kds'+kds_id
  //   console.log("channel",channel)

  //   this.socket.on(channel,async (message)=>{
  //     let messageData=JSON.parse(message.data);
  //     console.log("New Push notification : ",messageData);
  //     if (messageData.order_status == 3) {
  //       let audio = new Audio("../assets/pending_orders_sound.mp3");
  //       audio.play()
  //     } else {
  //       let audio = new Audio("../assets/new.mp3");
  //       audio.play();
  //     }
  //     console.log('notificationObject', message)
  //     console.log('notificationObjectData', messageData)
  //     console.log('notificationObjectDataTitle', messageData.title)
  //     console.log('messageData.order.local_transaction_no', messageData.order.local_transaction_no)
  //      this.toastr.success(messageData.title)
  //   //  this.toastr.success(messageData.title)

  //     let store_order_sequence_id = '0';
  //     let local_transaction_no = 0;
  //     let click_action = messageData.click_action;
  //     let currentDate = new Date()
  //     let created_date = Date.parse(currentDate.toString());

  //     if (click_action == 'order_placed') {
  //       store_order_sequence_id = await this.createSequentialOrderNo();
  //      // console.log(' 459 store_order_sequence_id',store_order_sequence_id)
  //       local_transaction_no = await this.createSequentialLocalTransactionNo(messageData.order.local_transaction_no, messageData.order.device_type);

  //     }

  //     /* if((click_action=='order_cooking_started' || click_action=='order_ready'  ) && JSON.parse(messageData.order).store_order_sequence_id==0)
  //     {
  //       store_order_sequence_id =  this.createSequentialOrderNo();
  //       local_transaction_no =  this.createSequentialLocalTransactionNo(JSON.parse(messageData.order).local_transaction_no,JSON.parse(messageData.order).device_type);
  //     } */

  //     //debugger

  //    // console.log('this.list', this.list)
  //   //  console.log('messageData.order_id ' + messageData.order_id + ' Index OF' + this.list.indexOf(messageData.order_id))
  //     if (this.list.indexOf(messageData.order_id) == -1) {
  //       this.list.push(messageData.order_id);
  //     }
  //     let order_status = 0;
  //     let push_order_id = 0;

  //     //  this.sqlService.dbInstance.executeSql(`SELECT * FROM push_notifications where order_id = "` + messageData.order_id + `" AND id not in(` + this.push_ids + `) LIMIT 1`, []).then(async (res) => {
  //     //   //this.delay(100 * res.rows.length);
  //     //   if (res.rows.length > 0) {//update
  //     //      this.sqlService.dbInstance.executeSql(`UPDATE push_notifications SET isSync = 0 where order_id = ` + messageData.order_id, []).then((res) => { })
  //     //     order_status = 0;
  //     //     push_order_id = res.rows.item(0).order_id
  //     //     var p_id = res.rows.item(0).id

  //     //      //this.syncMobileOrder(push_order_id, order_status, store_order_sequence_id, local_transaction_no);

  //     //   } else {//create new
  //     //     console.log(' 489 store_order_sequence_id',store_order_sequence_id)
  //     //     console.log('messageData.order_id',messageData.order_id)
  //     //     console.log('local_transaction_no',local_transaction_no)
  //     //     console.log('created_date',created_date)

  //     //      this.sqlService.dbInstance.executeSql(`INSERT INTO push_notifications (isSync,order_id,store_order_sequence_id,local_transaction_no,created_at) VALUES(0,` + messageData.order_id + `,'` + store_order_sequence_id + `',` + local_transaction_no + `,` + created_date + `)`, []).then(async (res) => {
  //     //        console.log("trasndata", res)
  //     //       // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
  //     //       order_status = 0;
  //     //       push_order_id = messageData.order_id
  //     //       //this.syncMobileOrder(push_order_id, order_status, store_order_sequence_id, local_transaction_no);

  //     //     })
  //     //   }
  //     // })

  //     localStorage.setItem("notification", JSON.stringify(this.list))
  //   })
  // }

  async socketPushNotification() {
    let tempStoreId = JSON.parse(localStorage.getItem('store_id'))
    let kds_id = JSON.parse(localStorage.getItem('kds_id'));

    var channel = 'store' + tempStoreId + 'kds' + kds_id
    console.log("channel", channel)

    // this.socket.on(channel, async (messageData) => {
    //   let message = JSON.parse(messageData.data);
    //   console.log("New Push notification : ", message);

    //   let audio = new Audio("../assets/new.mp3");

    //   audio.play();
    //   // this.presentAlertNotification1()
    //   this.toastr.success(message.title)

    //   // let order = this.getOrderDetail(message)




    //   // this.currentMessage.next(message);
    //   // return new Promise(async resolve => {



    //   //this.notificationAry.push(message.data.order)
    //   //let list = JSON.parse(localStorage.getItem('notification'))

    //   //debugger

    //   //this.createOrder(JSON.parse(message.data.order))
    //   localStorage.setItem("is_receive_notification", "1")
    //   var notificaion_order = message.order;
    //   console.log("notificaion_order", notificaion_order)
    //   console.log("notificaion_order.order_status", notificaion_order.order_status)
    //   if (notificaion_order.order_status == 1 || notificaion_order.order_status == 2) {
    //     localStorage.setItem('order_move_flag', notificaion_order.id);
    //   } else {
    //     localStorage.setItem('order_move_flag', "0");
    //   }

    //   console.log("localStorage.getItem('order_move_flag')", localStorage.getItem('order_move_flag'))


    //   if (this.list.indexOf(message.order_id) == -1) {
    //     this.list.push(message.order_id);
    //   }
    //   await this.sqlService.dbInstance.executeSql(`SELECT * FROM push_notifications where order_id = ` + message.order_id, []).then(async (res) => {
    //     if (res.rows.length > 0) {//update
    //       await this.sqlService.dbInstance.executeSql(`UPDATE push_notifications SET isSync = 0 where order_id = ` + message.order_id, []).then((res) => { })
    //     } else {//create new
    //       await this.sqlService.dbInstance.executeSql(`INSERT INTO push_notifications (isSync,order_id) VALUES(0,` + message.order_id + `)`, []).then((res) => {

    //         // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
    //       })
    //     }
    //   })
    //   localStorage.setItem("notification", JSON.stringify(this.list))



    //   // if (list) {
    //   //   list.push(message.data.order_id);
    //   // } else {
    //   //   list = message.data.order_id;
    //   // }

    //   //localStorage.setItem("notification", JSON.stringify(this.notificationAry))

    //   //await this.syncMobileOrder()
    //   // if (message.notification.order_status == 3) {
    //   //   this.posdashboard.tabClicked('pending')
    //   // } else if (message.notification.order_status == 4) {
    //   //   this.posdashboard.tabClicked('accepted')
    //   // } else if (message.notification.order_status == 6) {
    //   //   this.posdashboard.tabClicked('inprocess')
    //   // } else if (message.notification.order_status == 5) {
    //   //   this.posdashboard.tabClicked('indelivery')
    //   // } else if (message.notification.order_status == 1) {
    //   //   this.posdashboard.tabClicked('completed')
    //   // } else if (message.notification.order_status == 2) {
    //   //   this.posdashboard.tabClicked('cancel')
    //   // }
    //   // await this.sqlService.syncData()
    //   //let mobileData = JSON.parse(message.data.order)
    //   // resolve()
    //   // })

    // })


    // this.notificationAry.push(resp)
    // })

  }


  async createSequentialLocalTransactionNo(local_transaction_no, device_type) {

    await this.createTranscationNoTabel()
    var new_transaction_number = Number(localStorage.getItem('local_transaction_no'));
    if (local_transaction_no == 0 && (device_type == 1 || device_type == 2 || device_type == 0)) {

      //  console.log("localStorage.getItem('local_transaction_no')1", localStorage.getItem('local_transaction_no'))
      if (localStorage.getItem('local_transaction_no')) {
        new_transaction_number = Number(localStorage.getItem('local_transaction_no')) + 1;
      }

      // await this.sqlService.dbInstance.executeSql(`SELECT tran_no FROM transcation_no ORDER BY tran_no DESC LIMIT 1`, {}).then((res) => {
      //   if (res.rows.length > 0) {
      //     var total_transaction_number = res.rows.item(0).tran_no;
      //     if (total_transaction_number > 0) {
      //       new_transaction_number = total_transaction_number + 1;
      //     } else {
      //       new_transaction_number = 1;
      //     }
      //   } else {
      //     new_transaction_number = 1;
      //   }
      // })
    } else {
      new_transaction_number = local_transaction_no;
    }

    localStorage.setItem('local_transaction_no', new_transaction_number.toString());
    // let transdata = [`'` + new Date() + `'`]
    // await this.sqlService.dbInstance.executeSql(`INSERT INTO transcation_no (tran_no, created_at) VALUES(` + new_transaction_number + `,` + transdata + `)`, []).then((res) => {
    // })
    return new_transaction_number;

  }

  async ngOnInit() {
    // this.requestPermission();
    this.checkDevice();
    await this.listen();
    // this.checkMobile(this.mobileDevice) //show in desktop only

    // this.ConnectionService.monitor().subscribe(isConnected => {
    //   if (isConnected) {
    //     this.internetStatus = "Online";
    //   } else {
    //     this.internetStatus = "Offline";
    //   }
    //   this.dataPass.internetCheck(this.internetStatus)
    // })


    //  this.createOrder()
    // const userId = 'user001';
    // this.messagingService.requestPermission(userId)
    // this.messagingService.receiveMessage();
    // this.message = this.messagingService.currentMessage


    // this.os.onInit();
  }



  truncateText(text, length) {
    if (typeof text != 'string') {
      text = text.toString();
    }
    if (text.length <= length) {
      return text;
    }
    return text.substr(0, length - 1) + '-'
  }

  checkDevice() {
    this.platform.ready().then(() => {
      // debugger


      var deviceWidth = this.platform.width()
      localStorage.setItem('platformWidth', deviceWidth)
      if (this.platform.is('android')) {

        localStorage.setItem('platform', 'android')
      } else if (this.platform.is('ios')) {

        localStorage.setItem('platform', 'ios')
      } else {

        localStorage.setItem('platform', 'web')
      }
    });
  }

  checkMobile(template: TemplateRef<any>) {
    if (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      // document.write("mobile device");

      localStorage.setItem('platform', 'mobile')
      // this.modalRef = this.modalService.show(template, this.config);
      // this.modalRef.setClass('modal-dialog-centered');
    } else {
      // false for not mobile device
    }
  }

  // to access permission from user to recieve push notification
  requestPermission() {
    this.afMessaging.requestToken
      .subscribe((token) => {
        localStorage.setItem('fcm_token', JSON.stringify(token))

      },
        (error) => { console.log(error) })
  }



  // onesignal_setup() {
  //   this.oneSignal.startInit('e3a31f2c-96c6-4af9-9d52-a1568407dcc6', '380062583748')
  //   this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
  //   this.oneSignal.handleNotificationReceived().subscribe((data) => {
  //
  //     // do something when notification is received
  //   });
  //   this.oneSignal.handleNotificationOpened().subscribe((data) => {
  //     // do something when a notification is opened
  //     // this.loading.alert('notification',JSON.stringify(data));
  //
  //     if (data.notification.payload.additionalData) {
  //       let load = data.notification.payload.additionalData;
  //
  //     }
  //   });
  //   this.oneSignal.endInit();
  // }

  async toast(msg, type) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 10000,
      position: "top",
      color: type,
      cssClass: 'cus_toast'
    });
    toast.present();
  }

  backButton() {
    this.counter = 0;
    this.platform.backButton.subscribe(() => {
      this.counter = this.counter + 1;

      if (this.counter == 2) {
        if (this._router.url === '/dashboard') {
          this.presentAlertConfirm();
          setTimeout(() => {
            this.counter = 0;
          }, 1000);
        } else {
          this.counter = 0;
        }
      } else {
        this._router.navigateByUrl('/dashboard')
      }
    });
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant('Confirm!'),
      message: this.translate.instant('Are you sure want to exit?'),
      buttons: [
        {
          text: this.translate.instant('NOT NOW'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }, {
          text: this.translate.instant('EXIT APP'),
          cssClass: 'secondary',
          handler: () => {

            navigator['app'].exitApp();
          }
        }
      ]
    });
    await alert.present();
  }





  // test() {
  //   // alert('test')
  //   var notificationData =  JSON.parse(localStorage.getItem('notificationData'))
  //   this.foregroundService.start(notificationData.title, '');
  //   setTimeout(() => {
  //     this.foregroundService.stop();
  //   }, 3000);
  // }

  // async presentAlertNotification() {
  //
  //   var notificationData =  JSON.parse(localStorage.getItem('notificationData'))
  //    const alert = await this.alertController.create({
  //      cssClass: 'my-custom-class',
  //      header: notificationData.title,
  //      message: notificationData.message,
  //      buttons: [
  //        {
  //          text: this.translate.instant('Accept'),
  //          role: 'cancel',
  //          cssClass: 'secondary',
  //          handler: (blah) => {
  //
  //          },
  //        },
  //        {
  //          text: this.translate.instant('Reject'),
  //          handler: (res) => {
  //            this.goToPosDashboard();
  //
  //          },
  //        },
  //      ],
  //    });
  //    await alert.present();
  //  }

  goToPosDashboard() {
    this._router.navigate(['/pos-dashboard'])
  }

  async presentAlertNotification1() {
    var notificationData = JSON.parse(localStorage.getItem('notificationData'))
    // alert(JSON.stringify(notificationData))
    console.log('notificationData',notificationData);
    this.toast(notificationData.title, 'warning');
    let audio = new Audio("../assets/new.mp3"); //let audio = new Audio("../assets/alert_sound.mp3");
    console.log('audio', audio)
    audio.play();
    if (this.list.indexOf(notificationData.order_id) == -1) {
      this.list.push(notificationData.order_id);
    }

    await this.sqlService.dbInstance.executeSql(`SELECT * FROM push_notifications where order_id = ` + notificationData.order_id, []).then(async (res) => {
      if (res.rows.length > 0) {//update
        await this.sqlService.dbInstance.executeSql(`UPDATE push_notifications SET isSync = 0 where order_id = ` + notificationData.order_id, []).then((res) => { })
      } else {//create new
        await this.sqlService.dbInstance.executeSql(`INSERT INTO push_notifications (isSync,order_id) VALUES(0,` + notificationData.order_id + `)`, []).then((res) => {

          // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
        })
      }
    })
    localStorage.setItem("notification", JSON.stringify(this.list))
    var noti = JSON.parse(notificationData.order);

    if (noti.order_status == 1 || noti.order_status == 2) {
      await this.sqlService.dbInstance.executeSql(`UPDATE orders SET order_status = ` + noti.order_status + `  where database_id = ` + notificationData.order_id, []).then(async (res) => {
        localStorage.setItem('order_move_flag', notificationData.order_id);
        this.dataPass.getnewOrderReciveKds(true)
      });
    } else {
      localStorage.setItem('order_move_flag', "0");
    }

    // this.sqlService.syncData()
    // await this.syncMobileOrder()
    // if (notificationData.order_status == 3) {
    //   this.posdashboard.tabClicked('pending')
    // } else if (notificationData.order_status == 4) {
    //   this.posdashboard.tabClicked('accepted')
    // } else if (notificationData.order_status == 6) {
    //   this.posdashboard.tabClicked('inprocess')
    // } else if (notificationData.order_status == 5) {
    //   this.posdashboard.tabClicked('indelivery')
    // } else if (notificationData.order_status == 1) {
    //   this.posdashboard.tabClicked('completed')
    // } else if (notificationData.order_status == 2) {
    //   this.posdashboard.tabClicked('cancel')
    // }

  }


  async createTranscationNoTabel() {
    await this.sqlService.dbInstance.executeSql(`CREATE TABLE IF NOT EXISTS transcation_no(
      tran_no INTEGER NOT NULL,
      created_at TEXT
    )`, []);
  }

  // getKdsOrderDetail(order) {
  //   var data = {
  //     order_id: order.data.order_id,
  //     is_kds: 1,
  //     store_id:localStorage.getItem('store_id'),
  //     kds_id:localStorage.getItem('kds_id'),
  //     kds_departments:JSON.parse(localStorage.getItem('kds_departments')),
  //     get_departments:JSON.parse(localStorage.getItem('get_departments'))
  //   }
  //   this._posService.getKdsOrderDetail(data)
  //     .subscribe(
  //       res => {
  //         if (res.status === 1) {
  //           let order_items = res.data;
  //           return order_items
  //         }
  //       },
  //       err => {

  //       });
  // }

  //to listen notificaion from API
  async listen() {
    // debugger
    // return new Promise(async resolve => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.addEventListener('message', async (event) => {
        this.payload = event.data;
        console.log('payload backGoundPushNotification', this.payload)
        var message = this.payload
        // const resp = this.afMessaging.messages
          // .subscribe(async (message: any) => {
            console.log("message", message)
            let audio = new Audio("../assets/new.mp3");
            audio.play();
            // this.presentAlertNotification1()
            this.toastr.success(message.notification.title)
            // let order = this.getOrderDetail(message)
            // this.currentMessage.next(message);
            // return new Promise(async resolve => {
            //this.notificationAry.push(message.data.order)
            //let list = JSON.parse(localStorage.getItem('notification'))
            //debugger
            //this.createOrder(JSON.parse(message.data.order))
            localStorage.setItem("is_receive_notification", "1")
            var notificaion_order = JSON.parse(message.data.order);
            console.log("notificaion_order", notificaion_order)
            console.log("notificaion_order.order_status", notificaion_order.order_status)
            if (notificaion_order.order_status == 1 || notificaion_order.order_status == 2) {
              localStorage.setItem('order_move_flag', notificaion_order.id);
            } else {
              localStorage.setItem('order_move_flag', "0");
            }
            console.log("localStorage.getItem('order_move_flag')", localStorage.getItem('order_move_flag'))
            if (this.list.indexOf(message.data.order_id) == -1) {
              this.list.push(message.data.order_id);
            }
            await this.sqlService.dbInstance.executeSql(`SELECT * FROM push_notifications where order_id = ` + message.data.order_id, []).then(async (res) => {
              if (res.rows.length > 0) {//update
                await this.sqlService.dbInstance.executeSql(`UPDATE push_notifications SET isSync = 0 where order_id = ` + message.data.order_id, []).then((res) => { })
              } else {//create new
                await this.sqlService.dbInstance.executeSql(`INSERT INTO push_notifications (isSync,order_id) VALUES(0,` + message.data.order_id + `)`, []).then((res) => {
                  // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
                })
              }
            })
            localStorage.setItem("notification", JSON.stringify(this.list))
          // })
        });
        }
      }

  initializeApp() {
        this.platform.ready().then(() => {
          this.statusBar.styleDefault();
          this.splashScreen.hide();
          // subscribe to a topic
          // this.fcm.subscribeToTopic('Deals');
          // get FCM token
          // this.fcm.getToken().then(token => {
          //
          //   localStorage.setItem('fcm_token', JSON.stringify(token))
          // });
          // ionic push notification example
          this.fcm.onNotification().subscribe(async data => {

            if (data.wasTapped) {

              // this.sqlService.syncData()
              if (this.list.indexOf(data.order_id) == -1) {
                this.list.push(data.order_id);
              }
              await this.sqlService.dbInstance.executeSql(`SELECT * FROM push_notifications where order_id = ` + data.order_id, []).then(async (res) => {
                if (res.rows.length > 0) {//update
                  await this.sqlService.dbInstance.executeSql(`UPDATE push_notifications SET isSync = 0 where order_id = ` + data.order_id, []).then((res) => { })
                } else {//create new
                  await this.sqlService.dbInstance.executeSql(`INSERT INTO push_notifications (isSync,order_id) VALUES(0,` + data.order_id + `)`, []).then((res) => {

                    // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
                  })
                }
              })

              // if (list) {
              //   list.push(message.data.order_id);
              // } else {
              //   list = message.data.order_id;
              // }

              //localStorage.setItem("notification", JSON.stringify(this.notificationAry))
              localStorage.setItem("notification", JSON.stringify(this.list))
              localStorage.setItem("is_receive_notification", "1")
              // await this.syncMobileOrder()
              // if (data.notification.order_status == 3) {
              //   this.posdashboard.tabClicked('pending')
              // } else if (data.notification.order_status == 4) {
              //   this.posdashboard.tabClicked('accepted')
              // } else if (data.notification.order_status == 6) {
              //   this.posdashboard.tabClicked('inprocess')
              // } else if (data.notification.order_status == 5) {
              //   this.posdashboard.tabClicked('indelivery')
              // } else if (data.notification.order_status == 1) {
              //   this.posdashboard.tabClicked('completed')
              // } else if (data.notification.order_status == 2) {
              //   this.posdashboard.tabClicked('cancel')
              // }
            } else {
              this.fcm.createNotificationChannel({
                id: 'com.limerr.kds', // required
                name: 'Alert', // required
                description: 'Very urgent message alert',
                importance: 'high', // https://developer.android.com/guide/topics/ui/notifiers/notifications#importance
                visibility: 'public', // https://developer.android.com/training/notify-user/build-notification#lockscreenNotification
                sound: 'alert_sound', // In the "alert_sound" example, the file should located as resources/raw/alert_sound.mp3
                lights: false, // enable lights for notifications
                vibration: true, // enable vibration for notifications
              });

              localStorage.setItem('notificationData', JSON.stringify(data))
              localStorage.setItem("is_receive_notification", "1")
              // this.test()
              await this.presentAlertNotification1()
            }
            var notificaion_order = JSON.parse(data.order);
            console.log("notificaion_order.order_status", notificaion_order.order_status)
            if (notificaion_order.order_status == 1 || notificaion_order.order_status == 2) {
              localStorage.setItem('order_move_flag', notificaion_order.id);
            } else {
              localStorage.setItem('order_move_flag', "0");
            }
            // await this.syncMobileOrder()
          });
          // refresh the FCM token
          // this.fcm.onTokenRefresh().subscribe(token => {
          //
          // });
          // unsubscribe from a topic
          // this.fcm.unsubscribeFromTopic('offers');
        });
      }


  async createOrder(mobileData) {


        console.log("mobileData",mobileData)
        // return;
        return new Promise(async resolve => {
          await this.sqlService.dbInstance.executeSql(`SELECT * FROM orders where database_id = ` + mobileData.id, []).then(async (res) => {
            //debugger
            if (res.rows.length > 0) {
              var local_order_id = res.rows.item(0).id

              // alert("update order")
              // async updateOrderSyncInWbSql(order, serverOrder) {

              await this.sqlService.dbInstance.executeSql(`UPDATE orders SET order_status = ` + mobileData.order_status + `  where database_id = ` + mobileData.id, []).then(async (res) => {

                await this.sqlService.dbInstance.executeSql(`DELETE FROM order_store_detail where order_id = ` + local_order_id, []);
                await this.sqlService.dbInstance.executeSql(`DELETE FROM order_store_kds where order_id = ` + local_order_id, []);

                
                    if(mobileData.order_kds_detail_items.length > 0){
                      mobileData.order_kds_detail_items.forEach(async res => {
                        let order_kds_data = [
                          res.id, res.kds_id, res.order_id, res.order_detail_id, res.group_id, res.pricelookup_id,res.pricelookup_qty, res.status, res.created_at_formatted
                        ]
                        await this.sqlService.dbInstance.executeSql(`INSERT INTO order_store_kds(database_id,kds_id,order_id,order_detail_id,group_id,pricelookup_id,pricelookup_qty,status,created_at) VALUES (` + order_kds_data.toString() + `)`, [])
                      });
                    }

                mobileData.order_detail.forEach(async cartData => {



                  //debugger

                  // if (res_check_order.rows.item.length > 0) {

                  //   let price_cmt = cartData.price_override_item_comment != "" ? cartData.price_override_item_comment : 'null';
                  //   let plu_type = cartData.plu_type;
                  //   let addOnItemAry = cartData.order_detail_add_add_ons ? JSON.stringify(cartData.order_detail_add_add_ons) : '[]';
                  //   let removeItemAry = cartData.order_detail_remove_add_ons ? JSON.stringify(cartData.order_detail_remove_add_ons) : '[]';
                  //   let specialNote = cartData.specialNote != "" ? cartData.specialNote : '';
                  //   // let storeTaxAry = cartData.order_detail_tax ? JSON.stringify(cartData.order_detail_tax) : '[]';
                  //   let storeTaxAry_temp = []

                  //
                  //

                  //   cartData.order_detail_tax.forEach(async cartDataTax => {

                  //     let standard_price = ((cartData.price_override_item_status == 0 ? cartData.pricelookup_item_price : cartData.price_override_item_price) ?? 0)

                  //       storeTaxAry_temp.push(
                  //         {
                  //           id :  cartDataTax.id ,
                  //           display_name :  cartDataTax.name ,
                  //           tax_value :  cartDataTax.tax_value ,
                  //           tax_type :  cartDataTax.tax_type ,
                  //           tax_cal :  Number(cartDataTax.tax_amount ),
                  //           // standard_price :  cartData.pricelookup_item_price ,
                  //           standard_price :  standard_price ,
                  //           tax_include_price :  cartData.tax_include_price
                  //         }
                  //       )
                  //   })

                  //
                  //

                  //   let storeTaxAry = JSON.stringify(storeTaxAry_temp)



                  //   let custAddress = mobileData.customer_address.house_no + " " + mobileData.customer_address.society_name + " " + mobileData.customer_address.state + " " + mobileData.customer_address.pincode ? JSON.stringify(mobileData.customer_address.house_no + " " + mobileData.customer_address.society_name + " " + mobileData.customer_address.state + " " + mobileData.customer_address.pincode) : '[]'
                  //   let is_taken_for_new_item = cartData.is_taken_for_new_item ? cartData.is_taken_for_new_item : 0;
                  //   let storeCategoriesAry = '[]';


                  //     await this.sqlService.dbInstance.executeSql(`UPDATE order_store_detail SET order_id = ` + local_order_id + `,pricelookup_id = `+cartData.pricelookup_id+`,pricelookup_qty = `+cartData.pricelookup_qty+`, pricelookup_name = '` + cartData.pricelookup_name.replace("'", "`") + `',
                  //     pricelookup_item_price = `+cartData.pricelookup_item_price+`, price_override_item_status = `+cartData.price_override_item_status+`, price_override_item_price = `+cartData.price_override_item_price+`,
                  //     price_override_item_id = `+cartData.price_override_item_id+`, price_override_item_comment = '` + price_cmt + `', add_addons_detail = '` + addOnItemAry + `', remove_addons_detail = '` + removeItemAry + `',
                  //     remove_group_id = `+cartData.remove_group_id+`,add_group_id = `+cartData.add_group_id+`,is_advance_ingredient = `+cartData.is_advance_ingredient+`,note = '` + specialNote + `', is_substitute = `+cartData.is_substitute+`, is_taken_for_new_item = `+is_taken_for_new_item+`,is_variation_pricelookup = `+cartData.is_variation_pricelookup+`,store_categories = '` + storeCategoriesAry + `',taxes = '` + storeTaxAry + `',customer_address = '` + this.sqlService.addslashes(custAddress) + `',plu_type= `+plu_type+`  where database_id = ` + cartData.id, []).then((res) => {
                  //
                  //     })

                  //     await this.sqlService.dbInstance.executeSql(`UPDATE order_store_kds SET kds_id=`+cartData.get_k_d_s_detail.kds_id+`,order_id=`+cartData.get_k_d_s_detail.order_id+`,order_detail_id=`+cartData.get_k_d_s_detail.order_detail_id+`,pricelookup_id=`+cartData.get_k_d_s_detail.pricelookup_id+`,status=`+cartData.get_k_d_s_detail.status+`,created_at=`+cartData.get_k_d_s_detail.created_at_formatted+`  where database_id = ` + cartData.get_k_d_s_detail.id, []).then((res) => {

                  //     })

                  // }else{
                  let price_cmt = cartData.price_override_item_comment != "" ? cartData.price_override_item_comment : 'null';
                  let plu_type = cartData.plu_type;
                  let addOnItemAry = cartData.order_detail_add_add_ons ? JSON.stringify(cartData.order_detail_add_add_ons) : '[]';
                  let removeItemAry = cartData.order_detail_remove_add_ons ? JSON.stringify(cartData.order_detail_remove_add_ons) : '[]';
                  let specialNote = cartData.specialNote != "" ? cartData.specialNote : '';
                  // let storeTaxAry = cartData.order_detail_tax ? JSON.stringify(cartData.order_detail_tax) : '[]';
                  let storeTaxAry_temp = []




                  cartData.order_detail_tax.forEach(async cartDataTax => {

                    let standard_price = ((cartData.price_override_item_status == 0 ? cartData.pricelookup_item_price : cartData.price_override_item_price) ?? 0)

                    storeTaxAry_temp.push(
                      {
                        id: cartDataTax.id,
                        display_name: cartDataTax.name,
                        tax_value: cartDataTax.tax_value,
                        tax_type: cartDataTax.tax_type,
                        tax_cal: Number(cartDataTax.tax_amount),
                        // standard_price :  cartData.pricelookup_item_price ,
                        standard_price: standard_price,
                        tax_include_price: cartData.tax_include_price
                      }
                    )
                  })




                  let storeTaxAry = JSON.stringify(storeTaxAry_temp)


                  let custAddress = ""
                  if (mobileData.delivery_partner_id == 0 || mobileData.delivery_partner_id == 7 || mobileData.delivery_partner_id == 8) {
                   let custAddress = mobileData.customer_address?.house_no + " " + mobileData.customer_address?.society_name + " " + mobileData.customer_address?.state + " " + mobileData.customer_address?.pincode ? JSON.stringify(mobileData.customer_address?.house_no + " " + mobileData.customer_address?.society_name + " " + mobileData.customer_address?.state + " " + mobileData.customer_address?.pincode) : '[]'
                  }
                  let is_taken_for_new_item = cartData.is_taken_for_new_item ? cartData.is_taken_for_new_item : 0;
                  let storeCategoriesAry = '[]';

                  let order_cart_data = [cartData.id,
                    local_order_id, cartData.pricelookup_id, cartData.pricelookup_qty, `'` + cartData.pricelookup_name.replace("'", "`") + `'`,
                  cartData.pricelookup_item_price, cartData.price_override_item_status, cartData.price_override_item_price,
                  cartData.price_override_item_id, `'` + price_cmt + `'`, `'` + addOnItemAry + `'`, `'` + removeItemAry + `'`, cartData.remove_group_id, cartData.add_group_id,
                  cartData.is_advance_ingredient, `'` + specialNote + `'`,
                  cartData.is_substitute, is_taken_for_new_item, cartData.is_variation_pricelookup, `'` + storeCategoriesAry + `'`, `'` + storeTaxAry + `'`, `'` + this.sqlService.addslashes(custAddress) + `'`, plu_type
                  ]
                  // , cartData.tax_include_price
                  await this.sqlService.dbInstance.executeSql(`INSERT INTO order_store_detail(database_id,order_id, pricelookup_id, pricelookup_qty, pricelookup_name,
                    pricelookup_item_price, price_override_item_status, price_override_item_price,
                    price_override_item_id, price_override_item_comment, add_addons_detail, remove_addons_detail,
                    remove_group_id,add_group_id,is_advance_ingredient,note, is_substitute, is_taken_for_new_item,is_variation_pricelookup,store_categories,taxes,customer_address,plu_type) VALUES (`+ order_cart_data.toString() + `)`, [])

                    
                    
                 /* await this.sqlService.dbInstance.executeSql(`SELECT * FROM order_store_kds where order_detail_id = ` + cartData.get_k_d_s_detail.order_detail_id + ` AND group_id =`+ cartData.get_k_d_s_detail.group_id, []).then(async (res) => {
                    //debugger
                    if (res.rows.length > 0) {
                      await this.sqlService.dbInstance.executeSql(`UPDATE order_store_kds SET database_id = ` + cartData.get_k_d_s_detail.id + `,kds_id = ` + cartData.get_k_d_s_detail.kds_id + `,order_id = ` + cartData.get_k_d_s_detail.order_id + `,order_detail_id = ` + cartData.get_k_d_s_detail.order_detail_id + `,group_id = ` + cartData.get_k_d_s_detail.group_id + `,pricelookup_id = ` + cartData.get_k_d_s_detail.pricelookup_id + `,pricelookup_qty = ` + cartData.get_k_d_s_detail.pricelookup_qty + `,status = ` + cartData.get_k_d_s_detail.status + `  where order_detail_id = ` + cartData.get_k_d_s_detail.order_detail_id, []).then(async (res) => {

                      });

                    } else {
                      if (cartData.get_k_d_s_detail) {
                        let order_kds_data = [
                          cartData.get_k_d_s_detail.id, cartData.get_k_d_s_detail.kds_id, cartData.get_k_d_s_detail.order_id, cartData.get_k_d_s_detail.order_detail_id, cartData.get_k_d_s_detail.group_id, cartData.get_k_d_s_detail.pricelookup_id,cartData.get_k_d_s_detail.pricelookup_qty, cartData.get_k_d_s_detail.status, cartData.get_k_d_s_detail.created_at_formatted
                        ]
                        await this.sqlService.dbInstance.executeSql(`INSERT INTO order_store_kds(database_id,kds_id,order_id,order_detail_id,group_id,pricelookup_id,pricelookup_qty,status,created_at) VALUES (` + order_kds_data.toString() + `)`, [])
                      }

                    }
                  }); */

                  //}
                });

                if(mobileData.order_kds_group.length > 0){
                  await this.sqlService.orderStoreGroupKds(mobileData.order_kds_group);
                }
              });
              this.dataPass.getnewOrderReciveKds(true)
              // }
            } else {
              // alert("New Order")
              // let transdata = [`'` + new Date() + `'`]
              // let local_no;
              // await this.sqlService.dbInstance.executeSql(`INSERT INTO transcation_no (created_at) VALUES(` + transdata + `)`, []).then((res) => {
              //   
              //   // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
              //   local_no = res['insertId']
              // })
              await this.createTranscationNoTabel()
              var new_transaction_number = 1;
              // device type = 1 for ios and 2 for android 0 for POS
              if (mobileData.local_transaction_no == 0 && (mobileData.device_type == 1 || mobileData.device_type == 2)) {
                await this.sqlService.dbInstance.executeSql(`SELECT tran_no FROM transcation_no ORDER BY tran_no DESC LIMIT 1`, {}).then((res) => {

                  if (res.rows.length > 0) {
                    var total_transaction_number = res.rows.item(0).tran_no;
                    if (total_transaction_number > 0) {
                      new_transaction_number = total_transaction_number + 1;
                    } else {
                      new_transaction_number = 1;
                    }
                  } else {
                    new_transaction_number = 1;
                  }
                })

              } else {
                new_transaction_number = mobileData.local_transaction_no;
                // let date = [`'` + new Date() + `'`]
                // new_transaction_number = ordData.local_transaction_no;
                // await this.dbInstance.executeSql(`INSERT INTO transcation_no (tran_no, created_at) VALUES(` + new_transaction_number + `,` + date + `)`, []).then((res) => {
                // })

              }

              let transdata = [`'` + new Date() + `'`]
              let local_no;
              await this.sqlService.dbInstance.executeSql(`INSERT INTO transcation_no (tran_no, created_at) VALUES(` + new_transaction_number + `,` + transdata + `)`, []).then((res) => {

                // local_no = localStorage.getItem('store_id') + localStorage.getItem('terminal_id') + res['insertId']
                // local_no = res['insertId']
              })

              //end to create transction number
              this.isSync = 1
              let staffNote = mobileData.staff_note ? mobileData.staff_note : "null";
              if (mobileData.discount_id == undefined) {
                mobileData.discount_id = 0
              }
              if (mobileData.discount_rate == undefined) {
                mobileData.discount_rate = 0
              }
              if (mobileData.discount_type == undefined) {
                mobileData.discount_type = 0
              }
              if (mobileData.table_id == undefined) {
                mobileData.table_id = 0
              }
              mobileData.table_name = '';
              let table_name = '';
              if (mobileData.table_id != 0) {
                table_name = mobileData.order_table.title;
                mobileData.table_name = table_name;
              }

              let store_order_sequence_id = this.createSequentialOrderNo()

              var printed_kot = JSON.parse(localStorage.getItem("printed_kot"))
              console.log("printed_kot app component", printed_kot)
              var kds_print_flag = 0;
              if (printed_kot == '' || printed_kot == null) {
                kds_print_flag = 0;
              } else {
                printed_kot.findIndex
                let index = printed_kot.findIndex(x => x == mobileData.id)
                if (index != -1) {
                  kds_print_flag = 1;
                }
              }

              let data = [`'` + mobileData.store_order_sequence_id + `'`, `'` + mobileData.store_order_id + `'`, new_transaction_number, `'` + mobileData.id + `'`, mobileData.business_id, mobileData.state_id, mobileData.store_id, mobileData.terminal_id,
                0, `'` + mobileData.customer_id + `'`, `'` + mobileData.order_customer.name + `'`, `'` + mobileData.order_customer.phone + `'`, `'` + mobileData.adujusted_order_id + `'`, mobileData.cash_change,
              mobileData.cash_taken, mobileData.total_item, mobileData.total_item_qty, mobileData.total_discount,
              mobileData.total_tax, mobileData.total_price, mobileData.grand_total, 0, 0, 0, mobileData.discount_id, mobileData.discount_rate,
              mobileData.discount_type, 0, 0, 0, 0, mobileData.order_status, `'` + mobileData.cancellation_reason + `'`, 0, 0, "null", `'` + staffNote + `'`, mobileData.tender_profile_id,
              `'` + mobileData.tender_profile_name + `'`, `'` + mobileData.tender_profile_image + `'`, mobileData.price_override_status, mobileData.created_by, mobileData.created_at_formated, this.isSync, mobileData.round_off,
              mobileData.delivery_charge, mobileData.device_type, mobileData.delivery_type, mobileData.table_id, `'` + table_name + `'`, `'` + kds_print_flag + `'`, `'` + JSON.stringify(mobileData).replace("'", "`") + `'`];



              let sql = `INSERT INTO orders
          (store_order_sequence_id, store_order_id, local_transaction_no,database_id, business_id, state_id, store_id,terminal_id,
          reference_no,customer_id, customer_name,customer_phone, adujusted_order_id, cash_change,
          cash_taken,total_item,total_item_qty,total_discount,
          total_tax,total_price,grand_total,tax_id,
          tax_rate,tax_type, discount_id,discount_rate,
          discount_type,coupon_id,coupon_discount,coupon_type,
          shipping_cost, order_status, cancellation_reason, payment_status,
          paid_amount,sale_note, staff_note,tender_profile_id,
          tender_profile_name, tender_profile_image, price_override_status, created_by, created_at, isSync, round_off, delivery_charges, device_type, delivery_type,table_id,table_name,kds_print_flag,kds_order_obj) VALUES (`+ data.toString() + `)`;


              await this.sqlService.dbInstance.executeSql(sql, []).then(async (data) => {

                this.isSync = 1;
                let order = data;


                if(mobileData.order_kds_detail_items.length > 0){
                  mobileData.order_kds_detail_items.forEach(async res => {
                    let order_kds_data = [
                      res.id, res.kds_id, res.order_id, res.order_detail_id, res.group_id, res.pricelookup_id,res.pricelookup_qty, res.status, res.created_at_formatted
                    ]
                    await this.sqlService.dbInstance.executeSql(`INSERT INTO order_store_kds(database_id,kds_id,order_id,order_detail_id,group_id,pricelookup_id,pricelookup_qty,status,created_at) VALUES (` + order_kds_data.toString() + `)`, [])
                  });
                }

                // Success
                mobileData.order_detail.forEach(async cartData => {

                  let price_cmt = cartData.price_override_item_comment != "" ? cartData.price_override_item_comment : 'null';
                  let plu_type = cartData.plu_type;
                  let addOnItemAry = cartData.order_detail_add_add_ons ? JSON.stringify(cartData.order_detail_add_add_ons) : '[]';
                  let removeItemAry = cartData.order_detail_remove_add_ons ? JSON.stringify(cartData.order_detail_remove_add_ons) : '[]';
                  let specialNote = cartData.specialNote != "" ? cartData.specialNote : '';
                  // let storeTaxAry = cartData.order_detail_tax ? JSON.stringify(cartData.order_detail_tax) : '[]';
                  let storeTaxAry_temp = []




                  cartData.order_detail_tax.forEach(async cartDataTax => {

                    let standard_price = ((cartData.price_override_item_status == 0 ? cartData.pricelookup_item_price : cartData.price_override_item_price) ?? 0)


                    storeTaxAry_temp.push(
                      {
                        id: cartDataTax.id,
                        display_name: cartDataTax.name,
                        tax_value: cartDataTax.tax_value,
                        tax_type: cartDataTax.tax_type,
                        tax_cal: Number(cartDataTax.tax_amount),
                        // standard_price :  cartData.pricelookup_item_price ,
                        standard_price: standard_price,
                        tax_include_price: cartData.tax_include_price
                      }
                    )
                  })




                  let storeTaxAry = JSON.stringify(storeTaxAry_temp)


                  let custAddress = ""
                  if (mobileData.delivery_partner_id == 0 || mobileData.delivery_partner_id == 7 || mobileData.delivery_partner_id == 8) {
                    let custAddress = mobileData.customer_address?.house_no + " " + mobileData.customer_address?.society_name + " " + mobileData.customer_address?.state + " " + mobileData.customer_address?.pincode ? JSON.stringify(mobileData.customer_address?.house_no + " " + mobileData.customer_address?.society_name + " " + mobileData.customer_address?.state + " " + mobileData.customer_address?.pincode) : '[]'
                  }
                  let is_taken_for_new_item = cartData.is_taken_for_new_item ? cartData.is_taken_for_new_item : 0;
                  let storeCategoriesAry = '[]';

                  let order_cart_data = [cartData.id,
                  order.insertId, cartData.pricelookup_id, cartData.pricelookup_qty, `'` + cartData.pricelookup_name.replace("'", "`") + `'`,
                  cartData.pricelookup_item_price, cartData.price_override_item_status, cartData.price_override_item_price,
                  cartData.price_override_item_id, `'` + price_cmt + `'`, `'` + addOnItemAry + `'`, `'` + removeItemAry + `'`, cartData.remove_group_id, cartData.add_group_id,
                  cartData.is_advance_ingredient, `'` + specialNote + `'`,
                  cartData.is_substitute, is_taken_for_new_item, cartData.is_variation_pricelookup, `'` + storeCategoriesAry + `'`, `'` + storeTaxAry + `'`, `'` + this.sqlService.addslashes(custAddress) + `'`, plu_type
                  ]
                  // , cartData.tax_include_price
                  await this.sqlService.dbInstance.executeSql(`INSERT INTO order_store_detail(database_id,order_id, pricelookup_id, pricelookup_qty, pricelookup_name,
                pricelookup_item_price, price_override_item_status, price_override_item_price,
                price_override_item_id, price_override_item_comment, add_addons_detail, remove_addons_detail,
                remove_group_id,add_group_id,is_advance_ingredient,note, is_substitute, is_taken_for_new_item,is_variation_pricelookup,store_categories,taxes,customer_address,plu_type) VALUES (`+ order_cart_data.toString() + `)`, [])

                


                  // if (cartData.get_k_d_s_detail) {
                  //   let order_kds_data = [
                  //     cartData.get_k_d_s_detail.id, cartData.get_k_d_s_detail.kds_id, cartData.get_k_d_s_detail.order_id, cartData.get_k_d_s_detail.order_detail_id, cartData.get_k_d_s_detail.group_id, cartData.get_k_d_s_detail.pricelookup_id, cartData.get_k_d_s_detail.pricelookup_qty, cartData.get_k_d_s_detail.status, cartData.get_k_d_s_detail.created_at_formatted
                  //   ]


                  //   // , cartData.tax_include_price
                  //   await this.sqlService.dbInstance.executeSql(`INSERT INTO order_store_kds(database_id,kds_id,order_id,order_detail_id,group_id,pricelookup_id,pricelookup_qty,status,created_at) VALUES (` + order_kds_data.toString() + `)`, [])

                  // }



                });

                if(mobileData.order_kds_group.length > 0){
                  await this.sqlService.orderStoreGroupKds(mobileData.order_kds_group);
                }

                //           let price_cmt = cartData.price_override_item_comment != "" ? cartData.price_override_item_comment : 'null';
                //           let order_cart_data = [
                //             order.insertId, cartData.pricelookup_id, cartData.pricelookup_qty, `'` + cartData.pricelookup_name.replace("'", "`") + `'`,
                //             cartData.pricelookup_item_price, cartData.add_variation, cartData.price_override_item_status, cartData.price_override_item_price,
                //             cartData.price_override_item_id, `'` + price_cmt + `'`, cartData.add_addon, cartData.add_ingredient,
                //             cartData.addon_variation_combine, cartData.addon_ingredient_combine
                //           ]
                //           // , cartData.tax_include_price
                //           await this.sqlService.dbInstance.executeSql(`INSERT INTO order_store_detail(order_id, pricelookup_id, pricelookup_qty, pricelookup_name,
                // pricelookup_item_price, add_variation, price_override_item_status, price_override_item_price,
                // price_override_item_id, price_override_item_comment, add_addon, add_ingredient,
                // addon_variation_combine, addon_ingredient_combine) VALUES (`+ order_cart_data.toString() + `)`, [])
                //         });
                //debugger
                let tenders = mobileData.order_type
                tenders.forEach(async tender => {
                  let data_order_type = [order.insertId, tender.order_type_id, tender.order_type, `'` + tender.order_type_name + `'`, tender.order_price]
                  await this.sqlService.dbInstance.executeSql(`INSERT INTO orders_store_order_type (order_id, order_type_id, order_type, order_type_name, order_price) VALUES (` + data_order_type.toString() + `)`, [])
                });

                this.dataPass.getnewOrderReciveKds(true)
                if (mobileData.local_transaction_no == 0) {
                  //Sync mobile customer to local DB
                  this.sqlService.dbInstance.executeSql(`SELECT * FROM customers where phone = ` + mobileData.order_customer.phone).then((data) => {
                    if (data.rows.length <= 0) {
                      let insertElement = [mobileData.customer_id, mobileData.business_id, mobileData.state_id, `'` + mobileData.order_customer.name + `'`, `'`
                        + `` + `'`, `'` + mobileData.order_customer.phone + `'`, 1,
                      mobileData.customer_id, mobileData.customer_id, `'` + new Date() + `'`, `'` + new Date() + `'`]
                      this.sqlService.dbInstance.executeSql(`INSERT INTO customers(database_id, business_id, state_id, name, lname, phone, isSync,
                       created_by, updated_by, created_at, updated_at) VALUES(`+ insertElement.toString() + `)`, [])
                        .then((res) => {

                        }).catch((err) => {
                        })
                      // resolve(res)
                    }
                  })
                  //END Sync mobile customer to local DB
                  mobileData.order_id = mobileData.id;
                  mobileData.local_transaction_no = new_transaction_number;
                  this.store_order_id = store_order_sequence_id;
                  localStorage.setItem('store_order_sequence_id', JSON.stringify(this.store_order_id))
                  mobileData.phone = mobileData.order_customer.phone;
                  mobileData.customer_name = mobileData.order_customer.name;
                  mobileData.store_order_sequence_id = store_order_sequence_id
                  // mobileData.add_addons_detail = mobileData.order_detail_add_add_ons
                  // mobileData.remove_addons_detail = mobileData.remove_addons_detail
                  mobileData.customer_address_id = mobileData.customer_address.id ?? '0'



                  mobileData.order_detail.forEach(cartData => {
                    cartData.add_addons_detail = cartData.order_detail_add_add_ons;
                    cartData.remove_addons_detail = cartData.order_detail_remove_add_ons
                  });


                  // debugger
                  // this._posService.placeOrder(mobileData);
                }
                // if (this.cart.store_addon) {
                //   this.cart.store_addons.forEach(async addon => {
                //     let order_addons = [order.insertId, addon.pricelookup_id, addon.order_store_detail_id, addon.order_store_detail_variation_id, addon.addons_id, addon.addons_name, addon.addons_price, addon.uom_id, addon.uom_value]
                //     await this.sqlService.dbInstance.executeSql(`INSERT INTO order_store_detail_addons (order_id, pricelookup_id, order_store_detail_id, order_store_detail_variation_id, addons_id, addons_name, addons_price, uom_id, uom_value) VALUES (` + order_addons.toString() + `)`, [])
                //   });
                // }

                // if (this.cart.store_variations) {
                //   this.cart.store_variations.forEach(async variation => {
                //     let order_variation = [order.insertId, variation.pricelookup_id, variation.order_store_detail_id, variation.variation_id, variation.variation_name, variation.variation_price]
                //     await this.sqlService.dbInstance.executeSql(`INSERT INTO order_store_detail_variation (order_id, pricelookup_id, order_store_detail_id, variation_id, variation_name, variation_price) VALUES (` + order_variation.toString() + `)`, [])
                //   });
                // }

              })

            }
            this.dataPass.getnewOrderReciveKds(true)
            // resolve()
          })
          //debugger
          await this.sqlService.dbInstance.executeSql(`UPDATE push_notifications SET isSync = 1 where order_id = ` + mobileData.id, []).then((res) => { })
          resolve("")
        })

      }

  createSequentialOrderNo() {
        let temp_store_order_sequence_id = JSON.parse(localStorage.getItem('store_order_sequence_id'))
    let tempStoreId = JSON.parse(localStorage.getItem('store_id'))
    let tempTerminal_id = JSON.parse(localStorage.getItem('terminal_id'));

        let storeId = String(tempStoreId).padStart(4, '0');
        let terminal_id = String(tempTerminal_id).padStart(2, '0');

        let finalNumber = ''
    if(temp_store_order_sequence_id == 0) {
        // new terminal
        finalNumber = storeId + terminal_id + 1;

      } else {
        //old terminal
        let intoString = JSON.stringify(temp_store_order_sequence_id)
        let lastNumber = intoString.slice(7, intoString.length - 1)
        let inc = Number(lastNumber) + 1
        finalNumber = storeId + terminal_id + inc;

      }
      return finalNumber.toString()
    }

    syncAfter5min() {
      //in 5 MIN  do something
      //interval(300000)//in 5 MIN  do something
      interval(30000)//30 Second
        .subscribe((val) => {
          if (this.status === "Online") {

            this.sqlService.syncForOrders();
            this.sqlService.paidoutSync();
            this.sqlService.cashoutSync();
            this.sqlService.syncReasons();
          }
        });
    }

  /*syncBackground() {
    interval(2000)//2 Second
      .subscribe((val) => {
        if (this.status === "Online") {
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          
          if (currentUser !== null) {//User login then only request API
            this.customer_background_check = JSON.parse(localStorage.getItem('customer_background_check'));
            this.tender_type_background_check = JSON.parse(localStorage.getItem('tender_type_background_check'));
            this.all_reason_background_check = JSON.parse(localStorage.getItem('all_reason_background_check'));

            if (this.customer_background_check == 0) {
              this.sqlService.getcustomerListToWebSql();
            }
            if (this.tender_type_background_check == 0) {
              this.sqlService.getTenderTypeListItems();
            }
            if (this.all_reason_background_check == 0) {
              this.sqlService.getAllReasons();
            }
          }
        }
      });
  }*/

  async syncMobileOrder() {
      interval(5000)//5 Second
        .subscribe(async (val) => {
          if (this.status === "Online") {

            //let notification_list = JSON.parse(localStorage.getItem('notification'))
            //let new_list = [];
            await this.sqlService.dbInstance.executeSql(`SELECT * FROM push_notifications where isSync = 0 ORDER BY order_id`, []).then(async (res) => {
              if (res.rows.length > 0) {//update

                // debugger;
                for (let index = 0; index < res.rows.length; index++) {
                  let order_items;
                  //var is_sync = res.rows[index]['isSync'];
                  var order_id = res.rows.item(index)['order_id'];
                  var data = {
                    order_id: order_id,
                    is_kds: 1,
                    store_id: localStorage.getItem('store_id'),
                    kds_id: localStorage.getItem('kds_id'),
                    kds_departments: JSON.parse(localStorage.getItem('kds_departments')),
                    get_departments: JSON.parse(localStorage.getItem('get_departments'))
                  }

                  await this._posService.getKdsOrderDetail(data)
                    .subscribe(
                      async res => {
                        this.error_try_flag = 0
                        if (res.status == 1) {
                          order_items = res.data;
                          await this.createOrder(order_items)


                          //await this.sqlService.dbInstance.executeSql(`UPDATE push_notifications SET isSync = 1 where order_id = ` + order_items.id, []).then((res) => { })

                        }
                      },
                      async err => {
                        console.log('getKdsOrderDetail err', JSON.stringify(err))
                        if (this.error_try_flag < 3) {
                          this.error_try_flag++;
                          // await this.syncMobileOrder()
                        } else {
                          this.error_try_flag = 0
                        }

                      });

                }

              }
            })


            /*  if (notification_list) {
                notification_list.sort();
  
                //  for (let index = 0; index < notification_list.length; index++) {
                var sync_order = 1;//1=yes,0=no
                notification_list.forEach(async (order_id, index) => {
                  //var order_id = notification_list[index];
  
                  let order_items;
                  var data = {
                    order_id: order_id
                  }
  
                  await this._posService.getOrderDetail(data)
                    .subscribe(
                      async res => {
                        if (res.status === 1 && sync_order == 1) {
                          sync_order = 0;
                          order_items = res.data;
                          
  
  
  
                          await this.createOrder(order_items)
                          notification_list.splice(index, 1);
                          this.list.splice(index, 1);//main list array//pop by index
  
                          localStorage.setItem('notification', JSON.stringify(notification_list));
                          sync_order = 1;
                        }
                        
                      },
                      err => {
  
                      });
                });
  
                // for (let index = 0; index < list.length; index++) {
                //   
                //   
  
                //   await this.createOrder(JSON.parse(list[index]))
                //   list.splice(index, 1);
                //   
                //   localStorage.setItem('notification', JSON.stringify(list));
                // }
  
                
                
              }*/

          }
        });
    }

    // set data in to local storage
    setPOSGeneralSetting() {
      localStorage.setItem('order_type', JSON.stringify(this.pos_order_type))
    }

    checkConnection() {
      console.log("checkConnection")
      this.connectionService.monitor().subscribe(currentState => {
        // this.hasNetworkConnection = currentState.hasNetworkConnection;
        // this.hasInternetAccess = currentState.hasInternetAccess;

        if (currentState.hasNetworkConnection) {
          // if (currentState) {

          this.internetStatus = 'Online';
        } else {
          this.internetStatus = 'Offline';
        }
        this.dataPass.internetCheck(this.internetStatus)
      });
    }

    // set_network() {
    //   // alert("set_network call")
    //   let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
    //     console.log('network was disconnected :-(');
    //     let url = this.router.url;
    //     localStorage.setItem('no-internet', url);
    //     this.router.navigate(['/no-internet'])
    //   });

    //   let connectSubscription = this.network.onConnect().subscribe(() => {
    //     console.log('network connected!');
    //     setTimeout(() => {
    //       let url = localStorage.getItem('no-internet');
    //       localStorage.removeItem('no-internet');    
    //     }, 2000);
    //   });
    // }

    // Android
    set_network() {
      console.log("set_network")
      let disconnectSubscription = this.network.onDisconnect().subscribe(() => {

        this.internetStatus = 'Offline';
        this.dataPass.internetCheck(this.internetStatus)

      });

      let connectSubscription = this.network.onConnect().subscribe(() => {

        setTimeout(() => {
          this.internetStatus = 'Online';
          this.dataPass.internetCheck(this.internetStatus)
        }, 2000);
      });
    }


    setConfig() {
      localStorage.setItem('status', '0');
    }





    // sync
    // backgroundSync() {
    //   navigator.serviceWorker.ready
    //     .then((swRegistration) => swRegistration.sync.register('post-data'))
    //     .catch
    // }



  }
