import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';
import { appConfig } from '../app.config';
import { map } from 'rxjs/operators'

@Injectable()
export class CMSService {
  constructor(private http: HttpClient) { }



  reportcontrolsheet(data) {

    // var mediaType = 'application/pdf';
    // this.http.post(appConfig.apiUrl + '/reportcontrolsheet', {location: "report.pdf"}, { responseType: 'blob' }).subscribe(
    //     (response) => {
    //       return response;
    //         // var blob = new Blob([response], { type: mediaType });
    //         // saveAs(blob, 'report.pdf');
    //     },
    //     // e => { throwError(e); }
    // );

    // let header = new Headers();
    // header.append('content-type', 'application/pdf')
    // let token = JSON.parse(localStorage.getItem('token'));
    let requestHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('accept', 'application/pdf')
    // .append('Access-Control-Allow-Origin', `*`);
    return this.http.post<any>(appConfig.apiUrl + '/reportcontrolsheet', data, { headers: requestHeaders })
      .pipe(map(res => {
        return res;
      }));
  }

  newpaidOutReasonlast(data) {
    return this.http.post<any>(appConfig.apiUrl + '/savereason', data)
      .pipe(map(res => {
        return res;
      }));
  }

  newpaidOutReason(data) {
    // return this.http.post<any>(appConfig.apiUrl + '/savereason', data)
    //   .pipe(map(res => {
    //     return res;
    //   }));
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/savereason', data)
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  addCustomer(data) {
    // return this.http.post<any>(appConfig.apiUrl + '/customeraddupdate', data)
    // .pipe(map(res => {
    //   return res;
    // }));
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/customeraddupdate', data)
        .subscribe(res => {
          resolve(res);
        });
    });

  }

  addTable(data) {
    
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/store-table-update-status', data)
        .subscribe(res => {
          resolve(res);
        });
    });

  }

addAddresses(data) {
    // return this.http.post<any>(appConfig.apiUrl + '/customeraddupdate', data)
    // .pipe(map(res => {
    //   return res;
    // }));
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/customer-address-addupdate', data)
        .subscribe(res => {
          resolve(res);
        });
    });

  }
  getReasons(data) {
    return this.http.post<any>(appConfig.apiUrl + '/getreasons', data)
      .pipe(map(res => {
        return res;
      }));
  }

  checkStartOfDay(data) {
    return this.http.post<any>(appConfig.apiUrl + '/checkStartofDay', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getstore(data) {
    return this.http.post<any>(appConfig.apiUrl + '/getstore', data)
      .pipe(map(res => {
        return res;
      }));
  }

  receiptPrintOut(data) {
    return this.http.post<any>(appConfig.apiUrl + '/receiptendofday', data)
      .pipe(map(res => {
        return res;
      }));
  }

  saveEndofDay(data) {
    return this.http.post<any>(appConfig.apiUrl + '/saveendofday', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getPaidOut(data) {
    return this.http.post<any>(appConfig.apiUrl + '/getpaidout', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getCashOut(data) {
    return this.http.post<any>(appConfig.apiUrl + '/getcashout', data)
      .pipe(map(res => {
        return res;
      }));
  }

  paidOutEOD(data) {
    return this.http.post<any>(appConfig.apiUrl + '/savepaidout', data)
      .pipe(map(res => {
        return res;
      }));

    // return new Promise((resolve, reject) => {
    //   this.http.post(appConfig.apiUrl + '/savepaidout', data)
    //     .subscribe(res => {
    //       resolve(res);
    //     });
    // });
  }

  paidOut(data) {
    return this.http.post<any>(appConfig.apiUrl + '/savepaidout', data)
      .pipe(map(res => {
        return res;
      }));

    // return new Promise((resolve, reject) => {
    //   this.http.post(appConfig.apiUrl + '/savepaidout', data)
    //     .subscribe(res => {
    //       resolve(res);
    //     });
    // });
  }

  cashOutEOD(data) {
    return this.http.post<any>(appConfig.apiUrl + '/savecashout', data)
      .pipe(map(res => {
        return res;
      }));
    
  }

  cashOut(data) {
    return this.http.post<any>(appConfig.apiUrl + '/savecashout', data)
      .pipe(map(res => {
        return res;
      }));
    // return new Promise((resolve, reject) => {
    //   this.http.post(appConfig.apiUrl + '/savecashout', data)
    //     .subscribe(res => {
    //       resolve(res);
    //     });
    // });
  }

  oneMinCashIn(data) {
    return this.http.post<any>(appConfig.apiUrl + '/saveoneminutecashin', data)
      .pipe(map(res => {
        return res;
      }));
  }

  saveStartofDay(data) {
    return this.http.post<any>(appConfig.apiUrl + '/savestartofday', data)
      .pipe(map(res => {
        return res;
      }));
  }



  getAllWisrList() {
    return this.http.post<any>(appConfig.apiUrl + '/wisrcategorylist', '')
      .pipe(map(res => {
        return res;
      }));
  }


  getAllWisrListById(id) {
    return this.http.post<any>(appConfig.apiUrl + '/wisrcategorylist', id)
      .pipe(map(res => {
        return res;
      }));
  }

  createWisr(data) {
    return this.http.post<any>(appConfig.apiUrl + '/wisrcategorystore', data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateWisr(data) {
    return this.http.post<any>(appConfig.apiUrl + '/wisrcategoryupdate', data)
      .pipe(map(res => {
        return res;
      }));
  }


  getAlldepartmentList() {
    return this.http.post<any>(appConfig.apiUrl + '/departmentlist', '')
      .pipe(map(res => {
        return res;
      }));
  }

  getAlldepartmentListById(id) {
    return this.http.post<any>(appConfig.apiUrl + '/departmentlist', id)
      .pipe(map(res => {
        return res;
      }));
  }


  createDepartment(data) {
    return this.http.post<any>(appConfig.apiUrl + '/departmentstore', data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateDepartment(data) {
    return this.http.post<any>(appConfig.apiUrl + '/departmentupdate', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllIngredientList() {
    return this.http.get<any>(appConfig.apiUrl + '/cms/getAllIngredientList')
      .pipe(map(res => {
        return res;
      }));
  }

  createIngredient(data) {
    return this.http.post<any>(appConfig.apiUrl + '/cms/createIngredient', data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateIngredient(data) {
    return this.http.post<any>(appConfig.apiUrl + '/cms/updateIngredient', data)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteIngredient(data) {
    return this.http.post<any>(appConfig.apiUrl + '/cms/deleteIngredient', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllPrepareIngredientList() {
    return this.http.get<any>(appConfig.apiUrl + '/cms/getAllPrepareIngredientList')
      .pipe(map(res => {
        return res;
      }));
  }

  deletePrepareIngredient(data) {
    return this.http.post<any>(appConfig.apiUrl + '/cms/deletePrepareIngredient', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllStandardList() {
    return this.http.get<any>(appConfig.apiUrl + '/cms/getAllStandardList')
      .pipe(map(res => {
        return res;
      }));
  }

  deleteStandard(data) {
    return this.http.post<any>(appConfig.apiUrl + '/cms/deleteStandard', data)
      .pipe(map(res => {
        return res;
      }));
  }

  createStandard(data) {
    return this.http.post<any>(appConfig.apiUrl + '/cms/createStandard', data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateStandard(data) {
    return this.http.post<any>(appConfig.apiUrl + '/cms/updateStandard', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllMenuList() {
    return this.http.get<any>(appConfig.apiUrl + '/cms/getAllMenuList')
      .pipe(map(res => {
        return res;
      }));
  }


  deleteMenu(data) {
    return this.http.post<any>(appConfig.apiUrl + '/cms/deleteMenu', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllTenderTypeList(data) {
    return this.http.post<any>(appConfig.apiUrl + '/tendertypelist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  createNewTenderType(data) {
    return this.http.post<any>(appConfig.apiUrl + '/tendertypestore', data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateNewTenderType(data) {
    return this.http.post<any>(appConfig.apiUrl + '/tendertypeupdate', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllTenderTypeListById(id) {
    return this.http.post<any>(appConfig.apiUrl + '/tendertypelist', id)
      .pipe(map(res => {
        return res;
      }));
  }

  getTenderList() {
    return this.http.post<any>(appConfig.apiUrl + '/tenderlist', '')
      .pipe(map(res => {
        return res;
      }));
  }

  createNewTender(data) {
    return this.http.post<any>(appConfig.apiUrl + '/tenderstore', data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateNewTender(data) {
    return this.http.post<any>(appConfig.apiUrl + '/tenderupdate', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getTenderListbyId(id) {
    return this.http.post<any>(appConfig.apiUrl + '/tenderlist', id)
      .pipe(map(res => {
        return res;
      }));
  }


  getTaxConfiguration() {
    return this.http.post<any>(appConfig.apiUrl + '/taxlist', '')
      .pipe(map(res => {
        return res;
      }));
  }

  getTaxConfigurationbyId(id) {
    return this.http.post<any>(appConfig.apiUrl + '/taxlist', id)
      .pipe(map(res => {
        return res;
      }));
  }

  getDiscount() {
    return this.http.post<any>(appConfig.apiUrl + '/discountlist', '')
      .pipe(map(res => {
        return res;
      }));
  }

  getDiscountbyId(id) {
    return this.http.post<any>(appConfig.apiUrl + '/discountlist', id)
      .pipe(map(res => {
        return res;
      }));
  }

  createTax(data) {
    return this.http.post<any>(appConfig.apiUrl + '/taxstore', data)
      .pipe(map(res => {
        return res;
      }));
  }

  createDiscount(data) {
    return this.http.post<any>(appConfig.apiUrl + '/discountstore', data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateTax(data) {
    return this.http.post<any>(appConfig.apiUrl + '/taxupdate', data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateDiscount(data) {
    return this.http.post<any>(appConfig.apiUrl + '/discountupdate', data)
      .pipe(map(res => {
        return res;
      }));
  }

  customeraddupdate(data) {
    return this.http.post<any>(appConfig.apiUrl + '/customeraddupdate', data)
      .pipe(map(res => {
        return res;
      }));
  }

  storeReceiptTemplate(data) {
    return this.http.post<any>('https://adminpos.limerr.co/api/v1/kds-storereceipttemplate', data)
      .pipe(map(res => {
        return res;
      }));
  }

  storePosBehavior(data) {
    return this.http.post<any>(appConfig.apiUrl + '/getstoreposbehavior', data)
      .pipe(map(res => {
        return res;
      }));
  }

}
